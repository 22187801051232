<template>
  <div class="page-container">
    <div class="page-content">

      <div class="page-header">
        <div class="d-flex">
          <h4 class="text-white">Visualizar Processo</h4>
        </div>
      </div>

      <div v-if="alertMessage" class="col-12">
        <b-alert show :variant="alertVariant">{{ alertMessage }}</b-alert>
      </div>

      <div class="page-body" v-if="process">
        <div class="card form">
          <div class="card-body">

            <div class="row">
              <div class="col-12 mb-3">
                <h5>
                  <div class="form-subheader"><span>Dados Gerais</span></div>
                </h5>
              </div>

              <div class="col-12 col-lg-4 mb-3">
                <label class="dbo-ui" for="">Número do Processo</label>
                <p>#{{ process.number }}</p>
              </div>
              
              <div class="col-12 col-lg-4 mb-3">
                <label class="dbo-ui" for="">Parte Polo Ativo</label>
                <p>{{ process.active_part }}</p>
              </div>

              <div class="col-12 col-lg-4 mb-3">
                <label class="dbo-ui" for="">Parte Polo Pssivo</label>
                <p>{{ process.passive_part }}</p>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-lg-4 mb-3">
                <label class="dbo-ui" for="">Possui pedido de substituição</label>
                <p>{{ process.substitution_order }}</p>
              </div>
              <div class="col-12 col-lg-4 mb-3">
                <label class="dbo-ui" for="">Desfecho do pedido de substituição</label>
                <p>{{ process.substitution_outcome }}</p>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-lg-4 mb-3">
                <label class="dbo-ui" for="">Pagamento de Recursos</label>
                <p>{{ process.resource_payments }}</p>
              </div>

              <div class="col-12 col-lg-4 mb-3">
                <label class="dbo-ui" for="">Tipos de Recursos</label>
                <p>{{ process.resource_types }}</p>
              </div>
              <div class="col-12 col-lg-4 mb-3">
                <label class="dbo-ui" for="">Valor de Depósitos</label>
                <p>{{ formatCurrency(process.value_deposit) }}</p>
              </div>
              <div class="col-12 col-lg-4 mb-3">
                <label class="dbo-ui" for="">Valor de Liquidação</label>
                <p>{{ formatCurrency(process.liquidation_value) }}</p>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-lg-4 mb-3">
                <label class="dbo-ui" for="">Vara</label>
                <p>{{ process.court }}</p>
              </div>
              <div class="col-12 col-lg-4 mb-3">
                <label class="dbo-ui" for="">Comarca</label>
                <p>{{ process.county }}</p>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-lg-12">
                <a @click="back()" class="btn btn-outline-secondary"
                  ><i class="fa fa-angle-left"></i> Voltar</a
                >
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import jurimetryService from "@/services/jurimetry";
import numericFormatterMixin from '@/mixins/numericFormatter';

export default {
  name: "details-process",
  props: {
    processId: {
      type: String,
    },
  },
  mixins: [numericFormatterMixin],
  data: () => ({
    process: null,
  }),
  created() {
    this.getProcess(this.processId);
  },
  methods: {
    getProcess(uid) {
      this.resetAlert();
      jurimetryService
        .getProcessByUID(uid)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.process = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar o processo.");
        });
    },
    back() {
      this.$router.push({
        name: "list-jurimetry",
      });
    },
  },
};
</script>

<style></style>
