<template>
  <div>
    <div class="row">
      <div class="col-12 mb-3">
        <h5>
          <div class="form-subheader"><span>Detalhes</span></div>
        </h5>
        <div class="row ml-2">
          <div class="col-12 col-lg-4 mb-3">
            <label class="dbo-ui" for="">Descrição</label>
            <p>{{ endorsement.description }}</p>
          </div>

          <div class="col-12 col-lg-4 mb-3">
            <label class="dbo-ui" for="">Usuário</label>
            <p>{{ endorsement.user ? endorsement.user.name : "---" }}</p>
          </div>

          <div class="col-12 col-lg-4 mb-3">
            <label class="dbo-ui" for="">Data de criação</label>
            <p>{{ formatDateLocal(endorsement.created) }}</p>
          </div>

          <div class="col-12 col-lg-4 mb-3">
            <label class="dbo-ui" for="">Tomador</label>
            <p>{{ endorsement.policy ? endorsement.policy.policy_holder_name : "---" }}</p>
          </div>

          <div class="col-12 col-lg-4 mb-3">
            <label class="dbo-ui" for="">Numero da apólice</label>
            <p>
              {{ endorsement.policy ? endorsement.policy.number : "---" }}
              <i @click="redirect('details-policy', { policyId: endorsement.policy.id })"
                v-b-tooltip.hover title="Visualizar Apólice"
                class="fas fa-eye icon-btn mx-1 small align-self-center"></i>
            </p>
          </div>

          <div class="col-12 col-lg-4 mb-3">
            <label class="dbo-ui" for="">CPF/CNPJ TOMADOR</label>
            <p>{{ endorsement.policy ? endorsement.policy.policy_holder_document : "---" }}</p>
          </div>
        </div>
      </div>
      <div class="col-12 mb-3" v-if="endorsement.type">
        <h5>
          <div class="form-subheader"><span>Dados do endosso</span></div>
        </h5>
        <div class="row ml-2">
          <div class="col-12 col-lg-4 mb-3">
            <label class="dbo-ui" for="">TIPO DE ALTERAÇÃO</label>
            <p>{{ endorsement.endorsement_type.translate }}</p>
          </div>
          <div class="col-12 col-lg-4 mb-3" v-if="endorsement.type == 1 || endorsement.type == 5">
            <label class="dbo-ui" for="">INICIO DA NOVA IS</label>
            <p>{{ formatDateLocal(endorsement.new_validity_start) }}</p>
          </div>
          <div class="col-12 col-lg-4 mb-3"
            v-if="endorsement.type == 1 || endorsement.type == 3 || endorsement.type == 4">
            <label class="dbo-ui" for="">Nova IS</label>
            <p>{{ formatCurrency(endorsement.new_coverage_value) }}</p>
          </div>
          <div class="col-12 col-lg-4 mb-3"
            v-if="endorsement.type == 2 || endorsement.type == 3 || endorsement.type == 5">
            <label class="dbo-ui" for="">NOVA DATA DE VENCIMENTO</label>
            <p>{{ formatDateLocal(endorsement.new_expiration_date) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dateFormatter from "@/mixins/dateFormatter";
import numericFormatterMixin from '@/mixins/numericFormatter';
export default {
  name: "GeneralData",
  mixins: [dateFormatter, numericFormatterMixin],
  props: {
    endorsement: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    redirect(routeName, params = null) {
      this.$router.push({name: routeName, params: params});
    }
  }
};
</script>

<style></style>
