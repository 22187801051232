<template>
  <div class="page-container">
    <div class="page-content">
      <div class="page-header">
        <div class="d-flex align-items-center mb-3">
          <h1 class="text-white"><i class="mdi mdi-handshake"></i> Visualizar tomador</h1>
          <span @click="back()" class="btn btn-outline-white ml-auto"
            ><i class="fa fa-arrow-left mr-1"></i>
            <span class="d-none d-md-inline">Voltar</span></span
          >
        </div>
      </div>
      <div
        v-if="loading"
        class="overlay d-flex align-items-center justify-content-center"
      >
        <div class="d-flex justify-content-center mb-2">
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>

      <div class="page-body">
        <div class="card">
          <div class="card-body">
            <div class="col-12 mb-3">
              <h5>
                <div class="form-subheader">
                  <span>Dados do tomador</span>
                </div>
              </h5>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="col-md-12 mt-3 ml-2 d-flex flex-column">
                  <label class="dbo-ui">Nome do Grupo economico</label>
                  <span>{{ company ? company.group_name : "" }}</span>
                </div>
                <div class="col-md-12 mt-4 ml-2 d-flex flex-column">
                  <label class="dbo-ui">CNPJ</label>
                  <span>{{ company ? company.economic_group_cnpj : "" }}</span>
                </div>
                <div class="col-md-12 mt-4 ml-2 pb-4 d-flex flex-column">
                  <label class="dbo-ui">Razão social</label>
                  <span>{{ company ? company.company_name : "" }}</span>
                </div>
              </div>
              <div class="border-left col-lg-6 overflow-hidden">
                <label class="ml-4 dbo-ui">GRUPO ECONÔMICO</label>
                <div class="col-lg-12 mt-4">
                  <b-table
                    class="table pretty-title-gray mb-0"
                    :items="economic_groups"
                    :fields="fieldsTaker"
                  >
                    <template #cell(company_cnpj)="data">
                      <span class="centered-cell">{{ data.value }}</span>
                    </template>
                    <template #cell(company.company_name)="data">
                      <span class="centered-cell">{{ data.value }}</span>
                    </template>
                    <template #cell(economic_group_type_id)="data">
                      <span class="centered-cell" v-if="data.value == 1">Coligada</span>
                      <span class="centered-cell" v-if="data.value == 2">Filial</span>
                      <span class="centered-cell" v-if="data.value == 3">Controlada</span>
                      <span class="centered-cell" v-if="data.value == 4"
                        >Controladora</span
                      >
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
            <h5 class="mx-2">
              <div class="form-subheader">
                <span
                  >DOCUMENTOS <i class="fa fa-angle-right mx-2"></i>
                  <p class="text-dark my-0" style="overflow-wrap: normal">
                    Demonstrativos financeiros
                  </p>
                </span>
              </div>
            </h5>
            <div class="col-lg-12 mt-4 pr-1">
              <b-table
                class="table font-14 list pretty-title-gray mb-0"
                :items="documents"
                :fields="fieldsDocuments"
              >
                <template #cell(balance_type)="data">
                  {{ getEnumKeyByEnumValue(translateBalanceTypes, data.value) }}
                </template>
                <template #cell(id)="data">
                  <button
                    @click="downloadEconomicGroupFile(data.item)"
                    class="btn text-secondary"
                    title="Download"
                    data-toggle="tooltip"
                    data-original-title="down"
                  >
                    <i class="fa fa-cloud-download-alt"></i>
                  </button>
                  <button
                    @click="showModalDelete(data.item)"
                    class="btn text-secondary"
                    title="Deletar"
                    data-toggle="tooltip"
                    data-original-title="Deletar"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </template>
              </b-table>
              <button
                @click="downloadEconomicGroupFiles()"
                class="button btn btn-outline-secondary float-right mb-2"
              >
                <i class="fa fa-file-archive mr-1"></i>
                Fazer download de todos os documentos
              </button>
            </div>
          </div>
          <div class="modal">
            <b-modal
              id="show-modal-delete"
              ref="showModalDelete"
              class="mt-4"
              size="sm"
              centered
              hide-footer
            >
              <br />
              <template #modal-title> Deseja deletar esse arquivo ? </template>
              <div class="mb-5 pb-2">
                <h6 class="dbo-ui">
                  Deseja deletar o arquivo
                  <p>
                    {{ file_data.name }}
                  </p>
                  Vinculado ao cliente de ID
                  <p>
                    {{ file_data.economic_group_id }}
                  </p>
                </h6>
              </div>
              <div>
                <a
                  @click="$bvModal.hide('show-modal-delete')"
                  class="btn btn-outline-secondary"
                  ><i class="fa fa-angle-left"></i> Voltar</a
                >
                <a @click="deleteFile(file_data.id)" class="btn btn-md btn-danger ml-2"
                  ><i class="fas fa-times"></i> Confirmar</a
                >
              </div>
            </b-modal>
          </div>
          <div class="col-lg-12 mb-3">
            <h5 class="mx-4">
              <div class="form-subheader">
                <span>OBSERVAÇÕES DO TOMADOR </span>
              </div>
            </h5>
            <div class="col-lg-12 mt-4 ml-3 pr-4">
              <b-table
                class="table font-14 list pretty-title-gray mb-0"
                :items="observations"
                :fields="fieldsObservations"
              >
                <template #cell(date)="data">
                  <span class="centered-cell">{{ formatDateLocal(data.value) }}</span>
                </template>
              </b-table>
            </div>
          </div>
          <div class="col-lg-12 mb-3">
            <h5 class="mx-4">
              <div class="form-subheader">
                <span>RELAÇÃO DE CAPACIDADE NO MERCADO </span>
              </div>
            </h5>
            <div class="col-lg-12 mt-4 ml-3 pr-4">
              <b-table
                class="table font-14 list pretty-title-gray mb-0"
                :items="insurers"
                :fields="fieldsInsurers"
              >
                <template #cell(name)="data">
                  <span class="centered-cell">{{ data.value }}</span>
                </template>
                <template #cell(status)="data">
                  <span
                    class="btn btn-sm btn-success text-white text-uppercase rounded-pill nowrap ml-3"
                    v-if="data.value == 1"
                  >
                    Aprovado
                  </span>
                  <span
                    class="btn btn-sm btn-orange text-white text-uppercase rounded-pill nowrap ml-3"
                    v-if="data.value == 2"
                  >
                    Em Análise
                  </span>
                  <span
                    class="btn btn-sm btn-orange text-white text-uppercase rounded-pill nowrap ml-3"
                    v-if="data.value == 3"
                  >
                    Documentação pendente
                  </span>
                  <span
                    class="btn btn-sm btn-danger text-white text-uppercase rounded-pill nowrap ml-3"
                    v-if="data.value == 4"
                  >
                    Recusada
                  </span>
                </template>
                <template #cell(approved_limit)="data">
                  <span class="centered-cell">{{ formatCurrency(data.value) }}</span>
                </template>
                <template #cell(available_limit)="data">
                  <span class="centered-cell">{{ formatCurrency(data.value) }}</span>
                </template>
                <template #cell(total_accumulation)="data">
                  <span class="centered-cell">{{ formatCurrency(data.value) }}</span>
                </template>
                <template #cell(modality)="data">
                  <div class="centered-cell">
                    <span class="nowrap col-md-10" v-if="data.value == 1">
                      Judicial
                    </span>
                    <span class="nowrap col-md-10" v-else> Tradicional </span>
                  </div>
                </template>
                <template #cell(current_rate)="data">
                  <span class="centered-cell">{{ formatPercentage(data.value) }}</span>
                </template>
                <template #cell(validity)="data">
                  <span class="centered-cell">{{ formatDateLocal(data.value) }}</span>
                </template>
                <template #cell(ccg)="data">
                  <div class="centered-cell">
                    <span
                      class="btn btn-sm btn-success text-white text-uppercase rounded-pill nowrap col-md-10"
                      v-if="data.value"
                    >
                      regularizado
                    </span>
                    <span
                      class="btn btn-sm btn-orange text-white text-uppercase rounded-pill nowrap col-md-10"
                      v-else
                      >Não regularizado</span
                    >
                  </div>
                </template>
                <template #cell(appointment)="data">
                  <div class="centered-cell">
                    <span
                      class="btn btn-sm btn-success text-white text-uppercase rounded-pill nowrap col-md-10 centered-cell"
                      v-if="data.value"
                      >Sim</span
                    >
                    <span
                      class="btn btn-sm btn-danger text-white text-uppercase rounded-pill nowrap col-md-10 centered-cell"
                      v-else
                      >Não</span
                    >
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import companyService from "@/services/company";
import commomsHelper from "@/mixins/commomsHelper";
import numericFormatterMixin from "@/mixins/numericFormatter";
import dateFormatterMixin from "@/mixins/dateFormatter";
import translateBalanceTypes from "@/enums/translate/balanceTypes";
import fileDownload from "js-file-download";

export default {
  name: "client-store",
  components: {},
  mixins: [commomsHelper, numericFormatterMixin, dateFormatterMixin],
  data: () => ({
    economic_groups: [],
    insurers: [],
    documents: [],
    observations: [],
    company: null,
    loading: false,
    translateBalanceTypes: translateBalanceTypes,
    file_data: "",
  }),
  computed: {
    rowsTaker() {
      return this.economic_groups.items_taker.length;
    },
    fieldsTaker() {
      var columns = [
        {
          key: "company_cnpj",
          label: "CNPJ",
          tdClass: "font-6",
          thClass: "table-pretty dbo-ui rounded-top centered-header",
        },
        {
          key: "company_name",
          label: "NOME",
          tdClass: "font-6",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "economic_group_type_id",
          label: "TIPO",
          tdClass: "font-6",
          thClass: "table-pretty dbo-ui rounded-top centered-header",
        },
      ];
      return columns;
    },
    rowsDocuments() {
      return this.documents.length;
    },
    fieldsDocuments() {
      var columns = [
        {
          key: "balance_type",
          label: "Categoria",
          tdClass: "font-6",
          thClass: "table-pretty dbo-ui rounded-top",
        },
        {
          key: "name",
          label: "NOME DO DOCUMENTO	",
          tdClass: "font-6",
          thClass: "table-pretty dbo-ui w-75",
        },
        {
          key: "id",
          label: "",
          tdClass: "font-6",
          thClass: "table-pretty dbo-ui rounded-top",
        },
      ];
      return columns;
    },
    rowsInsurers() {
      return this.insurers.length;
    },
    fieldsInsurers() {
      var columns = [
        {
          key: "name",
          label: "NOME",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui rounded-top centered-header",
        },
        {
          key: "status",
          label: "status",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui rounded-top centered-header",
        },
        {
          key: "approved_limit",
          label: "LIMITE APROVADO",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "available_limit",
          label: "LIMITE DISPONÍVEL",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "total_accumulation",
          label: "ACÚMULO TOTAL",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "modality",
          label: "ACÚMULO TOTAL",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui rounded-top centered-header",
        },
        {
          key: "current_rate",
          label: "TAXA ATUAL",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "validity",
          label: "VALIDADE",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "ccg",
          label: "CCG",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "appointment",
          label: "NOMEAÇÃO",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui rounded-top centered-header",
        },
      ];
      return columns;
    },
    fieldsObservations() {
      var columns = [
        {
          key: "author",
          label: "usuário",
          tdClass: "font-6",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "date",
          label: "data",
          tdClass: "font-6",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "text",
          label: "observação",
          tdClass: "font-6",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "actions",
          label: "",
          tdClass: "font-14 ",
          thClass: "table-pretty dbo-ui centered-header",
        },
      ];
      return columns;
    },
  },
  created() {
    this.getEconomicGroup();
  },
  methods: {
    back() {
      this.$router.push({
        name: "list-client",
      });
    },
    getEconomicGroup() {
      this.loading = true;
      companyService
        .showEconomicGroup(this.getIdFromUrl())
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }

          this.insurers = response.data.economicGroupInsurers;
          this.economic_groups = response.data.economicGroups;
          this.observations = response.data.economicGroupObservations;
          this.company = response.data.company;
          this.showEconomicGroupFiles();
        })
        .catch(() => {
          this.showError("Não foi possível recuperar os grupos economicos.");
        });
    },
    showEconomicGroupFiles() {
      companyService
        .showEconomicGroupFiles(this.getIdFromUrl())
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.documents = [];
          for (const balance in response.data) {
            if (Array.isArray(response.data[balance])) {
              this.documents.push(...response.data[balance]);
            }
          }
        })
        .catch(() => {
          this.showError("Não foi possível apagar os grupos economicos.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadEconomicGroupFile(data) {
      this.loading = true;
      companyService
        .downloadEconomicGroupFile(data.id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.openLinkTargetBlank(response.data.url);
        })
        .catch(() => {
          this.showError("Não foi possível apagar os grupos economicos.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadEconomicGroupFiles() {
      this.loading = true;
      companyService
        .downloadEconomicGroupZipFile(this.getIdFromUrl())
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          const groupName = this.company.group_name.split(" ")[0];
          const currentDate = new Date().toISOString().slice(0, 10);
          fileDownload(response.data, `${groupName}_${currentDate}.zip`);
        })
        .catch(() => {
          this.showError("Não foi possível baixar o arquivo ZIP.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteFile(fileId) {
      this.$refs.showModalDelete.hide();
      this.loading = true;
      companyService
        .deleteEconomicGroupFile(fileId)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
        })
        .catch(() => {
          this.showError("Não foi possível apagar o arquivo do grupos economico.");
        })
        .finally(() => {
          this.showEconomicGroupFiles();
          this.loading = false;
        });
    },
    showModalDelete(file_data) {
      this.file_data = file_data;
      this.$refs.showModalDelete.show();
    },
  },
};
</script>

<style></style>
