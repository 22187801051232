<template>
  <div class="modal">
    <b-modal
      id="show-modal-document"
      ref="showModalDocument"
      class="mt-4"
      size="lg"
      centered
      hide-footer
      :body-bg-variant="'p-3 px-lg-5 pb-lg-5'"
    >

      <template #modal-title> Selecione o tomador que deseja utilizar </template>

      <div class="input-group mb-4">
        <b-form-input v-model="filter" @input="debounceSearch" placeholder="Digite sua busca..."></b-form-input>
        
        <div class="input-group-append">
        <button class="btn btn-primary" @click="listCompanies()"><i class="fa fa-search"></i></button>
        </div>
      </div>

      <div class="card">
        <div class="overlay_modal" v-if="loadingModal"><b-spinner class="text-primary"></b-spinner></div>
        <div class="row">
          <div class="col-12 col-lg-12 position-relative">
            

            <div class="lista">
              <div class="row row-cols-1" data-modelo="cards">
                  <div class="col mb-3"
                    v-for="(company, index) in companies"
                    :key="index">
                    <div class="card shadow border-1 h-100"
                      v-bind:class="{ 'shadow-primary border-primary': company.selected }">
                      <div class="card-body h-100 text-left d-flex align-items-center px-3 py-2">
                        <div class="">
                          <h6 class="mb-1 pr-3">{{ company.company_name }}</h6>
                          <span class="font-14 text-primary">{{ formatCnpj(company.cnpj) }}</span>
                        </div>
                        <div class="ml-auto">
                          <button data-dismiss="modal" type="button" class="button btn py-2 nowrap"
                            v-bind:class="{ 'btn-outline-primary': !company.selected, 'btn-primary': company.selected }"
                            @click="selectCompany(company)">
                            <i class="fa fa-check"></i><span class="d-none d-lg-inline"> Selecionar</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>

          </div>
        </div>
      </div>         
    </b-modal>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import commomsHelper from '@/mixins/commomsHelper';
import companiesService from "@/services/company";
import commonsService from "@/services/commons";

export default {
  name: "SelectCompany",
  mixins: [
    commomsHelper
  ],
  directives: {
    mask,
  },
  data: () => ({
    loadingModal: false,
    debounce: null,
    companies: [],
    filter: '',
    currentPage: 1,
    perPage: 10,
    dataForm: {},
    loading: false,
    loadingModalCnpj: false
  }),
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent) => {
      if (bvEvent.componentId == 'show-modal-document') {
        this.cleanModal();
        this.listCompanies();
      }
    });
  },
  computed: {
    clear_cnpj: function () {
      return this.selectedCompany ? this.traitCnpj(this.selectedCompany.cnpj) : '';
    }
  },
  methods: {
    openHolderModal() {
      this.$bvModal.show('show-modal-document');
    },
    debounceSearch() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.listCompanies();
      }, 500)
    },
    listCompanies() {
      this.loadingModal = true;
      companiesService
        .get({ 
          "filter": this.filter,
          "page":this.currentPage,
          "perPage":this.perPage,
        })
        .then(async (response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.companies = response.data;
          this.updateSelectedCompanies();
          this.loadingModal = false;
        }).finally(() => {
          this.loadingModal = false;
        });
    },
    selectCompany(company) {
      this.$parent.selectCompany(company);
      this.updateSelectedCompanies();
      
      if (!this.isMultiple) {
        this.cleanModal();
        if (this.$refs.showModalDocument) {
          this.$refs.showModalDocument.hide();
        }
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    formCompany() {
      this.loadingModalCnpj = true;
      companiesService
        .newCompany(this.dataForm)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.selectCompany( response.data );
        }).catch((error) => {
          this.$refs.formCompany.setErrors( error.response.data.errors );
        }).finally(() => {
          this.loadingModalCnpj = false;
        });
    },
    cleanModal() {
      this.filter = '';
      this.companies = [];
    },
    onChangeCompanyCnpj: function(value) {
      if (value.length == 18) {
        this.findCnpj(value)
      }
    },
    findCnpj(cnpj) {
      this.loadingModalCnpj = true;
      cnpj = this.traitCnpj(cnpj);
      commonsService
        .findCnpj(cnpj)
        .then((response) => {
          if (response.data.error) {
            this.$refs.formCompany.setErrors( response.data.error );
            return;
          }
          
          this.$refs.iptCompanyCnpj.focus();
          this.dataForm.company_name = response.data.name;
          this.dataForm.address = response.data.address;
        })
        .finally(async () =>{
          await this.$refs.formCompany.validate();
          this.loadingModalCnpj = false;
        });
    },
    async updateSelectedCompanies() {
      if (!this.isMultiple) {
        this.companies.forEach((obj, index) => {
          this.$set(this.companies[index], 'selected', (obj.cnpj == this.clear_cnpj));
        });
      } else {
        this.selectedCompanies.forEach((obj) => {
          this.companies.forEach((obj2, index) => {
            this.$set(this.companies[index], 'selected', (obj2.cnpj == obj.cnpj));
          });
        });
      }
    }
  },
  props: {
    isMultiple: {
      type: Boolean,
      required: false
    },
    selectedCompany: {
      type: Object,
      required: false
    },
    selectedCompanies: {
      type: Array,
      required: false
    },
  },
};
</script>

<style>
.lista { overflow-x: hidden; overflow-y: auto; min-height:200px; max-height: 400px; padding: 0px 10px; margin-bottom: 10px; margin-left: -8px; }
.overlay_modal { position: absolute !important; top:0px; left:0px; width:100%; height:100%; color: #FFF; align-items: center; justify-content: center; display:flex; z-index:9999; background-color: rgba(255, 255, 255, 0.4); }
</style>