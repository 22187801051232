<template>
  <apexchart ref="donut" class="chart-legend-80" width="100%" type="donut" :options="options" :series="settings.series"></apexchart>
</template>

<script>

export default {
  name: "DonutChart",
  props: {
    settings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      series: [],
      options: {
        colors: [
          "#008FFB", "#00509d", "#9b5de5", "#f15bb5", "#df2935",
          "#e76f51", "#f4a261", "#e9c46a", "#fee440", "#ABAB8E",
          "#7CAB81", "#a0e426", "#00E396", "#008000", "#264653"
        ],
        chart: {
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: []
            },
            export: {
              csv: {
                filename: undefined,
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                }
              },
              svg: {
                filename: undefined,
              },
              png: {
                filename: undefined,
              }
            },
            autoSelected: 'zoom'
          },
          type: 'donut',
          height: 320,
        },
        legend: {
          position: 'bottom',
          formatter: function(label, series) {
            let insurrence = series.w.globals.series[series.seriesIndex];
            let insurrenceTotal = series.w.globals.series.reduce((a, b) => a + b, 0);
            let insurrenceParticipation = (insurrence * 100 / insurrenceTotal).toFixed(2)
            return insurrence ? `<label class="bold col-md-6 text-sm-center mb-0 pr-0 pl-0">${label}</label><label class="col-md-3 text-sm-center mb-0 pr-0 pl-0">R$ ${insurrence.toLocaleString('pt-BR')}</label><label class="col-md-3 mb-0 text-right text-sm-center pr-0 pl-0">${insurrenceParticipation}%</label>` : ''
          },
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          y: {
            formatter: function(label) {
              return label ? `R$ ${label.toLocaleString('pt-BR')}` : ''
            }
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                value: {
                  offsetY: 5,
                  fontSize: '16px',
                },
                total: {
                  showAlways: true,
                  show: true,
                  formatter: function(series) {
                    return series ? `R$ ${series.globals.series.reduce((a,b) => a + b, 0).toLocaleString('pt-BR')}` : '';
                  },
                }
              },
            },
          },
        },
        responsive: [{
          breakpoint: 575,
          options: {
            chart: {
              height: 300,
            },
          },
        }],
        labels: this.settings.labels,
      },
    }
  },
  watch:{
    settings: {
      immediate: true,
      deep: true,
      handler(data) {
        if(this.$refs.donut) {
          this.$refs.donut.updateOptions({
            series: data.series,
            labels: data.labels
          }, true);
          return
        }
        this.series = data.series
      }
    }
  },
};
</script>

<style>
</style>
