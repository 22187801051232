<template>
<div class="page-container">
	<div class="page-content">

		<div class="page-header">
			<div class="d-flex">
				<h4 class="text-white">Visualizar Apólice</h4>
			</div>
		</div>
    <div v-if="alertMessage" class="col-12">
      <b-alert show :variant="alertVariant">{{ alertMessage }}</b-alert>
    </div>

		<div class="page-body" v-if="policy">
			<div class="card form">
				<div class="card-body">

          <general-data :policy="policy" @getPolicy="getPolicy"/>
          <contract-data :policy="policy"/>
          <insurance-data :policy="policy"/>
          <documents-data :files="files" :policy="policy" @deletePolicyFiles="deletePolicyFiles"/>
          <renovations-data :policy="policy"/>

					<div class="row">
						<div class="col-12 col-lg-12">
							<a @click="back()" class="btn btn-outline-secondary"><i class="fa fa-angle-left"></i> Voltar</a>
							<a 
                v-if="isMaster && policy.status == statusQuotationTypes.ACTIVE"
                class="btn btn-md btn-danger ml-2" 
                @click="cancelPolicy()" 
              >
                <i class="fa fa-solid fa-ban"></i> Cancelar apólice
              </a>
						</div>
					</div>

				</div>
			</div>
		</div>

	</div>
   <div
      v-if="loading"
      class="overlay d-flex align-items-center justify-content-center"
    >
        <div class="d-flex justify-content-center mb-2">
          <b-spinner label="Loading..."></b-spinner>
        </div>
    </div>

  <cancel-policy @getPolicy="getPolicy" :policy="policy"/>
</div>

</template>

<script>
import { ContractData, GeneralData, InsuranceData, DocumentsData, CancelPolicy, RenovationsData } from '@/components/Policy';
import authenticationService from '@/services/authentication';
import policyService from "@/services/policy";
import fileDownload from 'js-file-download';
import statusQuotationTypes from "@/enums/statusQuotationTypes";
import endorsementTypes from "@/enums/endorsementTypes";

export default {
  name: "details-quotation",
  props: {
    policyId: {
      type: String
    }
  },
  components:{
    ContractData,
    GeneralData,
    InsuranceData,
    DocumentsData,
    CancelPolicy,
    RenovationsData
  },
  data: () => ({
    policy: null,
    files: [],
    statusQuotationTypes: statusQuotationTypes,
    loading: false,
    endorsementTypes: endorsementTypes
  }),
  created(){
    this.getPolicy(this.policyId)
    this.findPolicyFiles(this.policyId)
  },
  methods:{
    findPolicyFiles(uid) {
      this.resetAlert();
      policyService
        .getPolicyFiles(uid)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.files = response.data
        })
        .catch(() => {
          this.showError("Não foi possível recuperar os arquivos da apólice.");
        });
    },
    getPolicy(uid){
      this.loading = true;
      this.resetAlert();
      policyService.getByUID(uid).then(response => {
        if (response.data.error) {
          this.showError(response.data.message);
          return
        }
        this.policy = response.data
      }).catch(() => {
        this.showError('Não foi possível recuperar a apólice.');
      }).finally(() => {
        this.loading = false;
      });
    },
    downloadFile(file) {
      policyService.downloadFile(this.policy.id, file.id).then(response => {
        if (response.data.error) {
          this.showError(response.data.message);
          return
        }
        fileDownload(response.data, file.name);
      }).catch(() => {
        this.showError('Não foi possível realizar o download deste arquivo.');
      })
    },
    deletePolicyFiles(file){
       policyService
        .deletePolicyFile(file.policy_id, file.id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return ;
          }
        })
        .catch(() => {
          this.showError("Não foi possível apagar arquivo da cotação");
        })
        .finally(() => {
          this.findPolicyFiles(this.policy.id)
          this.$bvModal.hide('show-modal-del')
        });
      },
    downloadClaimFile(claimId, file) {
      policyService.downloadClaimFile(claimId, file.id).then(response => {
        if (response.data.error) {
          this.showError(response.data.message);
          return
        }
        fileDownload(response.data, file.name);
      }).catch(() => {
        this.showError('Não foi possível realizar o download deste arquivo.');
      })
    },
    back() {
      this.$router.push({
        name: "list-policies",
        query: { filter: true},
      });
    },
    isMaster(){
      return authenticationService.isMaster()
    },
    cancelPolicy() {
      this.$router.push({ name: 'create-endorsement', query: { type: this.endorsementTypes.CANCEL_ENDORSEMENT } });
    }
  }
};
</script>

<style>
</style>
