<template>
  <apexchart class="bar-chart" v-if="!validation.destroy" height="320" type="bar" :options="options" :series="settings.series"></apexchart>
</template>

<script>
import accounting from 'accounting';

export default {
  name: "BarChart",
  props: {
    settings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
    validation:{
        destroy: false
      },
    options : {
      colors: [
        "#008FFB", "#00509d", "#9b5de5", "#f15bb5", "#df2935",
        "#e76f51", "#f4a261", "#e9c46a", "#fee440", "#ABAB8E",
        "#7CAB81", "#a0e426", "#00E396", "#008000", "#264653"
      ],
      chart: {
          type: 'bar',
          height: 250,
          width: '100%',
          stacked: true,
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: []
            },
            export: {
              svg: {
                filename: undefined,
              },
              png: {
                filename: undefined,
              }
            },
            autoSelected: 'zoom'
          },
        },
        legend: {
          position: 'bottom',
        },
        tooltip: {
          y: {
            formatter: function(label) {
              return `R$ ${label.toLocaleString('pt-BR')}`
            }
          },
        },
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        fill: {
          opacity: 1
        },
        yaxis: {
          labels: {
            show: true,
            formatter: function(val) {
              return accounting.formatMoney(val, 'R$ ', 2, '.', ',');
            },
          }
        },
        responsive: [{
          breakpoint: 575,
          options: {
            yaxis: {
              labels: {
                show: false
              }
            },
            xaxis: {
              labels: {
                show: false
              }
            },
          },
        }]
    },
    }
  },
  watch:{
    settings: {
      immediate: true,
      deep: true,
      handler(data) {
        this.validation.destroy = true
        this.options = {...this.options, ...{
          xaxis: {
            type: 'string',
            categories: data.categories
          }
        }}
        this.$nextTick(() => {
          this.createComponent()
        });
      }
    }
  },
  methods:{
    createComponent() {
      this.$set(this.validation, 'destroy', false);
    }
  }
};
</script>

<style>
</style>
