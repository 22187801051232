import dashboard from '@/mixins/typesAnalytics/dashboard';

export default {
  data() {
    return {
      optionsFormatDate: { year: 'numeric', month: '2-digit', day: '2-digit' },
      error: [],
      value: 0,
      max: 100,
      filtering: false,
      numberMaxOfRequests: 7,
      numberOfRequests: 0,
      range: {
        min: null,
        max: null
      }
    }
  },
  mixins: [
    dashboard
  ],
  methods: {
    mountDashboard() {
      this.resetAlert();
      this.dashboard()
    },
    addFilters(min_date, max_date) {
      this.filtering = true
      this.numberOfRequests = 0
      this.dashboard(min_date, max_date)
    },
  },
  watch: {
    max_date() {
      if (this.max_date) {
        var minDate = new Date(this.max_date)
        minDate.setMonth(minDate.getMonth() - 12)
        this.range.min = minDate
      }
      if (this.min_date && this.max_date) {
        this.addFilters(this.min_date, this.max_date)
      }
    },
    min_date() {
      if (this.min_date) {
        var maxDate = new Date(this.min_date)
        maxDate.setMonth(maxDate.getMonth() + 12)
        this.range.max = maxDate
      }
      if (this.min_date && this.max_date) {
        this.addFilters(this.min_date, this.max_date)
      }
    },
    error() {
      if (this.error.length > 0) {
        this.showError(this.error.join(" "))
      }
    },
    numberOfRequests(data) {
      this.value = (data * 100) / this.numberMaxOfRequests
      if (data == this.numberMaxOfRequests) {
        this.filtering = false
        this.value = 0
      }
    },
  }
}
