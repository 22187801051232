<template>
  <div class="page-container">
    <div class="overlay d-flex align-items-center justify-content-center" v-if="loading">
      <b-spinner class="text-primary"></b-spinner>
    </div>
    <div class="page-content">
      <div class="page-header">
        <div class="row space-around mb-3">
          <div class="col-10">
            <h1 class="text-white">
              <i class="mdi mdi-folder-open"></i> Visualizar Endosso
            </h1>
          </div>
        </div>
      </div>
      <div v-if="alertMessage" class="col-12">
        <div class="alert-custom alert-danger-custom">{{ alertMessage }}</div>
      </div>
      <div class="page-body" v-if="endorsement">
        <div class="card form">
          <div class="card-body">
            <general-data :endorsement="endorsement" />
            <observations-table
              @setObservationData="setObservationData"
              @showLoading="showLoading"
              ref="observationsTable"
            />
            <documents-data
              :endorsement="endorsement"
              v-if="endorsement.endorsement_files"
            />
            <observations-modal
              :observation_data="observation_data"
              @showTableData="showTableData"
              @showLoading="showLoading"
            />
            <div class="col-12 col-lg-12">
              <a @click="back()" class="btn btn-outline-secondary">
                <i class="fa fa-angle-left"></i>
                Voltar
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import policyService from "@/services/policy";
import commomsHelper from "@/mixins/commomsHelper";
import {
  GeneralData,
  DocumentsData,
  ObservationsTable,
  ObservationsModal,
} from "@/components/Endorsement";

export default {
  name: "details-endorsement",
  mixins: [commomsHelper],
  components: { GeneralData, DocumentsData, ObservationsTable, ObservationsModal },
  data: () => ({
    endorsement: null,
    loading: false,
    observation_data: [],
  }),
  created() {
    this.getEndorsementByID();
  },
  methods: {
    getEndorsementByID() {
      this.loading = true;
      policyService
        .getEndorsementByUID(this.getIdFromUrl())
        .then((response) => {
          this.endorsement = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar endosso.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setObservationData(data) {
      this.observation_data = data;
    },
    showLoading(value) {
      this.loading = value;
    },
    showTableData() {
      this.$refs.observationsTable.getAllObservations();
    },
    back() {
      this.$router.push({
        name: "list-endorsement",
      });
    },
  },
};
</script>

<style></style>
