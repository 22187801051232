<template>
  <div>
    <div v-if="errors">
      <div class="alert-custom alert-danger-custom">Seus processos não podem ser importados, verifique os erros abaixo e submeta novamente.</div>

      <ErrorViewer :errors="errors" :fieldLabels="fieldLabels"/>
    </div>
    <b-table v-else striped small responsive :items="processes" :fields="fields">
      <template #cell(value_deposit)="data">
        <span>{{ formatCurrency(data.value) }}</span>
      </template>
      <template #cell(liquidation_value)="data">
        <span>{{ formatCurrency(data.value) }}</span>
      </template>
    </b-table>
  </div>
</template>

<script>
import { ErrorViewer } from '@/components';
import numericFormatterMixin from "@/mixins/numericFormatter";

export default {
  name: 'PreviewProcessesStep',
  components: {
    ErrorViewer
  },
  mixins: [
    numericFormatterMixin,
  ],
  props: {
    processes: {
      type: Array
    },
    errors: {
      type: Object
    }
  },
  computed: {
    fields() {
      return Object.keys(this.fieldLabels).map(key => {
        return {
          label: this.fieldLabels[key],
          key,
        }
      });
    }
  },
  data() {
    return {
      fieldLabels: {
        'number': 'Número do Processo',
        'active_part': 'Partes polo Ativo',
        'passive_part': 'Parte polo Passivo',
        'substitution_order': 'Possui pedido substituição',
        'substitution_outcome': 'Desfecho pedido substituição',
        'resource_payments': 'Pagamento de Recursos',
        'resource_types': 'Tipo Recurso',
        'value_deposit': 'Valor Depósito',
        'liquidation_value': 'Valor Liquidação',
        'court': 'Vara',
        'county': 'Comarca',
      }
    }
  }
}
</script>

<style>

</style>
