<template>
  <apexchart ref="pie" class="chart-legend-80" height="600" type="pie" :options="options" :series="series"></apexchart>
</template>

<script>

export default {
  name: "PieChart",
  props: {
    settings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
    series: [],
      options:{
      colors: ['#00E396', '#008FFB', '#FF4560', '#79106E', '#FEB019', '#6462AF', '#F33C9A', '#A277C3', '#B2AE6E', '#844C0D', '#DE8B62'],
      chart: {
          type: 'pie',
          width: '100%',
          height: 300,
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: []
            },
            export: {
              csv: {
                filename: undefined,
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                }
              },
              svg: {
                filename: undefined,
              },
              png: {
                filename: undefined,
              }
            },
            autoSelected: 'zoom'
          },
        },
        legend: {
          position: 'bottom',
          show: true,
          formatter: function(label) {
            return `<label class="bold col-md-12 text-sm-center mb-0 pr-0 pl-0">${label}</label>`
          }
        },
        dataLabels: {
          enabled: true,
          formatter(val) {
            return val ? [val.toFixed(0) + '%'] : ''
          }
        },
        tooltip: {
          y: {
            formatter: function(label) {
              return label ? `R$ ${label.toLocaleString('pt-BR')}` : ''
            }
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: false,
                total: {
                  showAlways: false,
                  show: false,
                }
              },
            },
          },
        },
        responsive: [{
          breakpoint: 575,
          options: {
            chart: {
              height: 300,
            },
          },
        }],
        labels: this.settings.labels
      }
    }
  },
  watch:{
    settings: {
      immediate: true,
      deep: true,
      handler(data) {
        if(this.$refs.pie) {
          this.$refs.pie.updateOptions({
            series: data.series,
            labels: data.labels
          }, true);
          return
        }
        this.series = data.series
      }
    }
  },
  methods:{
    addHtmlClass(data){
      return '<div class="badge badge-success d-block">' + data.value + '</div>'
    }
  }
};
</script>

<style>
</style>
