import { mapGetters } from "vuex";
import store from '@/store';

export default {
  data: () => ({
    perPage: 10,
    currentPage: 1,
    sortBy: 'created_at',
    sortDesc: true,
    filtersTable: {
      policy_holder: '',
      process: '',
      insured: '',
      status: '',
      start_created: '',
      end_created: '',
      document: '',
      insurer : '',
      insurance_category : '',
      expiration_date : '',
      start_date : '',
      end_date : '',
    }
  }),
  computed: {
    ...mapGetters(["getFiltersTable"]),
  },
  methods: {
    setFilterForm() {
      let filter = {};
      filter = this.filtersTable;
      filter.page = this.currentPage;
      filter.sort_by = this.sortBy;
      filter.sort_desc = this.sortDesc;

      store.commit('SET_FILTER', JSON.stringify(filter));  
      this.filtersTable = filter;    
    },
    getFilterForm() {
      if (this.getFiltersTable.length > 0) {
        let filter = JSON.parse((this.getFiltersTable).toString())
        return filter;
      }
      return { };
    },
    fillFilterForm() {
      let filter = JSON.parse((this.getFiltersTable).toString())

      this.filtersTable = filter;
      this.sortBy = filter.sort_by;
      this.sortDesc = filter.sort_desc;
      this.currentPage = filter.page;
    },
  },
}
