<template>
  <b-modal
    v-model="show"
    size="xl"
    :hide-cancel="true"
  >
    <template #modal-title>
      <div>
        <div>
          <h5 class="modal-title">
            Importar planilha de documentos
          </h5>
        </div>
      </div>
    </template>
    <div class="p-3">
      <div v-if="alertMessage" class="alert-custom alert-danger-custom">{{ alertMessage }}</div>

      <UploadFile
        v-if="step == 1"
        v-model="importFile"
      />

      <div class="row" v-if="step == 1">
        <div class="col-sm-6">
          <div class="form-group mt-3">
            <label class="dbo-ui">Cnpj do Tomador</label>
            <b-form-input
              v-model="document_holder"
              placeholder="CNPJ"
              v-mask="documentMask"
            ></b-form-input>
          </div>
        </div>
        <div class="col-md-6 margin-checkbox-copy">
          <b-form-checkbox
            v-model="only_values"
            class="mt-3"
          >
            Importar apenas processos com valor depositado
          </b-form-checkbox>
        </div>
      </div>

      <PreviewProcesses
        v-if="step == 2"
        :processes="processes"
        :errors="errors"
      />
      <div v-if="step == 3">
        <div class="alert-custom alert-success-custom">Sucesso! Seus documentos foram importados. Agora você pode fechar essa janela</div>
      </div>
    </div>
    <template #modal-footer>
      <div class="d-flex flex-grow-1 justify-content-between flex-row-reverse">
        <button
          v-if="step == 2"
          @click="discardImportProcess()"
          type="button"
          class="btn btn-outline-secondary order-2"
        >
          <b-spinner v-if="loadingDiscard" small></b-spinner> Descartar
        </button>
        <button
          v-if="$can('process-import')"
          :disabled="(!importFile || !document_holder) || errors"
          @click="next()"
          type="button"
          class="btn btn-primary order-1"
        >
          <b-spinner v-if="loading" small></b-spinner> {{ nextLabel }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mask } from "vue-the-mask";
import jurimetryService from "@/services/jurimetry";
import UploadFile from "./Steps/UploadFile.vue";
import PreviewProcesses from "./Steps/PreviewProcesses/Index.vue";

export default {
  name: 'ImportProcesses',
  directives: {
    mask
  },
  components: {
    UploadFile,
    PreviewProcesses,
  },
  props: {
    value: {
      type: Boolean
    }
  },
  data() {
    return {
      document_holder: null,
      only_values: false,
      importFile: null,
      loading: false,
      loadingDiscard: false,
      step: 1,
      processes: [],
      importId: null,
      errors: null
    }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    documentMask() {
      return '##.###.###/####-##';
    },
    nextLabel() {
      const labels = {
        1: 'Prosseguir',
        2: 'Confirmar importação',
        3: 'Ok'
      }

      return labels[this.step];
    }
  },
  methods: {
    async next() {
      this.resetAlert();
      if (this.step == 1) {
        await this.sendFile();
        this.step++;
        return;
      }

      if (this.step == 2) {
        await this.confirmProcessesImport();
        this.step++;
        return;
      }

      this.$emit('processesImported');
      this.clearState();
      this.show = false;
    },
    async sendFile() {
      this.loading = true;
      try {

        const responseUpload = await jurimetryService.uploadImportSpreadsheet(this.importFile, this.document_holder, this.only_values);

        if (responseUpload.data.error) {
          this.showError(responseUpload.data.message);
          return;
        }

        this.importId = responseUpload.data.id;
      } catch(error) {
        this.loading = false;
        this.showError('Ocorreu um problema ao fazer upload da planilha');
        throw error;
      }

      let responseStatus = await jurimetryService.getImportSpreadsheetPreview(this.importId);

      while (responseStatus.code == 204) {
        await this.timeout(1000);

        responseStatus = await jurimetryService.getImportSpreadsheetPreview(this.importId);
      }

      this.loading = false;

      if (responseStatus.data.valid) {
        this.processes = responseStatus.data.processes;
        return;
      }

      this.errors = responseStatus.data.errors;
    },
    async confirmProcessesImport() {
      this.loading = true;
      await jurimetryService.confirmImportProcesses(this.importId);
      this.loading = false;
    },
    discardImportProcess() {
      this.loadingDiscard = true;
      jurimetryService.discardImportSpreadsheet(this.importId)
        .then(() => {
          this.clearState();
        })
        .finally(() => {
          this.loadingDiscard = false;
        })
    },
    clearState() {
      this.step = 1;
      this.document_holder = null;
      this.only_values = false;
      this.importFile = null;
      this.importId = null;
      this.processes = [];
      this.errors = null;
      this.resetAlert();
    },
    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  }
};
</script>

<style>
</style>
