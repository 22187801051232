<template>
  <div class="page-container">
    <div class="page-content">
      <div class="page-header">
        <div class="d-flex align-items-center mb-3">
          <h1 class="text-white">
            <i class="mdi mdi-folder-open"></i> Atualizar Certidões
          </h1>
          <span
            @click="redirect(routeRedirect)"
            class="btn btn-outline-white ml-auto"
            ><i class="fa fa-arrow-left mr-1"></i>
            <span class="d-none d-md-inline">Voltar</span></span
          >
        </div>
      </div>

      <div v-if="alertMessage" class="col-12">
        <b-alert show :variant="alertVariant">{{ alertMessage }}</b-alert>
      </div>

      <div class="page-body">
        <div class="card">
          <div class="card-body p-3 p-lg-5">
            <div id="form-cadastrar" class="row form">
              <div class="col-12 mb-3">
                <upload-data ref="upload"
                :files="files"
                :url="urlUpload"
                :forceRedirect="true"
                :title="'Adicione os documentos relacionados a seguradora'"
                @deleteFile="deleteFile"
                @upload-finished="redirect(routeRedirect)"/>
              </div>
              <div class="col-12 text-right">
                <button @click="reportCertificate()" class="button btn btn-primary">
                  <i class="fa fa-check mr-1"></i> Atualizar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UploadData } from '@/components';
import quotationService from "@/services/quotation";

export default {
  name: "create-claim",
  components: {
    UploadData
  },
  props: {
    identifier: {
      type: String,
      required: true
    },
  },
  data: () => ({
    files: [],
    urlUpload: '',
    routeRedirect: 'certificate-list',
  }),
  created(){
    this.findCertificates();
  },
  methods: {
    reportCertificate() {
      this.resetAlert();
      this.urlUpload = `/api/v1/certificates/${this.identifier}/files`
      this.$nextTick(() => {
        if(this.$refs.upload.$refs.dropzone.getAcceptedFiles().length > 0) {
          this.$refs.upload.$refs.dropzone.processQueue();
          return;
        }
        this.redirect(this.routeRedirect);
      });
    },
    findCertificates() {
      quotationService
        .getCertificatesFiles(this.identifier)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.files = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar os arquivos.");
        });
    },
    deleteFile(fileId) {
      quotationService
        .deleteInsurerFile(this.identifier, fileId)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
        })
        .catch(() => {
          this.showError("Não foi possível deletar o arquivo.");
        });
    },
    redirect(routeName) {
      this.$router.push({ name: routeName });
    }
  },
};
</script>

<style>
</style>
