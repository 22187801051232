<template>
  <div class="page-container">
    <div class="page-content">
      <div class="page-header">
        <div class="d-flex">
          <h4 class="text-white">Certidões</h4>
        </div>
      </div>
      <div v-if="alertMessage" class="col-12">
        <b-alert show :variant="alertVariant">{{ alertMessage }}</b-alert>
      </div>

      <div class="page-body">
        <div class="card form">
          <div class="card-body">
            <div class="row">
              <div class="col-12 mb-3">
                <h5>
                  <div class="form-subheader"><span>Documentos</span></div>
                </h5>
              </div>

              <div class="col-12 col-lg-12 mb-3">
                <table class="pretty admin mb-3 shadow">
                  <thead>
                    <tr>
                      <th>Nome do documento</th>
                      <th>Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="file in files" :key="file.id">
                      <td>{{ file.name }}</td>
                      <td>
                        <button
                          @click="downloadFile(file)"
                          type="button"
                          class="button btn btn-primary btn-sm"
                        >
                          <i class="fa fa-download"></i> Download
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-lg-12">
                <a @click="back()" class="btn btn-outline-secondary"
                  ><i class="fa fa-angle-left"></i> Voltar</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import quotationService from "@/services/quotation";
import commomsHelper from "@/mixins/commomsHelper";

export default {
  name: "details-certificate",
  props: {
    identifier: {
      type: String,
    },
  },
  mixins: [commomsHelper],
  data: () => ({
    files: [],
  }),
  created() {
    this.findCertificateFiles();
  },
  methods: {
    findCertificateFiles() {
      this.resetAlert();
      quotationService
        .getCertificatesFiles(this.identifier)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.files = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar os arquivos.");
        });
    },
    downloadFile(file) {
      quotationService
        .downloadInsurerFile(this.identifier, file.id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.openLinkTargetBlank(response.data);
        })
        .catch(() => {
          this.showError("Não foi possível realizar o download deste arquivo.");
        });
    },
    back() {
      this.$router.push({
        name: "certificate-list",
      });
    },
  },
};
</script>

<style>
</style>
