import http from "@/services/http";

const get = (params) => http.get(`policy-renovations`, { params: params })
const store = (uid) => http.post(`policies/${uid}/policy-renovations`)
const updateStatusPolicyRenovation = (uid, dataForm) => http.patch(`policy-renovations/${uid}`, dataForm)
const destroyPolicyRenovation = (uid) => http.delete(`policy-renovations/${uid}`)
const destroyPolicyRenovationFile = (uid, fileId) => http.delete(`policy-renovations/${uid}/files/${fileId}`)
const getPolicyRenovationFile = (uid, fileId) => http.get(`policy-renovations/${uid}/files/${fileId}`)
const getPolicyRenovationAllFiles = (uid) => http.get(`policy-renovations/${uid}/download-all-files`, { responseType: 'blob' })

export default {
  get,
  store,
  updateStatusPolicyRenovation,
  destroyPolicyRenovation,
  destroyPolicyRenovationFile,
  getPolicyRenovationFile,
  getPolicyRenovationAllFiles
}
