import http from "@/services/http";

const store = (dataForm) => http.post('policies', dataForm)
const storeClaim = (dataForm) => http.post('claims', dataForm)
const getClaims = () => http.get('claims')
const storeEndorsement = (dataForm) => http.post('endorsements', dataForm)
const getEndorsements = () => http.get('endorsements')
const getEndorsementByUID = (uid) => http.get(`endorsements/${uid}`)
const getEndorsementFile = (uid, fileId) => http.get(`endorsements/${uid}/files/${fileId}`)
const updateStatusEndorsement = (uid, dataForm) => http.patch(`endorsements/${uid}/update-status`, dataForm)
const destroyEndorsement = (uid) => http.delete(`endorsements/${uid}`)
const update = (uid, dataForm) => http.patch(`policies/${uid}`, dataForm)
const updateUser = (uid, user_id) => http.patch(`policies/${uid}/update-user`, { user_id: user_id })
const destroy = (uid) => http.delete(`policies/${uid}`)
const get = (params) => http.get(`policies`, { params: params })
const getByUID = (uid) => http.get(`policies/${uid}`)
const getPolicyFiles = (uid) => http.get(`policies/${uid}/files`)
const downloadFile = (uid, fileId) => http.get(`policies/${uid}/files/${fileId}`, { responseType: 'blob' })
const downloadClaimFile = (uid, fileId) => http.get(`claims/${uid}/files/${fileId}`, { responseType: 'blob' })
const deletePolicyFile = (uid, fileId) => http.delete(`policies/${uid}/files/${fileId}`)
const downloadReport = (params) => http.get('policy-export', { params: params, responseType: 'blob' })
const deleteFilePolicy = () => http.get('delete-file-policy')
const uploadImportSpreadsheet = (file) => {
  var formData = new FormData();
  formData.append("file", file);

  return http.post('policy-imports', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
const getImportSpreadsheetPreview = (importId) => http.get(`policy-imports/${importId}`)
const discardImportSpreadsheet = (importId) => http.delete(`policy-imports/${importId}`)
const confirmImportPolicies = (importId) => http.post(`policy-imports/${importId}/confirmation`)
const getInsurers = () => http.get(`insurers`)
const getInsuranceCategories = () => http.get(`insurance-categories`)
const getAllInsuranceCategories = () => http.get(`insurance-categories/list`)
const cancelPolicy = (policyId) => http.post(`cancel-policy/${policyId}`)
const getPoliciesDue = (params) => http.get(`policies/index/due`, { params: params })

const getEndorsementObservations = (uid) => http.get(`endorsements/${uid}/observations`)
const showEndorsementObservation = (uid) => http.get(`endorsements/${uid}/observation`)
const updateEndorsementObservations = (uid, dataForm) => http.patch(`endorsements/${uid}/observation-update`, dataForm)
const createEndorsementObservations = (uid, dataForm) => http.post(`endorsements/${uid}/observations`, dataForm)
const destroyEndorsementObservations = (uid) => http.delete(`endorsements/${uid}/observation-delete`)
const downloadMessageFile = (id) => http.get(`endorsements/${id}/download-message-file`)
const deleteMessageFile = (id) => http.delete(`endorsements/${id}/delete-message-file`)
const getIndexFilterValues = () => http.get('filter-values')

export default {
  get,
  getByUID,
  getClaims,
  store,
  update,
  destroy,
  getPolicyFiles,
  storeClaim,
  downloadFile,
  downloadClaimFile,
  deletePolicyFile,
  downloadReport,
  uploadImportSpreadsheet,
  getImportSpreadsheetPreview,
  discardImportSpreadsheet,
  confirmImportPolicies,
  updateUser,
  getEndorsements,
  storeEndorsement,
  getEndorsementByUID,
  getEndorsementFile,
  updateStatusEndorsement,
  destroyEndorsement,
  getInsurers,
  getInsuranceCategories,
  deleteFilePolicy,
  cancelPolicy,
  getAllInsuranceCategories,
  getPoliciesDue,
  getEndorsementObservations,
  showEndorsementObservation,
  createEndorsementObservations,
  updateEndorsementObservations,
  destroyEndorsementObservations,
  downloadMessageFile,
  deleteMessageFile,
  getIndexFilterValues
}
