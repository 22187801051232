<template>
  <div class="page-container">
    <div class="page-content">
      <div class="page-header">
        <div class="row space-around mb-3">
          <div class="col-10">
            <h1 class="text-white">Renovação Apólice</h1>
          </div>
        </div>
      </div>

      <div v-if="alertMessage" class="col-12">
        <div class="alert-custom alert-danger-custom">{{ alertMessage }}</div>
      </div>

      <div class="page-body">
        <div class="card-body p-0">
          <div class="row row-cols-1 row-cols-lg-6">
            <div
              class="col"
              v-for="(column, index) in columnKanbanPolicies()"
              :key="index">
              <div class="card shadow mb-3">
                                
                <div v-bind:class="cardColors[index]" class="card-header border-bottom px-3 text-uppercase text-center font-weight-bold">
                  {{ getNameColumn(column) }}
                </div>

                <!-- Apólices (a vencer) -->
                <div class="card-body p-3 fade-in" v-if="itemsPolicies[column]">
                  <div v-for="(policy, index) in itemsPolicies[column].data"
                    :key="index">
                    <div class="kanban-card">
                      <div class="d-flex align-items-start" v-if="policy">
                        <div class="d-block">
                          <strong class="pointer" @click="$can('policy-show') ? redirect('details-policy', {policyId: policy.id}) : null ">
                            {{ policy.policy_holder_name }}
                          </strong><br />
                          <div class="row">
                            <div class="col-sm-12">
                              <span class="pointer" @click="$can('policy-show') ? redirect('details-policy', {policyId: policy.id}) : null ">
                                <i class="far fa-file-alt mr-2"></i>Apólice nº {{ policy.number }}
                              </span><br />
                            </div>
                            <div class="col-12"><span><i class="far fa-user mr-2"></i>{{ policy.insured_name }}</span></div>
                            
                            <div class="col-sm-12">
                              <i class="fas fa-calendar-alt mr-1"></i>
                              <small :class="getClassTextColor(policy.deadline)" data-toggle="tooltip" :title="(policy.deadline > 0 ? 'Vence em ' : 'Venceu há ') + Math.abs(policy.deadline) + ' dia(s)'" v-b-tooltip.hover>
                                {{ formatDateLocal(policy.validity_expiration) }}
                              </small>
                            </div>
                          </div>
                        </div>

                        <span
                          v-show="$can.any(['policy-edit', 'policy-update'])"
                          @click="showModalCreate(policy)"
                          class="ml-auto" v-b-tooltip.hover  title="Renovar apólice">
                          <button type="button" class="button btn btn-sm btn-link text-secondary">
                            <i class="fa fa-ellipsis-v"></i>
                          </button>
                        </span>
                        
                      </div>
                    </div>
                  </div>
                  <div v-if="itemsPolicies[column]"
                    class="d-flex justify-content-center">
                    <button v-if="itemsPolicies[column].current_page < itemsPolicies[column].last_page"
                      class="btn btn-primary my-2"
                      @click="addMoreResults( column )">
                      Mostrar Mais
                    </button>
                  </div>
                </div>

                <!-- Renovações -->
                <div class="card-body p-3 fade-in" v-if="itemsPolicyRenovation[column]">
                  <div v-for="(policyRenovation, index) in itemsPolicyRenovation[column].data"
                    :key="index">
                    <div class="kanban-card">

                      <div class="d-flex align-items-start" v-if="policyRenovation">
                        <div class="d-block">
                          <p class="pointer m-0" @click="$can('policy-show') ? redirect('details-policy', {policyId: policyRenovation.policy_origin.id}) : null ">
                            <strong>Apólice {{ policyRenovation.policy_origin.number }} - {{ policyRenovation.policy_origin.policy_holder_name }}</strong>
                          </p>
                          <div class="row">
                            <div class="col-sm-9">
                              <span class="pointer" @click="$can('policy-show') ? redirect('details-quotation', {quotationId: policyRenovation.quotation_id}) : null ">
                                <i class="far fa-file-alt mr-2"></i>Cotação nº {{ policyRenovation.quotation ? policyRenovation.quotation.number : '-' }}
                              </span><br />
                            </div>
                            <div class="col-12"><span><i class="far fa-user mr-2"></i>{{ policyRenovation.policy_origin.insured_name }}</span></div>
                          </div>
                        </div>
                        
                        <div class="buttons text-center">
                          <button type="button" class="button btn btn-sm btn-link text-secondary ml-auto"
                            v-show="$can.any(['policy-edit', 'policy-update'])"
                            @click="showModalStatus(policyRenovation, column)"
                            v-b-tooltip.hover title="Mais informações">
                              <i class="fa fa-ellipsis-v"></i>
                          </button>
                          <button type="button" class="button btn btn-sm btn-link text-secondary ml-auto"
                            v-if="policyRenovation.policy_renovation_files"
                            :disabled="loading_download"
                            v-b-tooltip.hover title="Fazer download de todos os arquivos"
                            @click="downloadAllFile(policyRenovation)">
                            <i class="fas fa-paperclip"></i>
                          </button>
                        </div>
                        
                        
                      </div>
                    </div>
                  </div>
                  <div v-if="itemsPolicyRenovation[column]"
                    class="d-flex justify-content-center">
                    <button v-if="itemsPolicyRenovation[column].current_page < itemsPolicyRenovation[column].last_page"
                      class="btn btn-primary my-2"
                      @click="addMoreResults( column )">
                      Mostrar Mais
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- Modal de status de renovação -->
    <b-modal id="modal-renovation-status" ref="modalStatus"
      size="lg">
      <template #modal-title>
        <div>
          <div>
            <h5 class="modal-title" id="modal-cotacao-status-label">
              Alterar Renovação
            </h5>
            <p class="text-muted mb-0">Revise e altere o status da renovação</p>
          </div>
        </div>
      </template>

      <div v-if="policyRenewSelected && policyRenewSelected.policy_origin" class="modal-lg">
        <div class="p-3 pb-0">
          <div>
            <div class="row form">
              <div class="col-sm-4">
                <label class="dbo-ui">Apólice de origem</label>
                <p class="cursor-pointer" @click="redirect('details-policy', {policyId: policyRenewSelected.policy_origin.id})">
                  <i class="far fa-file-alt mr-2"></i>Apólice nº {{ policyRenewSelected.policy_origin.number }}
                </p>
              </div>
              <div class="col-sm-4">
                <label class="dbo-ui">Nova Cotação</label>
                <p  class="cursor-pointer" @click="redirect('details-quotation', {quotationId: policyRenewSelected.quotation.id})">
                  <i class="far fa-file-alt mr-2"></i>Cotação nº {{ policyRenewSelected.quotation ? policyRenewSelected.quotation.number : '-' }}
                </p>
              </div>
              <div class="col-sm-4">
                <label class="dbo-ui">Usuário</label>
                <p>{{ policyRenewSelected.user ? policyRenewSelected.user.name : '---' }}</p>
              </div>
              <div class="col-12" v-if="policyRenewSelected.policy">
                <label class="dbo-ui">Nova Apólice</label>
                <p class="cursor-pointer" @click="redirect('details-policy', {policyId: policyRenewSelected.policy.id})">
                  <i class="far fa-file-alt mr-2"></i>Apólice nº {{ policyRenewSelected.policy.number }}
                </p>
              </div>
              <div class="col-12">
                <label class="dbo-ui">Tomador</label>
                <p>{{ formatCnpj(policyRenewSelected.policy_origin.policy_holder_document) }} - {{ policyRenewSelected.policy_origin.policy_holder_name }}</p>
              </div>
              <div class="col-12">
                <label class="dbo-ui">Segurado/Beneficiário</label>
                <p>{{ policyRenewSelected.insured_name }}</p>
              </div>


              <div class="col-12 mb-2">
                <label class="dbo-ui">Descrição</label>
                <textarea v-if="editing" cols="30" rows="3" class="form-control" v-model="policyRenewSelected.description"></textarea>
                <p v-else>{{ policyRenewSelected.description ?? '---' }}</p>
              </div>

              <div class="col-12 mb-2">
                <label class="dbo-ui">Documentos anexados</label>
                <table  v-if="policyRenewSelected.policy_renovation_files" class="pretty admin mb-3 shadow">
                  <thead>
                    <tr>
                      <th>Nome do documento</th>
                      <th>Criado em</th>
                      <th>Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(file, key) in policyRenewSelected.policy_renovation_files" :key="file.id">
                      <td>{{ file.name }}</td>
                      <td>{{ formatDateTimeFromISO(file.created_at) }}</td>
                      <td class="auto-width">
                        <div class="btn-group">
                          <button @click="downloadFile(file)" type="button"
                            v-b-tooltip.hover title="Fazer download do arquivo"
                            class="button btn btn-outline-primary btn-sm">
                            <i class="fa fa-download"></i>
                          </button>
                          <button @click="deleteFile(file, key)" type="button"
                            v-b-tooltip.hover title="Remover arquivo"
                            :disabled="loading_delete"
                            class="button btn btn-outline-primary btn-sm">
                            <i class="fa fa-trash"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p v-else>---</p>
              </div>

              <div class="col-12" v-if="editing">
                <p>
                  <upload-data ref="upload"
                    :files="files"
                    :url="urlUpload"
                    :title="'Envie os documentos necessários'"
                    :forceRedirect="true"
                    @upload-finished="closeModalStatus()"
                    />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="col-12 col-lg-12 pb-4" v-if="isMaster()">
        <label class="dbo-ui" for="">Status</label>
        <select class="custom-select" v-model="policyRenewSelected.status_id" :disabled="!editing">
          <option value="null" selected>Selecione...</option>
          <option
            v-for="(status, index) in options"
            :key="index"
            :value="status.value">
            {{ status.text }}
          </option>
        </select>
      </div>

      <template #modal-footer>
        <button type="button" class="btn btn-primary"
          @click="editing ? changeStatusPolicyRenovation() : $refs.modalStatus.hide();">
          <b-spinner v-if="loading" small></b-spinner> {{ editing ? 'Alterar' : 'Fechar'}}
        </button>
      </template>
    </b-modal>

    <!-- Modal criação de renovação -->
    <div class="modal">
      <b-modal id="show-modal-create" ref="modalCreate"
        size="md" class="mt-4" hide-footer centered>
        <template #modal-title>Renovar apólice</template>
            
        <div v-if="policyRenewSelected">
          <div class="row form">
            <div class="col-12">
              <p>Deseja Iniciar a renovação da <strong>Apólice nº{{ policyRenewSelected.number }}</strong>?</p>
            </div>
            <div class="col-12">
              <label class="dbo-ui">Tomador</label>
              <p><strong>{{ policyRenewSelected.policy_holder_name }}</strong></p>
            </div>
            <div class="col-12">
              <label class="dbo-ui">Segurado/Beneficiário</label>
              <p><strong>{{ policyRenewSelected.insured_name }}</strong></p>
            </div>
          </div>        
        </div>
        
        <div class="d-block mt-3">
          <div class="d-flex justify-content-between">
            <button
              @click="$bvModal.hide('show-modal-create')"
              class="btn btn-outline-secondary btn-lg px-4 mr-1">
              <i class="fa fa-angle-left mr-2"></i>
              <span class="d-none d-md-inline-block"> Voltar</span>
            </button>
            <button
              type="button"
              class="btn btn-primary btn-lg text-uppercase px-4"
              @click="createRenovation()">
              <b-spinner v-if="loading_create" small></b-spinner> Renovar
            </button>
          </div>
        </div>
      </b-modal>
    </div>


  </div>
</template>

<script>
import policyService from "@/services/policy";
import policyRenovationService from "@/services/policy-renovation";
import dateFormatter from "@/mixins/dateFormatter";
import commomsHelper from "@/mixins/commomsHelper";
import numericFormatter from "@/mixins/numericFormatter";
import authenticationService from "@/services/authentication";
import statusQuotationTypes from "@/enums/statusQuotationTypes";
import translateStatusQuotationTypes from "@/enums/translate/statusQuotationTypes";
import { UploadData } from '@/components';
import fileDownload from "js-file-download";

export default {
  name: "list-kanban-policy-renewal",
  mixins: [dateFormatter, commomsHelper, numericFormatter],
  components: {
    UploadData
  },
  data: () => ({
    itemsPolicies: [],
    itemsPolicyRenovation: [],
    loading: false,
    loading_delete: false,
    loading_create: false,
    loading_download: false,
    deletion_msg: '',
    translateStatusQuotationTypes: translateStatusQuotationTypes,
    statusQuotationTypes: statusQuotationTypes,
    cardColors: {
      UPCOMING: "card-kanban-analysis",
      CONTACT: "card-kanban-analysis",
      AWAITING: "card-kanban-analysis",
      AWAITING_APPROVAL: "card-kanban-analysis",
      FINISHED: "card-kanban-issue",
      CANCELED: "card-kanban-declined",
    },
    limit: 10,
    limitResults: {
      UPCOMING: 10,
      CONTACT: 10,
      AWAITING: 10,
      AWAITING_APPROVAL: 10,
      FINISHED: 10,
      CANCELED: 10,
    },
    policyRenewSelected: {},
    options: [],
    editing: false,
    daysStartsAwaitingContact: -5,
    daysAwaitingContact: 60,
    daysNextRenewal: 120,
    files: [],
    urlUpload: '',
  }),
  created() {
    this.getStatusKanban();
    this.updateKanban();
  },
  methods: {
    updateKanban(page = 1) {
      this.getPoliciesDue(page, this.daysAwaitingContact, this.daysStartsAwaitingContact);
      this.getPoliciesDue(page, this.daysNextRenewal, this.daysAwaitingContact);
      this.getPoliciesRenovations(page, this.statusQuotationTypes.AWAITING_DOCUMENTATION);
      this.getPoliciesRenovations(page, this.statusQuotationTypes.AWAITING_APPROVAL);
      this.getPoliciesRenovations(page, this.statusQuotationTypes.FINISHED);
      this.getPoliciesRenovations(page, this.statusQuotationTypes.CANCELED);
    },
    getPoliciesDue(page = 1, due_days = 0, start_days = 0) {
      this.resetAlert();
      policyService
        .getPoliciesDue({
          page: page,
          due_days: due_days,
          start_days: start_days,
          no_renewals: true
        })
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          
          // Concatena os dados existentes com os recebidos
          // TODO: Improvisado coluna 1 e 2 (apólice a vencer 60 (this.daysAwaitingContact)/120 (this.daysNextRenewal) dias)
          const column = (due_days > this.daysAwaitingContact) ? 1 : 2;
          
          var arrItems = [];
          if (this.itemsPolicies[column] && (page > 1)) {
            arrItems = this.itemsPolicies[column].data;
          }
          response.data.data = [].concat.apply(arrItems, response.data.data);

          this.$set(
            this.itemsPolicies,
            column,
            response.data
          );
        })
        .catch(() => {
          this.showError("Não foi possível recuperar as apólices a vencer.");
        });
    },
    getPoliciesRenovations(page = 1, status = 0) {
      this.resetAlert();
      policyRenovationService
        .get({
          page: page,
          status_id: status,
        })
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          
          // Concatena os dados existentes com os recebidos
          const column = status;
          var arrItems = [];
          if (this.itemsPolicyRenovation[column] && (page > 1)) {
            arrItems = this.itemsPolicyRenovation[column].data;
          }
          response.data.data = [].concat.apply(arrItems, response.data.data);

          this.$set(
            this.itemsPolicyRenovation,
            column,
            response.data
          );
        })
        .catch(() => {
          this.showError("Não foi possível recuperar as renovações.");
        });
    },
    redirect(routeName, params = null) {
      this.$router.push({ name: routeName, params: params });
    },
    isMaster() {
      return authenticationService.isMaster();
    },
    columnKanbanPolicies() {
      return {
        UPCOMING: 1,
        CONTACT: 2,
        AWAITING: 15,
        AWAITING_APPROVAL: 18,
        FINISHED: 16,
        CANCELED: 9,
      };
    },
    addMoreResults(column) {
      var page = 1;
      switch (column) {
        case 1:
          page = (this.itemsPolicies[column].current_page + 1);
          this.getPoliciesDue(page, this.daysNextRenewal, this.daysAwaitingContact);
          break;
        case 2:
          page = (this.itemsPolicies[column].current_page + 1);
          this.getPoliciesDue(page, this.daysAwaitingContact);
          break;
        default:
          page = (this.itemsPolicyRenovation[column].current_page + 1);
          this.getPoliciesRenovations(page, column);
          break;
      }
    },
    getStatusKanban() {
      this.listStatusKanbanPolicyRenewal()
        .forEach((element) => {
          this.options.push({value: element, text: this.getEnumKeyByEnumValue(this.translateStatusQuotationTypes, element)})
        })
    },
    showModalCreate(policy) {
      if (policy) {
        this.policyRenewSelected = policy;
        this.$refs.modalCreate.show();
      }
    },
    createRenovation() {
      this.loading_create = true;
      
      if (!this.policyRenewSelected) {
        this.showError('Não foi possível iniciar a renovação.');
        return;
      }
      
      policyRenovationService
        .store(this.policyRenewSelected.id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }

          this.redirect('garantee-data', {
            quotationId: response.data.quotation_id,
          })
        })
        .catch(() => {
          this.showError('Não foi possível renovar a apólice.');
        })
        .finally(() => {
          this.policyRenewSelected = {};
          this.loading_create = false;
          this.$refs.modalCreate.hide();
        });
    },
    showModalStatus(policy, column) {
      this.editing = (
        [
          statusQuotationTypes.AWAITING_DOCUMENTATION,
          statusQuotationTypes.AWAITING_APPROVAL
        ].includes(column)
      );

      if (policy) {
        this.urlUpload = `/api/v1/policy-renovations/${policy.id}/files`;
        this.policyRenewSelected = policy;
        this.$refs.modalStatus.show();
      }
    },
    closeModalStatus() {
      this.$refs.modalStatus.hide();
      this.updateKanban();
    },
    changeStatusPolicyRenovation() {
      this.loading = true;
      this.resetAlert();
      policyRenovationService
        .updateStatusPolicyRenovation(this.policyRenewSelected.id, this.policyRenewSelected)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }

          this.$nextTick(() => {
            if(this.$refs.upload.$refs.dropzone.getAcceptedFiles().length > 0) {
              this.$refs.upload.$refs.dropzone.processQueue();
              return;
            }

            this.closeModalStatus();
          });

        })
        .catch(() => {
          this.showError("Não foi possível mudar o status da renovação.");
        })
        .finally(() => {
          this.policyRenewSelected.status_id = null;
          this.loading = false;
        });
    },
    showModalDestroy() {
      if (this.policyRenewSelected) {
        this.deletion_msg =
          'Deseja realmente excluir a renovação de ' +
          '<strong>'+this.policyRenewSelected.policy_holder_name + '</strong>?';
        this.$refs.showModal.show();
      }
    },
    destroyPolicyRenovation() {
      this.loading_delete = true;
      this.$refs.showModal.hide();
      policyRenovationService
        .destroyPolicyRenovation(this.policyRenewSelected.id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.updateKanban();
        })
        .catch(() => {
          this.showError('Não foi possível remover a cotação.');
        })
        .finally(() => {
          this.policyRenewSelected = {};
          this.loading_delete = false;
        });
    },
    downloadFile(file) {
      policyRenovationService
        .getPolicyRenovationFile(this.policyRenewSelected.id, file.id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.openLinkTargetBlank(response.data.url);
        })
        .catch(() => {
          this.showError("Não foi possível realizar o download deste arquivo.");
        });
    },
    downloadAllFile(policyRenovation) {
      this.loading_download = true;
      policyRenovationService
        .getPolicyRenovationAllFiles(policyRenovation.id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          fileDownload(response.data, 'download.zip');
          this.loading_download = false;
        })
        .catch(() => {
          this.showError("Não foi possível realizar o download deste arquivo.");
        });
    },
    deleteFile(file, key) {
      this.loading_delete = true;
      policyRenovationService
        .destroyPolicyRenovationFile(file.policy_renovation_id, file.id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.policyRenewSelected.policy_renovation_files.splice(key, 1);
          this.loading_delete = false;
        })
        .catch(() => {
          this.showError("Não foi possível realizar o download deste arquivo.");
        });
    },
    getNameColumn(column) {
      switch (column) {
        case 1:
          return 'Renovação próxima';
        case 2:
          return 'Aguardando contato';
        case 15:
          return 'Aguardando doc';
        default:
          return this.getEnumKeyByEnumValue(this.translateStatusQuotationTypes, column);
      }
    },
    getRealNameColumn(column) {
      switch (column) {
        case 1:
          return 'UPCOMING';
        case 2:
          return 'CONTACT';
        default:
          return this.getEnumKeyByEnumValue(this.statusQuotationTypes, column);
      }
    },
    getClassTextColor(days) {
      if (days < 7) {
        return 'text-danger';
      } else if (days < 15) {
        return 'text-warning';
      }
    }
  },
};
</script>

<style>
</style>
