<template>
  <div class="modal">
    <b-modal
      id="show-modal-observations"
      ref="showModalObservations"
      @show="initializeUserAndDate()"
      size="lg"
      class="mt-4"
      hide-footer
    >
      <template #modal-title>
        <h5 class="modal-title">Adicionar Observação ao Tomador</h5>
      </template>
      <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
        <b-form @submit.stop.prevent="handleSubmit()" class="form" autocomplete="off">
          <br />
          <p class="font-18">Adicione abaixo a observação:</p>
          <div class="text-center">
            <validation-provider
              name="texto da observação"
              rules="required"
              v-slot="validationContext"
              vid="text"
            >
              <b-form-textarea
                rows="11"
                cols="70"
                v-model="observations_data.text"
                :state="getValidationState(validationContext)"
              ></b-form-textarea>
              <b-form-invalid-feedback>{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </validation-provider>
          </div>
          <button
            :disabled="invalid"
            @click="returnObservationData()"
            class="btn btn-primary ml-6 my-2 float-right"
          >
            Salvar
          </button>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import companyService from "@/services/company";
import commomsHelperMixin from "@/mixins/commomsHelper";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import commonsTypes from "@/enums/commonsTypes";
export default {
  name: "ObservationsModal",
  emits: ["showClientData"],
  mixins: [commomsHelperMixin],
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    observations_data: {
      text: "",
      author: "",
      date: "",
      is_edit: false,
    },
    commonsTypes: commonsTypes,
  }),
  created() {
    if (this.getIdFromUrl().length == this.commonsTypes.URL_LENGHT) {
      this.is_edit = true;
    }
  },
  methods: {
    returnObservationData() {
      this.$bvModal.hide("show-modal-observations");
      if (this.is_edit) {
        this.insertObservationData();
      } else {
        this.$emit("setObservationData", this.observations_data);
        this.clearObservationsData();
      }
    },
    clearObservationsData() {
      this.observations_data = {};
    },
    initializeUserAndDate() {
      this.observations_data.author = this.$store.getters.user.name;
      this.observations_data.date = new Date().toISOString().split("T")[0];
    },
    insertObservationData() {
      companyService
        .insertEconomicGroupObservation(this.getIdFromUrl(), this.observations_data)
        .then((response) => {
          if (response.data.error) {
            return;
          }
        })
        .catch(() => {
          this.showError("Não foi possível apagar observação.");
        })
        .finally(() => {
          this.clearObservationsData();
          this.$emit("showClientData");
        });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

<style></style>
