<template>
  <div class="page-container">
    <div class="page-content">
      <div class="page-header">
        <div class="d-flex align-items-center pb-3">
          <div class="col-6">
            <h1 class="text-white">Painel de Cotações</h1>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button
              class="btn btn-outline-white mr-2"
              @click="goListQuotation()"
            >
              <i class="fa fa-cart-plus mr-2"></i>
              <span class="d-none d-md-inline">Listagem de Cotações</span>
            </button>
            <button class="btn btn-outline-white" @click="cleanFilter">
              <i class="fa fa-times mr-2"></i>
              <span class="d-none d-md-inline">Limpar Filtros</span>
            </button>
            <button class="btn btn-outline-white ml-2" v-b-modal.modal-filter>
              <i class="fa fa-filter mr-2"></i>
              <span class="d-none d-md-inline">Filtros</span>
            </button>
          </div>
        </div>
      </div>
      <div v-if="alertMessage" class="col-12">
        <div class="alert-custom alert-danger-custom">{{ alertMessage }}</div>
      </div>
      <div class="page-body">
        <div class="card-body p-0">
          <div class="row row-cols-1 row-cols-lg-5">
            <div
              class="col"
              v-for="(colunm, index) in colunmsKanban()"
              :key="index"
            >
              <div class="card shadow mb-3">
                <div
                  v-bind:class="cardColors[index]"
                  class="
                    card-header
                    border-bottom
                    px-3
                    text-uppercase text-center
                    font-weight-bold
                  "
                >
                  {{
                    getEnumKeyByEnumValue(translateStatusQuotationTypes, colunm)
                  }}
                </div>
                <div class="card-body p-3 fade-in">
                  <div
                    v-for="(quotation, index) in items[
                      getEnumKeyByEnumValue(statusQuotationTypes, colunm)
                    ]"
                    :key="index"
                  >
                    <div
                      class="kanban-card"
                      v-if="
                        index <
                        limitResults[
                          getEnumKeyByEnumValue(statusQuotationTypes, colunm)
                        ]
                      "
                    >
                      <div class="d-flex align-items-start">
                        <div
                          class="pointer"
                          @click="
                            $can('quotation-show')
                              ? redirect('details-quotation', quotation.id)
                              : null
                          "
                        >
                          <strong>{{
                              quotation.name
                          }}</strong
                          ><br />
                          {{ quotation.insured ? quotation.insured.name : "---"
                          }}<br />
                          <span class="text-muted">{{
                            formatCurrency(
                              quotation.guarantee
                                ? quotation.guarantee.contract_value
                                : 0
                            )
                          }}</span>
                        </div>
                        <span
                          v-show="
                            quotation.status != statusQuotationTypes.ISSUED && 
                            $can.any(['quotation-edit', 'quotation-update'])
                          "
                          @click="showModal(quotation)"
                          class="ml-auto"
                          data-toggle="tooltip"
                          title="Alterar status"
                        >
                          <button
                            type="button"
                            data-toggle="modal"
                            data-target="#modal-cotacao-status"
                            class="button btn btn-sm btn-link text-secondary"
                          >
                            <i class="fa fa-ellipsis-v"></i>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="d-flex justify-content-center"
                    v-if="
                      ifExistsPaginate(
                        getEnumKeyByEnumValue(statusQuotationTypes, colunm)
                      )
                    "
                  >
                    <button
                      class="btn btn-primary my-2"
                      @click="
                        addMoreResults(
                          getEnumKeyByEnumValue(statusQuotationTypes, colunm)
                        )
                      "
                    >
                      Mostrar Mais
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de status de cotação -->

    <b-modal
      v-model="show"
      id="modal-cotacao-status"
      size="lg"
    >
      <template #modal-title>
        <div>
          <div>
            <h5 class="modal-title" id="modal-cotacao-status-label">
              Status da cotação
            </h5>
            <p class="text-muted mb-0">Revise e altere o status da cotação</p>
          </div>
        </div>
      </template>
      <div v-if="element" class="modal-lg">
        <div class="p-3 pb-0">
          <div>
            <div class="row form">
              <div class="col-12 col-lg-4">
                <label class="dbo-ui" for="">Tipo de seguro</label>
                <p>
                  {{
                    getEnumKeyByEnumValue(
                      translateInsuranceTypes,
                      element.insurance_type_id
                    )
                  }}
                </p>
              </div>
              <div class="col-12 col-lg-4">
                <label class="dbo-ui" for="">Cliente</label>
                <p>{{ element.insured ? element.insured.name : "---" }}</p>
              </div>
              <div class="col-12 col-lg-4">
                <label class="dbo-ui" for="">Valor</label>
                <p>
                  {{
                    formatCurrency(
                      element.guarantee
                        ? element.guarantee.contract_value *
                            (element.guarantee.guarantee_percent / 100)
                        : 0
                    )
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-12 pb-4" v-if="isMaster()">
        <label class="dbo-ui" for="">Status</label>
        <select class="custom-select" v-model="dataForm.status">
          <option value="null" selected>Selecione...</option>
          <option
            v-for="(status, index) in options"
            :key="index"
            :value="status.value"
          >
            {{ status.text }}
          </option>
        </select>
      </div>
      <template #modal-footer>
        <div>
          <button
            :disabled="disabledButtonChange"
            @click="changeStatusQuotation(element)"
            type="button"
            class="btn btn-primary"
          >
            <b-spinner v-if="loading" small></b-spinner> Alterar
          </button>
        </div>
      </template>
    </b-modal>

    <!-- Modal do filtro -->

    <div class="modal">
      <b-modal
        ref="modalFilter"
        id="modal-filter"
        size="lg"
        class="mt-4"
        centered
      >
        <br />
        <template class="text-align-center" #modal-title>
          Filtrar Cotações
        </template>

        <form class="mb-2">
          <label>Tomador</label>
          <b-form-input
            class="mb-3"
            v-model="policy_holder"
            size="lg"
            id="name-input"
            required
          ></b-form-input>

          <label>Segurado</label>
          <b-form-input
            v-model="insured"
            class="mb-3"
            size="lg"
            id="name-input"
            required
          ></b-form-input>

          <label>Documento <small>(Apenas Números)</small></label>
          <b-form-input
            type="number"
            v-model="document"
            class="mb-3"
            size="lg"
            id="name-input"
            required
            v-on:keypress="isNumber($event)"
          ></b-form-input>
        </form>

        <template #modal-footer>
          <b-button @click="filterQuotations()" variant="outline-primary"
            >Filtrar <i class="fa fa-search"></i
          ></b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import quotationService from "@/services/quotation";
import commomsHelper from "@/mixins/commomsHelper";
import numericFormatterMixin from "@/mixins/numericFormatter";
import statusQuotationTypes from "@/enums/statusQuotationTypes";
import translateInsuranceTypes from "@/enums/translate/insuranceTypes";
import translateStatusQuotationTypes from "@/enums/translate/statusQuotationTypes";
import authenticationService from '@/services/authentication';

export default {
  name: "My-Quotation",
  mixins: [numericFormatterMixin, commomsHelper],
  data: () => ({
    show: false,
    loading: false,
    element: null,
    dataForm: { status: null },
    options: [],
    items: [],
    limit: 10,
    limitResults: {
      APPROVED: 10,
      ISSUE: 10,
      ISSUED: 10,
      DECLINED: 10,
      ISSUE_FAILURE: 10,
    },
    cardColors: {
      APPROVED: "card-kanban-analysis",
      ISSUE: "card-kanban-issue",
      ISSUED: "card-kanban-issued",
      DECLINED: "card-kanban-declined",
      ISSUE_FAILURE: "card-kanban-declined",
    },
    translateStatusQuotationTypes: translateStatusQuotationTypes,
    translateInsuranceTypes: translateInsuranceTypes,
    statusQuotationTypes: statusQuotationTypes,
    policy_holder: "",
    insured: "",
    status: "",
    start_created: "",
    end_created: "",
    document: "",
    perPage: 30,
    currentPage: 1
  }),
  computed: {
    disabledButtonChange() {
      return this.dataForm.status ? false : true;
    },
  },
  created() {
    this.getStatusKanban();
    this.getQuotations();
  },
  methods: {
    goListQuotation() {
      this.$router.push({ name: "list-quotation" });
    },
    changeStatusQuotation(element) {
      this.loading = true;
      this.resetAlert();
      quotationService
        .updateStatusQuotation(element.id, this.dataForm)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.getQuotations();
        })
        .catch(() => {
          this.showError("Não foi possível mudar o status da cotação.");
        })
        .finally(() => {
          this.show = false;
          this.loading = false;
        });
    },
    redirect(routeName, id) {
      this.$router.push({ name: routeName, params: { quotationId: id } });
    },
    getQuotations() {
      this.resetAlert();
      quotationService
        .getKanbanData(this.formatFilters())
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.formatListQuotation(response.data);
        })
        .catch(() => {
          this.showError("Não foi possível recuperar as cotações.");
        });
    },
    formatFilters() {
      return {
        policy_holder: this.policy_holder,
        insured: this.insured,
        status: this.status,
        start_created: this.start_created,
        end_created: this.end_created,
        document: this.document,
        page: this.currentPage,
        per_page: this.perPage,
      };
    },
    formatListQuotation(data) {
      Object.keys(statusQuotationTypes).forEach((element) => {
        element != "DRAFT"
          ? this.$set(
              this.items,
              element,
              data.data.filter((elementData) => {
                return elementData.status == statusQuotationTypes[element];
              })
            )
          : null;
      });
    },
    getEnumKeyByEnumValue(myEnum, enumValue) {
      let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
      return keys.length > 0 ? keys[0] : null;
    },
    showModal(element) {
      this.element = element;
      this.dataForm.status = null;
      this.show = true;
    },
    colunmsKanban() {
      return {
        APPROVED: 3,
        ISSUE: 4,
        ISSUED: 5,
        DECLINED: 6,
        ISSUE_FAILURE: 7,
      };
    },
    filterQuotations() {
      this.getQuotations();
      this.$refs.modalFilter.hide();
    },
    cleanFilter() {
      this.policy_holder = "";
      this.insured = "";
      this.document = "";
      this.getQuotations();
    },
    addMoreResults(type) {
      this.$set(
        this.limitResults,
        type,
        (this.limitResults[type] += this.limit)
      );
    },
    ifExistsPaginate(type) {
      if (Array.isArray(this.items[type]) && this.items[type].length > 0) {
        return this.items[type].length > this.limitResults[type];
      }
      return false;
    },
    getStatusKanban() {
      this.listStatusKanbanQuotation()
        .forEach((element) => {
          this.options.push({value: element, text: this.getEnumKeyByEnumValue(this.translateStatusQuotationTypes, element)})
        })
    },
    isMaster() {
      return authenticationService.isMaster();
    },
  },
};
</script>

<style>
</style>
