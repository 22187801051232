<template>
  <div class="row">
    <div class="col-12 col-lg-12">
      <div class=" mb-3">
        <h5>
          <div class="form-subheader"><span>Documentos anexos</span></div>
        </h5>
      </div>
      <div class="mb-3">
        <table class="pretty admin mb-3 shadow">
          <thead>
            <tr>
              <th class="table-80">Nome do documento</th>
              <th>Ação</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="file in files" :key="file.id">
              <td>{{ file.name }}</td>
              <td>
                <button @click="openLinkTargetBlank(file.url)" type="button" class="button btn btn-primary btn-sm">
                  <i class="fa fa-download"></i> Download </button>

                <button @click="showModalDestroy(file)" type="button" class="button btn  btn-outline-primary btn-sm ml-2">
                  <i class="fa fa-times"></i> Excluir </button>
              </td>
            </tr>
            <template v-for="claim in policy.claims">
              <tr v-for="element in claim.claim_files" :key="element.id">
                <td>{{ element.name }}</td>
                <td>
                  <button @click="openLinkTargetBlank(element.url)" type="button" class="button btn btn-primary btn-sm">
                    <i class="fa fa-download"></i> Download </button>

                  <button @click="showModalDestroy(file)" type="button"
                    class="button btn  btn-outline-primary btn-sm ml-2">
                    <i class="fa fa-times"></i> Excluir </button>
                </td>
              </tr>
            </template>
            <tr v-if="documentFile">
              <td>{{ policy.document_file_name }}</td>
              <td>
                <button @click="openLinkTargetBlank(policy.document_file)" type="button"
                  class="button btn btn-primary btn-sm">
                  <i class="fa fa-download"></i> Download </button>

                <button @click="showModalDestroy(file)" type="button" class="button btn  btn-outline-primary btn-sm ml-2">
                  <i class="fa fa-times"></i> Excluir </button>
              </td>
            </tr>
            <tr v-if="billingFile">
              <td>{{ policy.billing_file_name }}</td>
              <td>
                <button @click="openLinkTargetBlank(policy.billing_file)" type="button"
                  class="button btn btn-primary btn-sm">
                  <i class="fa fa-download"></i> Download </button>

                <button @click="showModalDestroy(file)" type="button" class="button btn  btn-outline-primary btn-sm ml-2">
                  <i class="fa fa-times"></i> Excluir </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal">
        <b-modal id="show-modal-del" ref="showModalDel" size="sm" class="mt-4" hide-footer centered>
          <br />
          <template #modal-title>
            Excluir arquivo
          </template>

          <div class="card card-termo-uso mt-1 mb-5">
            <p class="my-4">Deseja realmente excluir o arquivo {{ file.name }} ?</p>
          </div>
          <div class="row">
            <div class="col-12 col-lg-12">
              <button @click="$bvModal.hide('show-modal')"
                class="btn btn-outline-secondary btn-lg text-uppercase px-4 mr-1">
                <i class="fa fa-angle-left mr-2"></i><span class="d-none d-md-inline-block"> Voltar</span>
              </button>
              <button @click="deletePolicyFiles(file)" class="btn btn-primary btn-lg text-uppercase px-4"
                data-toggle="modal" data-target="#modal-cnpj">
                Excluir<i class="fa fa-angle-right ml-2"></i>
              </button>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
    <div class="col-12 col-lg-12 mt-3" v-if="policy.endorsements && policy.endorsements.length > 0">
      <div class="mb-3">
        <h5>
          <div class="form-subheader"><span> Dados do Endosso</span></div>
        </h5>
      </div>
      <div class="mb-3">
        <table class="pretty admin mb-3 shadow">
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Status</th>
              <th>Data/Hora</th>
              <th>Usuário</th>
              <th>Ação</th>
            </tr>
          </thead>
          <tbody>
            <template>
              <tr v-for="endorsement in policy.endorsements" :key="endorsement.id">
                <td>{{ getEnumKeyByEnumValue(translateEndorsementTypes, endorsement.type) }}</td>
                <td>{{ endorsement.status.translate }}</td>
                <td>{{ formatDateTimeLocal(endorsement.created_at) }}</td>
                <td>{{ endorsement.user ? endorsement.user.name : '' }}</td>
                <td>
                  <i @click="
                    redirect('details-endorsement', {
                      endorsementId: endorsement.id,
                    })
                    " v-b-tooltip.hover title="Visualizar Endosso"
                    class="fas fa-eye icon-btn mx-1 small align-self-center"></i>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div class="modal">
        <b-modal id="show-modal-del" ref="showModalDel" size="sm" class="mt-4" hide-footer centered>
          <br />
          <template #modal-title>
            Excluir arquivo
          </template>

          <div class="card card-termo-uso mt-1 mb-5">
            <p class="my-4">Deseja realmente excluir o arquivo {{ file.name }} ?</p>
          </div>
          <div class="row">
            <div class="col-12 col-lg-12">
              <button @click="$bvModal.hide('show-modal')"
                class="btn btn-outline-secondary btn-lg text-uppercase px-4 mr-1">
                <i class="fa fa-angle-left mr-2"></i><span class="d-none d-md-inline-block"> Voltar</span>
              </button>
              <button @click="deletePolicyFiles(file)" class="btn btn-primary btn-lg text-uppercase px-4"
                data-toggle="modal" data-target="#modal-cnpj">
                Excluir<i class="fa fa-angle-right ml-2"></i>
              </button>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import commomsHelper from '@/mixins/commomsHelper';
import dateFormatter from "@/mixins/dateFormatter";
import translateEndorsementTypes from "@/enums/translate/endorsementTypes";

export default {
  name: "DocumentsData",
  emits: ['deletePolicyFiles'],
  data: () => ({
    file: {},
    translateEndorsementTypes: translateEndorsementTypes,
  }),
  computed: {
    documentFile() {
      return this.policy && this.policy.document_file
    },
    billingFile() {
      return this.policy && this.policy.billing_file
    }
  },
  mixins: [
    commomsHelper,
    dateFormatter
  ],
  methods: {
    deletePolicyFiles(file) {
      this.$emit('deletePolicyFiles', file);
    },
    showModalDestroy(file) {
      this.$refs.showModalDel.show();
      this.file = file;
    },
    redirect(routeName, params = null) {
      this.$router.push({ name: routeName, params: params });
    },
  },
  props: {
    policy: {
      type: Object,
      required: true
    },
    files: {
      type: Array,
      required: true
    },
  }
};
</script>

<style></style>
