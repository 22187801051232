<template>
  <div class="modal">
    <b-modal
      id="show-modal-company"
      ref="showModalCompany"
      size="md"
      class="mt-4"
      @hidden="modalCompanyHide"
      hide-footer
    >
      <br />
      <template #modal-title>
        <h5 class="modal-title">Adicionar empresa</h5>

        <p class="text-muted mb-0 small mt-1">
          Adicione uma nova empresa ao grupo econômico
        </p>
      </template>
      <div
        v-if="loading"
        class="overlay d-flex align-items-center justify-content-center"
      >
        <div class="d-flex justify-content-center mb-2">
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>
      <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
        <b-form
          @submit.stop.prevent="handleSubmit()"
          class="form"
          autocomplete="off"
        >
          <div class="card mt-1 px-3 py-2">
            <div class="col-md-12 mb-3">
              <validation-provider
                name="cnpj do cliente"
                rules="required|min:14"
                v-slot="validationContext"
                vid="cnpj"
              >
                <label class="dbo-ui">CNPJ</label>
                <b-form-input
                  @change="onChangeCompanyGroupCnpj"
                  v-model="economicGroup.cnpj"
                  class="col-md-11"
                  v-mask="'##.###.###/####-##'"
                  :state="getValidationState(validationContext)"
                  placeholder="Digite o CNPJ da empresa"
                ></b-form-input>
                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </div>
            <div class="col-md-12 mb-3">
              <validation-provider
                name="Nome fantasia do cliente"
                rules="required"
                v-slot="validationContext"
                vid="nome"
              >
                <label class="dbo-ui">nome</label>
                <b-form-input
                  v-model="economicGroup.company_name"
                  :state="getValidationState(validationContext)"
                  class="col-md-11"
                  placeholder="Digite o nome da empresa"
                >
                </b-form-input>
                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </div>
            <div class="col-12 col-lg-12">
              <label class="dbo-ui"> TIPO DA EMPRESA</label>
              <div class="row mt-1 col-md-9">
                <div class="row col-md-12">
                  <div class="col-6">
                      <div class="d-flex flex-column">
                          <b-form-radio
                              value="1"
                              v-model="economicGroup.type"
                              class="ml-3 ml-1 mb-2"
                          >
                            Coligada
                          </b-form-radio>
                          <b-form-radio
                              value="2"
                              v-model="economicGroup.type"
                              class="ml-3"
                          >
                              Filial
                          </b-form-radio>
                      </div>
                  </div>
                  <div class="col-6">
                    <div class="d-flex flex-column">
                      <b-form-radio
                          value="3"
                          v-model="economicGroup.type"
                          class="ml-3 ml-1 mb-2"
                      >
                          Controlada
                      </b-form-radio>
                      <b-form-radio
                          value="4"
                          v-model="economicGroup.type"
                          class="ml-3"
                      >
                          Controladora
                      </b-form-radio>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row border-top mt-4">
            <div class="col-12 col-lg-12">
              <button
                class="btn btn-primary mx-2 my-2 float-right"
                :disabled="invalid"
                @click="returnEconomicGroup"
              >
                Salvar
              </button>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import commonsService from "@/services/commons";
import { mask } from "vue-the-mask";
import commomsHelperMixin from "@/mixins/commomsHelper";

export default {
  name: "EconomicGroup",
  emits: ["returnEconomicGroup"],
  data: () => ({
    loading: false,
  }),

  props: {
    economicGroup: {
      type: Object,
    },
  },
  mixins: [commomsHelperMixin],
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    mask,
  },
  methods: {
    returnEconomicGroup() {
      this.$emit("setEconomicGroup", this.economicGroup);
      this.resetModal();
    },
    modalCompanyHide() {
      this.resetModal();
    },
    onChangeCompanyGroupCnpj: function (cnpj) {
      this.loading = true;
      cnpj = this.traitCnpj(cnpj);
      commonsService
        .findCnpj(cnpj)
        .then((response) => {
          if (response.data.error) {
            return;
          }
          this.economicGroup.company_name = response.data.name;
          this.economicGroup.address = response.data.address;
        })
        .finally(() => {
          this.modalCompanyHide;
          this.loading = false;
        });
    },
    resetModal() {
      this.economicGroup.cnpj = null;
      this.economicGroup.company_name = null;
      this.economicGroup.type = 1;
      this.economicGroup.address = null;
      this.economicGroup.index = null;
      this.$refs.showModalCompany.hide();
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

<style>
</style>
