export default {
  BALANCE2020: 'BALANCE-2020',
  BALANCE2021: 'BALANCE-2021',
  BALANCE2022: 'BALANCE-2022',
  BALANCE2023: 'BALANCE-2023',
  BALANCE2024: 'BALANCE-2024',
  CORPORATE_DOCUMENTATION: "CORPORATE-DOCUMENTATION",
  APPOINTMENT: "APPOINTMENT",
  OTHERS: "OTHERS",
}
