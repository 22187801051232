<template>
  <div class="container">
    <div class="row">
      <p>
        Para realizar a importação de processos, primeiro faça o download do
        template, preencha os dados e faça
        upload do arquivo novamente através da opção abaixo.
        <a :href="templateUrl" target="_blank"><b>Clique aqui para baixar o modelo.</b></a>
      </p>
    </div>
    <div class="row">
      <b-form-file
        v-model="importFile"
        :state="Boolean(importFile)"
        placeholder="Selecione o arquivos ou arraste para esse campo"
        drop-placeholder="Solte o arquivo aqui"
      ></b-form-file>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadFileStep',
  props: {
    value: {
      type: File
    }
  },
  computed: {
    importFile: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    templateUrl() {
      return process.env.VUE_APP_API_URL + '/processes/download-template-import';
    }
  },
};
</script>

<style>
</style>
