import analyticsService from "@/services/analytics";

export default {
  methods: {
    dashboard(min_date = null, max_date = null, groupby) {
      var params = min_date && max_date ? { min_date: min_date, max_date: max_date } : {}
      params.groupby = groupby
      analyticsService.dashboard(params).then(response => {
        if (response.data.error) {
          this.error.push(response.data.message);
          return
        }
        this.collection.dashboard = response.data
        this.collection.dashboard.guarantee_insurance_premiums = this.formatDataGuaranteeInsurancePremiums(response.data.guarantee_categories_summary)
        this.collection.dashboard.insured_amount = this.formatDataInsuredAmount(response.data.guarantee_categories_summary)
        this.collection.dashboard.appeal_guarantee_summary = this.formatDataAppealGuaranteeSummary(response.data.appeal_guarantee_summary)
        this.collection.dashboard.monthly_insurance_premiums = this.formatDataMonthlyInsurancePremiums(response.data.monthly_insurance_premiums)
        this.collection.dashboard.insurance_premiums_by_type = this.formatDatainsurancePremiums(response.data.insurance_premiums_by_type)
        this.collection.dashboard.insurance_premiums_by_insurer = this.formatDatainsurancePremiums(response.data.insurance_premiums_by_insurer)
      }).catch(() => {
        this.error.push('Não foi possível recuperar dados do dashboard.');
      }).finally(() => {
        this.numberOfRequests = 7;
      });
    },
    formatDataInsuredAmount(element) {
      return { series: element.map(value => { return value.guarantee_value }), labels: element.map(value => { return value.insurance_name }) }
    },
    formatDataGuaranteeInsurancePremiums(element) {
      return { series: element.map(value => { return value.insurance_premium_paid }), labels: element.map(value => { return value.insurance_name }) }
    },
    formatDataAppealGuaranteeSummary(element) {
      return { series: element.map(value => { return value.insurance_premium_paid }), labels: element.map(value => { return value.resource_type_name }) }
    },
    formatDataMonthlyInsurancePremiums(data) {
      const months = {
        1: 'Jan',
        2: 'Fev',
        3: 'Mar',
        4: 'Abr',
        5: 'Mai',
        6: 'Jun',
        7: 'Jul',
        8: 'Ago',
        9: 'Set',
        10: 'Out',
        11: 'Nov',
        12: 'Dez'
      }
      var chartData = {
        categories: data.map(el => months[el.month] + '/' + el.year),
        series: []
      }
      var types = this.getAllInsuranceTypes(data) || [];
      types.forEach(({ name, id }) => {
        var serieItem = { name, data: [] }
        data.forEach(el => {
          var typeValue = el.insurance_types.find(elementData => elementData.id == id)
          serieItem.data.push(typeValue ? typeValue.amount : 0);
        })
        chartData.series.push(serieItem)
      })
      return chartData;
    },
    getAllInsuranceTypes(data) {
      return data
        .flatMap(e => e.insurance_types)
        .map(e => ({ id: e.id, name: e.name }))
        .filter((item, index, items) => items.findIndex(e => e.id == item.id) === index);
    },
    formatDatainsurancePremiums(element) {
      return { series: element.map(value => { return value.amount }), labels: element.map(value => { return value.label }) }
    },
  }
}
