<template>
  <div class="page-container">
    <div class="page-content">
      <div class="page-header">
        <div class="d-flex align-items-center mb-4">
          <h1 class="text-white">Listagem de Cotações</h1>
          <button class="ml-auto btn btn-outline-white" @click="cleanFilter">
            <i class="fa fa-times mr-2"></i>
            <span class="d-none d-md-inline">Limpar Filtros</span>
          </button>
          <button class="btn ml-3 btn-outline-white"  v-b-modal.modal-filter>
            <i class="fa fa-filter mr-2"></i>
            <span class="d-none d-md-inline">Filtros</span>
          </button>
        </div>
      </div>
      <div v-if="alertMessage" class="col-12">
        <div class="alert-custom alert-danger-custom">{{ alertMessage }}</div>
      </div>
      <div class="page-body">
        <div class="card">
          <div class="card-body p-0">
            <div class="table-responsive shadow">
              <b-table
                class="table pretty-title-gray mb-0"
                :items="items"
                :fields="fields"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                @sort-changed="sortingTableChanged"
                show-empty
                no-local-sorting>
                <template #empty="">
                  <p class="text-center">Não encontramos registros.</p>
                </template>
                <template #cell(id)="data">
                  <span>#{{ data.value }}</span>
                </template>
                <template #cell(status)="data">
                  <div
                    v-if="
                      isMaster() &&
                      (
                        data.value == statusQuotationTypes.DECLINED ||
                        data.value == statusQuotationTypes.ISSUE_FAILURE || 
                        data.value == statusQuotationTypes.DRAFT
                      )
                    "
                  >
                    <span
                      @click="showModalExeption(data)"
                      :class="classStatusQuotation(data.value) + ' d-block '"
                      style="min-width: 120px"
                      >{{
                        getEnumKeyByEnumValue(translateStatusQuotationTypes, data.value)
                      }}</span
                    >
                  </div>
                  <div
                    v-else-if="
                      data.value == statusQuotationTypes.ISSUED &&
                      data.item.policy_id
                    "
                  >
                    <span
                      @click="
                        redirect('details-policy', {
                          policyId: data.item.policy_id,
                        })
                      "
                      :class="classStatusQuotation(data.value) + ' d-block '"
                      style="min-width: 120px"
                      >{{
                        getEnumKeyByEnumValue(translateStatusQuotationTypes, data.value)
                      }}</span
                    >
                  </div>
                  <div v-else>
                    <span
                      :class="classStatusQuotation(data.value) + ' d-block '"
                      style="min-width: 120px"
                      >{{
                        getEnumKeyByEnumValue(translateStatusQuotationTypes, data.value)
                      }}</span
                    >
                  </div>
                </template>
                <template
                  class="table-td cursor-default text-center nowrap"
                  #cell(action)="data"
                >
                  <span
                    v-if="
                      data.item.status == statusQuotationTypes.APPROVED &&
                      isMaster()
                    "
                    @click="emitQuotation(data.value)"
                    class="text-secondary"
                    v-b-tooltip.hover
                    title="Gerar apólice manualmente"
                  >
                    <i class="far fa-handshake mx-1 icon-btn"></i>
                  </span>
                  <span
                    v-if="
                      data.item.status == statusQuotationTypes.APPROVED &&
                      $can('policy-issue')
                    "
                    @click="
                      redirect('final-feedback-quotation', {
                        quotationId: data.value,
                      })
                    "
                    class="text-secondary"
                    v-b-tooltip.hover
                    title="Emitir"
                  >
                    <i class="fas fa-angle-double-right mx-1 icon-btn"></i>
                  </span>
                  <span
                    v-else-if="
                      $can.any([
                        'quotation-store',
                        'quotation-update',
                        'quotation-edit',
                      ])
                    "
                    @click="
                      verifyStatusQuotation(data.item.status)
                        ? null
                        : editQuotation(data.value, data.item.status)
                    "
                    class="text-secondary"
                    v-b-tooltip.hover
                    title="Editar"
                  >
                    <i
                      class="fas fa-edit mx-1"
                      :class="{
                        'icon-btn': !verifyStatusQuotation(data.item.status),
                        'icon-disabled': verifyStatusQuotation(
                          data.item.status
                        ),
                      }"
                    ></i>
                  </span>
                  <span
                    v-if="$can('quotation-show')"
                    @click="redirect('details-quotation', {quotationId: data.value})"
                    data-toggle="tooltip"
                    class="text-secondary"
                    v-b-tooltip.hover
                    title="Visualizar"
                  >
                    <i
                      class="fas fa-eye mx-1 icon-btn"
                    ></i>
                  </span>
                  <span
                    v-if="$can.any(['quotation-store'])"
                    @click="cloneQuotation(data.value)"
                    class="text-secondary"
                    v-b-tooltip.hover
                    title="Clonar cotação"
                  >
                    <i class="far fa-copy mx-1 icon-btn"></i>
                  </span>
                  <span
                    v-if="$can('quotation-delete')"
                    @click="
                      actionAvaiable(data.item.status)
                        ? null
                        : showModalDestroy(data)
                    "
                    data-toggle="tooltip"
                    class="text-secondary"
                    v-b-tooltip.hover
                    title="Apagar"
                  >
                    <i
                      class="fas fa-trash mx-1"
                      :class="{
                        'icon-btn': !actionAvaiable(data.item.status),
                        'icon-disabled': actionAvaiable(data.item.status),
                      }"
                    ></i>
                  </span>
                </template>
              </b-table>
            </div>
            <nav aria-label="Paginação" class="m-3">
              <b-pagination
                v-if="rows>0"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                @page-click="pageChanged"
                aria-controls="my-table"
              ></b-pagination>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="loading"
      class="overlay d-flex align-items-center justify-content-center"
    >
      <div>
        <div class="d-flex justify-content-center mb-2">
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div class="d-flex align-items-center">
          <strong>{{ msgLoading }}</strong>
        </div>
      </div>
    </div>


    <div class="modal">
      <b-modal
        id="show-modal"
        ref="showModal"
        size="sm"
        class="mt-4"
        hide-footer
        centered>
        <br />
        <template #modal-title>
          {{ title }}
        </template>

        <div class="card card-termo-uso mt-1 mb-5">
          <p class="my-4">{{ deletion_data }}</p>
        </div>
        <div class="row">
          <div class="col-12 col-lg-12">
            <button
              @click="$bvModal.hide('show-modal')"
              class="btn btn-outline-secondary btn-lg text-uppercase px-4 mr-1"
            >
              <i class="fa fa-angle-left mr-2"></i
              ><span class="d-none d-md-inline-block"> Voltar</span>
            </button>
            <button
              @click="checkPolice ? checkPolicyExist() : destroyQuotation()"
              class="btn btn-primary btn-lg text-uppercase px-4"
              data-toggle="modal"
              data-target="#modal-cnpj"
            >
              Excluir<i class="fa fa-angle-right ml-2"></i>
            </button>
          </div>
        </div>
      </b-modal>
    </div>
    
    <div class="modal">
      <b-modal
        id="show-modal-confirm-clone"
        ref="showModalConfirmClone"
        hide-footer
        centered>
        <template #modal-title>Confirmação</template>

        <div class="card">
          <p>Deseja clonar essa cotação?</p>
        </div>
        <div class="row">
          <div class="col-12 col-lg-12">
            <button
              @click="$bvModal.hide('show-modal-confirm-clone')"
              class="btn btn-outline-secondary btn-lg text-uppercase px-4 mr-1"
            >
              <i class="fa fa-angle-left mr-2"></i
              ><span class="d-none d-md-inline-block"> Cancelar</span>
            </button>
            <button
              @click="confirmCloneQuotation()"
              class="btn btn-primary btn-lg text-uppercase px-4"
            >
              Confirmar<i class="fa fa-angle-right ml-2"></i>
            </button>
          </div>
        </div>
      </b-modal>
    </div>

    <div class="modal">
      <b-modal
        id="show-modal-confirm-emit"
        ref="showModalConfirmEmit"
        hide-footer
        size="confirmation"        
        centered>
        <template #modal-title>Confirmação</template>

        <div class="card">
          <p>Deseja emitir apólice desta cotação?</p>
        </div>
        <div class="row">
          <div class="col-12 col-lg-12">
            <button
              @click="$bvModal.hide('show-modal-confirm-emit')"
              class="btn btn-outline-secondary btn-lg text-uppercase px-4 mr-1"
            >
              <i class="fa fa-angle-left mr-2"></i
              ><span class="d-none d-md-inline-block"> Cancelar</span>
            </button>
            <button
              @click="confirmEmitQuotation()"
              class="btn btn-primary btn-lg text-uppercase px-4"
            >
              Confirmar<i class="fa fa-angle-right ml-2"></i>
            </button>
          </div>
        </div>
      </b-modal>
    </div>

    <div class="modal">
      <b-modal
        ref="modalFilter"
        id="modal-filter"
        size="lg"
        class="mt-4"
        centered>
        <br />
        <template class="text-align-center" #modal-title>
          Filtrar Cotações
        </template>

        <form class="mb-2">
          <label>Tomador</label>
          <b-form-input
            class="mb-3"
            v-model="filtersTable.policy_holder"
            size="lg"
            id="name-input"
            required
          ></b-form-input>

          <label>Segurado</label>
          <b-form-input
            v-model="filtersTable.insured"
            class="mb-3"
            size="lg"
            id="name-input"
            required
          ></b-form-input>

          <label for="">Processo</label>
          <b-form-input
            class="mb-3"
            v-model="filtersTable.process"
            size="lg"
            id="name-input"
            required
          ></b-form-input>

          <div id="filtros" class="row">
            <div class="col-12 col-lg-6 mb-3">
              <label>Tipo de seguro</label>
              <div class="input-group">
                <b-form-select v-model="filtersTable.insurance" class="mb-3">
                <b-form-select-option value="">Sem Filtro</b-form-select-option>
                <b-form-select-option
                  v-for="(value, index) in list_insurance"
                  :value="value.id"
                  :key="index"
                >
                  {{ value.name }}
                </b-form-select-option>
              </b-form-select>
              </div>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <label>Seguradora</label>
              <div class="input-group">
                <b-form-select v-model="filtersTable.insurer" class="mb-3">
                <b-form-select-option value="">Sem Filtro</b-form-select-option>
                <b-form-select-option
                  v-for="(value, index) in list_insurers"
                  :value="index"
                  :key="index"
                >
                  {{ value }}
                </b-form-select-option>
              </b-form-select>
              </div>
            </div>
          </div>

          <label>Status</label>
          <b-form-select v-model="filtersTable.status" :options="options()" class="mb-3">
          </b-form-select>

          <label>Data da Criação</label>
          <div id="filtros" class="row">
            <div class="col-12 col-lg-6 mb-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">de</span>
                </div>
                <b-form-datepicker
                  id="datepicker-dateformat1"
                  locale="pt-BR"
                  nav-button-variant="white"
                  v-model="filtersTable.start_created"
                  menu-class="w-100"
                  calendar-width="100%"
                  :show-decade-nav="false"
                  :hide-header="true"
                  placeholder="dd/mm/aaaa"
                ></b-form-datepicker>
              </div>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">até</span>
                </div>
                <b-form-datepicker
                  id="datepicker-dateformat2"
                  locale="pt-BR"
                  nav-button-variant="white"
                  v-model="filtersTable.end_created"
                  menu-class="w-100"
                  calendar-width="100%"
                  :show-decade-nav="false"
                  :hide-header="true"
                  placeholder="dd/mm/aaaa"
                ></b-form-datepicker>
              </div>
            </div>
          </div>
        </form>

        <template #modal-footer>
          <b-button @click="filterQuotations()" variant="outline-primary"
            >Filtrar <i class="fa fa-search"></i
          ></b-button>
        </template>
      </b-modal>
    </div>

    <div class="modal">
      <b-modal
        id="show-modal-info-quotation"
        ref="showModalInfoQuotation"
        size="lg"
        class="mt-4"
        hide-footer
        centered
        no-stacking
        @hidden="resetToggleValues"
        >
        <br />
        <template #modal-title> Informações da cotação </template>

        <div class="modal-informacao-erros">
          <b-card-group v-if="exception.length === 0" class="d-flex justify-content-center">
              <h6 class="modal-title">Essa cotação não possui retornos. </h6>
          </b-card-group>
          <b-card-group
            deck
            class="mt-2 mb-3 card-informacao-erros"
            v-for="(item, index) in exception"
            :key="item.id"
          >
            <b-card
              border-variant="primary"
              header-bg-variant="primary"
              header-text-variant="white"
            >
              <template #header>
              <div @click="setToggleModalExceptionValue(index)" class="cursor-pointer">
                {{ setModalExceptionHeader(item.insurer_identifier, index) }}
                <i :class="['mdi', toggle_value[index] ? 'mdi-chevron-down' : 'mdi-chevron-up']"></i>
              </div>
              </template>
              <div v-if="toggle_value[index]">
                <div class="col-12 col-lg-12 mb-3">
                  <label class="dbo-ui" for="">
                    <strong>Seguradora</strong>
                  </label>
                  <p>
                    {{
                      getEnumKeyByEnumValue(
                        translateInsurersTypes,
                        item.insurer_identifier
                      )
                    }}
                  </p>
                </div>

                <hr />
                  <div class="col-12 col-lg-12 mb-3">
                    <label class="dbo-ui" for=""> <strong>Endpoint</strong> </label>
                    <p>{{ jsonParse(item.endpoint) }}</p>
                  </div>

                  <hr />
                  <div class="col-12 col-lg-12 mb-3">
                    <label class="dbo-ui" for=""><strong>Payload</strong></label>
                    <pre>{{ jsonParse(item.payload) }}</pre>
                  </div>

                  <hr />
                  <div class="col-12 col-lg-12 mb-3">
                    <label class="dbo-ui" for=""><strong>Retorno</strong></label>
                    <pre>{{ jsonParse(item.exception) }}</pre>
                  </div>
              </div>
            </b-card>
          </b-card-group>
        </div>
        <hr>
        <div v-if="quotation_status == statusQuotationTypes.ISSUE_FAILURE " class="col-12 col-lg-12 my-3" >
          <button v-b-modal.show-modal-update class="btn btn-primary">Retornar cotação a fila</button>
        </div>
      </b-modal>
    </div>
    <div class="modal">
      <b-modal
        id="show-modal-update"
        ref="showModalUpdate"
        size="md"
        class="mt-4"
        hide-footer
        centered
        no-stack
      >
      <br />
      <template #modal-title>
        Retornar status da cotação
      </template>
      <div class="row mb-3">
          <div class="col-12 col-lg-12">
            <h6>Deseja retornar Cotação para a fila trocando o status dela para aprovada ?</h6>
          </div>
      </div>
        <div class="row">
          <div class="col-12 col-lg-12">
            <button
              @click="$refs.showModalUpdate.hide(), $refs.showModalInfoQuotation.show()"
              class="btn btn-outline-secondary btn-lg text-uppercase px-4 mr-1"
            >
              <i class="fa fa-angle-left mr-2"></i>
              <span class="d-none d-md-inline-block">Voltar</span>
            </button>
            <button
              class="btn btn-primary btn-lg text-uppercase px-4"
              @click="quotationIssueResend"
            >
              Confirmar<i class="fa fa-angle-right ml-2"></i>
            </button>
          </div>
        </div>
      </b-modal>
      </div>
  </div>
</template>

<script>
import quotationService from "@/services/quotation";
import dateFormatter from "@/mixins/dateFormatter";
import numericFormatterMixin from "@/mixins/numericFormatter";
import translateInsuranceTypes from "@/enums/translate/insuranceTypes";
import translateInsurersTypes from "@/enums/translate/insurersTypes";
import translateInsuranceCategoryTypes from "@/enums/translate/insuranceCategoryTypes";
import commomsHelper from "@/mixins/commomsHelper";
import authenticationService from "@/services/authentication";
import statusQuotationTypes from "@/enums/statusQuotationTypes";
import uiHelperMixin from "@/mixins/uiHelper";
import tableHelperMixin from '@/mixins/tableHelper';
import translateStatusQuotationTypes from "@/enums/translate/statusQuotationTypes";
import policyService from "@/services/policy";

export default {
  name: "list-quotation",
  mixins: [
    numericFormatterMixin,
    dateFormatter,
    commomsHelper,
    uiHelperMixin,
    tableHelperMixin,
  ],
  data: () => ({
    fields: [
      {
        key: "number",
        label: "FINN No",
        tdClass: "font-12",
        sortable: true,
        thClass: "table-pretty dbo-ui rounded-top",
      },
      {
        key: "type",
        label: "TIPO DE SEGURO",
        tdClass: "font-12",
        thClass: "table-pretty dbo-ui",
      },
      {
        key: "insurer",
        label: "SEGURADORA",
        tdClass: "font-12 text-uppercase",
        thClass: "table-pretty dbo-ui",
      },
      {
        key: "value_insured",
        label: "IMP. SEGURADA",
        tdClass: "font-12",
        sortable: true,
        thClass: "table-pretty dbo-ui",
      },
      {
        key: "taker",
        label: "TOMADOR",
        tdClass: "column-size-taker font-12",
        thClass: "table-pretty dbo-ui",
      },
      {
        key: "insured",
        label: "SEGURADO",
        tdClass: "column-size-taker font-12",
        thClass: "table-pretty dbo-ui",
      },
      {
        key: "status",
        label: "STATUS",
        tdClass: "font-12",
        thClass: "table-pretty dbo-ui",
      },
      {
        key: "created_at",
        label: "CRIADO",
        tdClass: "font-12",
        sortable: true,
        thClass: "table-pretty dbo-ui",
      },
      {
        key: "action",
        label: "AÇÃO",
        tdClass: "column-size-share font-12",
        thClass: "table-pretty dbo-ui rounded-top",
      },
    ],
    items: [],
    translateInsuranceTypes: translateInsuranceTypes,
    translateInsurersTypes: translateInsurersTypes,
    translateInsuranceCategoryTypes: translateInsuranceCategoryTypes,
    translateStatusQuotationTypes: translateStatusQuotationTypes,
    routeRedirect: "list-quotation",
    title: "Excluir cotação",
    deletion_data: "",
    current_deletion: "",
    loading: false,
    checkPolice: true,
    exception: [],
    statusQuotationTypes: statusQuotationTypes,
    rows: 0,
    idConfirmClone: null,
    msgLoading: 'Exclusão em andamento.',
    idConfirmEmit: null,
    nameTable: 'quotations',
    quotation_id: null,
    quotation_status: null,
    list_insurers: [],
    list_insurance: [],
    toggle_value: {},
  }),
  async created() {
    if (this.$route.query.filter) {
      this.fillFilterForm();
      this.getQuotations();

      return;
    }
    
    this.cleanFilter();
    this.getQuotations();
  },
  computed: {
    allowedJudicialFlow() {
      return this.allowedJudicial();
    },
  },
  methods: {
    isMaster() {
      return authenticationService.isMaster();
    },
    showModalExeption(val) {
      this.loadingQuotations();
      quotationService.getFailedQuote(val.item.action, val.item.insurer)
       .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.exception = response.data;
          this.$refs.showModalInfoQuotation.show();
          this.quotation_id = val.item.action;
          this.quotation_status = val.item.status;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showModalDestroy(val) {
      this.current_deletion = val.value;
      this.deletion_data =
        "Deseja realmente excluir os dados de " + val.item.taker;
      this.$refs.showModal.show();
    },
    jsonParse(str) {
      return this.isJsonString(str) ? JSON.parse(str) : str;
    },
    checkPolicyExist() {
      this.loading = true;
      this.msgLoading = 'Verificando apólice.';
      this.$refs.showModal.hide();
      quotationService
        .getByUID(this.current_deletion)
        .then((response) => {
          if (response.data.policies.length > 0) {
            this.deletion_data =
              "Existe uma apólice de seguro vinculada a esta cotação, deseja realmente excluí-la?";
            this.checkPolice = false;
            this.$refs.showModal.show();
            return;
          }
          this.destroyQuotation();
        })
        .catch(() => {
          this.showError("Não foi possível recuperar as cotações.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    verifyStatusQuotation(status) {
      return (
        status == this.statusQuotationTypes.ISSUED ||
        status == this.statusQuotationTypes.APPROVED ||
        status == this.statusQuotationTypes.ISSUE
      );
    },
    actionAvaiable(status) {
      return status == this.statusQuotationTypes.ISSUE;
    },
    destroyQuotation() {
      this.loading = true;
      this.msgLoading = 'Exclusão em andamento.';
      this.$refs.showModal.hide();
      quotationService
        .destroy(this.current_deletion)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.getQuotations();
        })
        .catch(() => {
          this.showError("Não foi possível remover a cotação.");
        })
        .finally(() => {
          this.checkPolice = true;
          this.loading = false;
        });
    },
    editQuotation(id, status) {
      if (status == this.statusQuotationTypes.ISSUED) {
        return;
      }
      quotationService
        .getByUID(id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          const quotation = response.data;
          const routeName = this.verifyCurrentRouteName(quotation);
          this.redirect(routeName, { quotationId: quotation.id });
          return;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar as cotações.");
        });
    },
    cloneQuotation(id) {
      this.idConfirmClone = id;
      this.$refs['showModalConfirmClone'].show()
    },
    confirmCloneQuotation() {      
      this.loading = true;
      this.msgLoading = 'Cópia de cotação em andamento.';
      this.$refs.showModalConfirmClone.hide();
      quotationService
        .clone(this.idConfirmClone)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.redirect('garantee-data', {
                          quotationId: response.data.id,
                        })
          this.idConfirmClone = null;
        })
        .catch(() => {
          this.showError("Não foi possível clonar a cotação.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    emitQuotation(id) {
      this.idConfirmEmit = id;
      this.$refs['showModalConfirmEmit'].show()
    },
    confirmEmitQuotation() {
      this.loading = true;
      this.msgLoading = 'Emissão da apólice em andamento.';
      this.$refs.showModalConfirmEmit.hide();
      quotationService
        .emitQuotation(this.idConfirmEmit)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.idConfirmEmit = null;
          this.filterQuotations();
        })
        .catch((error) => {
          this.showError("Não foi possível emitir a apólice. " + error.response.data.error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    verifyCurrentRouteName(quotation) {
      if (!quotation.insurance_type_id || !quotation.insurance_category_id) {
        return "garantee-types-selection";
      }
      
      if (!quotation.document || !quotation.name || !quotation.guarantee) {
        return "garantee-data";
      }

      if (!quotation.insured || !quotation.insured.address) {
        return "insured-data";
      }

      if (
        !quotation.guarantee.contract_number ||
        !quotation.guarantee.guarantee_object ||
        !quotation.guarantee.contract_value
      ) {
        return "quotation-details";
      }

      if (!quotation.guarantee.insurer_selected) {
        return "quotation-summary";
      }
    },
    getQuotations() {
      this.resetAlert();
      this.loadingQuotations();
      quotationService
        .get(this.getFilterForm())
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.rows = response.data.total;
          this.formatList(response.data);
          this.loading = false;
          this.getInsurers();
          this.getInsuranceCategories();
        })
        .catch(() => {
          this.showError("Não foi possível recuperar as cotações.");
        }).finally(() => {
          this.loading = false;
          this.msgLoading = 'Exclusão em andamento.';
        });
    },
    getEnumKeyByEnumValue(myEnum, enumValue) {
      let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
      return keys.length > 0 ? keys[0] : null;
    },
    formatList(data) {
      this.items = data.data.map((element) => ({
        number: element.number,
        type: this.getEnumKeyByEnumValue(
          this.translateInsuranceCategoryTypes,
          element.insurance_category_id
        ),
        value_insured: element.guarantee
          ? this.allowedJudicialFlow.indexOf(element.insurance_category_id) !=
            -1
            ? this.formatCurrency(element.guarantee.contract_value)
            : this.formatCurrency(element.guarantee.guarantee_value)
          : "R$ 0,00",
        taker: element.name,
        status: element.status,
        insured: element.insured ? element.insured.name : null,
        created_at: this.formatDateTimeLocal(element.created_at),
        action: element.id,
        failed_quote: element.failed_quote,
        insurer: element.guarantee ? element.guarantee.insurer_selected : null,
        policy_id: element.policy_id,
      }));
    },
    redirect(routeName, params = {}) {
      this.$router.push({ name: routeName, params: params });
    },
    filterQuotations() {
      this.setFilterForm();
      this.getQuotations();
      this.$refs.modalFilter.hide();
    },
    cleanFilter() {
      this.filtersTable = {
        policy_holder: '',
        process: '',
        insured: '',
        status: null,
        start_created: '',
        end_created: '',
        document: '',
        insurer: '',
        insurance: '',
      };
      this.setFilterForm();
      this.getQuotations();
    },
    sortingTableChanged(e) {
      this.sortBy = e.sortBy;
      this.sortDesc = e.sortDesc;
      this.setFilterForm();
      this.getQuotations();
    },
    pageChanged(bvEvent, pageNumber) {
      this.currentPage = pageNumber;
      this.setFilterForm();
      this.getQuotations();
    },
     loadingQuotations(){
        this.msgLoading = '';
        this.loading = true;
    },
    options() {
      let options = [];
      let status = Object.entries(this.translateStatusQuotationTypes);
      status.forEach((element) => {
        options.push({ value: element[1], text: element[0] });
      });
      options.sort(function (a, b) {
        return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
      });
      options.splice(0, 0, {
        value: null,
        text: "Sem Filtro",
        notEnabled: true,
        disabled: true,
      });
      return options;
    },
    quotationIssueResend(){
      this.msgLoading = '';
      this.loading = true;
      quotationService
        .quotationIssueResend(this.quotation_id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
        })
        .catch(() => {
          this.showError("Não foi possível alterar status da cotação.");
        }).finally(() => {
          this.$bvModal.hide('show-modal-update');
          this.getQuotations();
          this.loading = false;
          this.msgLoading = 'Exclusão em andamento.';
        });
    },
    getInsurers() {
      policyService
        .getInsurers()
        .then((response) => {
          this.list_insurers = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar a lista de seguradoras.");
        });
    },
    getInsuranceCategories() {
      policyService
        .getAllInsuranceCategories()
        .then((response) => {
          this.list_insurance = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar a lista de modalidades.");
        });
    },
    setToggleModalExceptionValue(index) {
      if (this.toggle_value[index] === undefined) {
        return this.$set(this.toggle_value, index, true);
      }
        return this.$set(this.toggle_value, index, !this.toggle_value[index]);
    },
    setModalExceptionHeader(insurer_identifier, index){
      var message = `Request n° ${index} `;
      if (insurer_identifier != null) {
        return message + ' - ' + this.getEnumKeyByEnumValue(this.translateInsurersTypes, insurer_identifier);
      }
        return message;
    },
    resetToggleValues(){
      this.$set(this, 'toggle_value', []);
    }
  },
};
</script>

<style>
.modal-confirmation { max-width:360px; }
</style>
