<template>
  <div class="row">
    <div class="col-12 col-lg-12 mb-3">
      <table class="pretty admin mb-3 shadow">
        <thead>
          <tr>
            <th>Cnpj</th>
            <th>Nome</th>
            <th>Ação</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="company in companies" :key="company.cnpj">
            <td>{{formatCnpj(company.cnpj)}}</td>
            <td>{{company.company_name}}</td>
            <td>
              <button @click="$parent.dettachCompany(company)" type="button" class="button btn btn-primary btn-sm">
                <i class="fa fa-trash"></i> Remover </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import commomsHelper from '@/mixins/commomsHelper';

export default {
  name: "CompanyData",
  mixins: [
    commomsHelper
  ],
  props: {
    companies: {
      type: Array,
      required: false
    },
  },
};
</script>

<style>
</style>
