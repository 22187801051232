<template>
  <div class="page-container">
    <div class="page-content">
      <div class="page-header">
        <div class="d-flex align-items-center pb-3">
          <h1 class="text-white">
            Dashboard
          </h1>
        </div>
      </div>

      <div v-if="alertMessage" class="col-12">
        <div class="alert-custom alert-danger-custom">
          <ul class="mt-2">
            <li v-for="er in error" :key="er">{{ er }}</li>
          </ul>
        </div>
      </div>

      <div class="page-body">
        <div
          v-if="filtering"
          class="overlay d-flex align-items-center justify-content-center"
        >
          <b-progress
            variant="#6d1463"
            :max="max"
            height="2.5rem"
            show-progress
            animated
          >
            <b-progress-bar :value="value">
              <strong
                ><span class="uppercase font-14"
                  >Aplicando filtros: {{ value.toFixed(0) }}%</span
                ></strong
              >
            </b-progress-bar>
          </b-progress>
        </div>

        <div class="row">
          <div class="col-12 col-lg-12 mb-3">
            <div class="card">
              <div class="card-body py-0">
                <div class="row text-center">
                  <div
                    class="
                      col-12 col-lg-4
                      border-bottom
                      no-border-bottom-lg
                      border-right-lg
                      p-3
                    "
                  >
                    <h5>Cotações pendentes</h5>
                    <div class="font-28">
                      <strong v-if="verifyReturn(collection.dashboard)">{{
                        collection.dashboard.quotations_count
                      }}</strong>
                      <b-spinner v-else></b-spinner>
                    </div>
                  </div>
                  <div
                    class="
                      col-12 col-lg-4
                      border-bottom
                      no-border-bottom-lg
                      border-right-lg
                      p-3
                    "
                  >
                    <h5>Apólices vigentes</h5>
                    <div class="font-28">
                      <strong v-if="verifyReturn(collection.dashboard)">{{
                        collection.dashboard.policies_count
                      }}</strong>
                      <b-spinner v-else></b-spinner>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 p-3">
                    <h5>Prêmios emitidos</h5>
                    <div class="font-28">
                      <strong v-if="verifyReturn(collection.dashboard)">{{
                        formatCurrency(collection.dashboard.insurance_premiums_issued)
                      }}</strong>
                      <b-spinner v-else></b-spinner>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-12 mb-3">
            <div class="card">
              <div class="card-header border-bottom">
                <h5 class="mb-0 text-center">Próximos vencimentos</h5>
              </div>
              <div
                v-if="collection.dashboard"
                class="card-body p-0"
              >
                <div class="table-responsive border-radius-bottom">
                  <b-table
                    class="table pretty mb-0"
                    :items="collection.dashboard.upcoming_policy_expirations"
                    :fields="fields"
                  >
                    <template #cell(deadline)="data">
                      <span v-html="addHtmlClass(data)"></span>
                    </template>

                    <template #cell(coverage_value)="data">
                      <span>{{ formatCurrency(data.value) }}</span>
                    </template>

                    <template #cell(insurance_premium_paid)="data">
                      <span>{{ formatCurrency(data.value) }}</span>
                    </template>
                  <template #cell(action)="data">
                    <span class="mt-1">
                      <i
                        @click="
                          redirect('details-policy', { policyId: data.item.id })
                        "
                        v-b-tooltip.hover
                        title="Visualizar"
                        class="fas fa-eye mx-1 icon-btn"
                      ></i>
                    </span>

                    <span
                      v-if="$can.any(['quotation-store']) && data.item.quotation_id"
                      @click="cloneQuotation(data.item.quotation_id)"
                      class="text-secondary"
                      v-b-tooltip.hover
                      title="Renovar apólice"
                    >
                      <i class="far fa-copy mx-1 icon-btn"></i>
                    </span>
                  </template>
                  </b-table>
                </div>
              </div>
              <div
                v-else
                class="
                  font-28
                  mt-5
                  mb-5
                  d-flex
                  align-items-center
                  justify-content-center
                "
              >
                <b-spinner></b-spinner>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-3 pr-3">
          <button
            type="button"
            class="button btn btn-outline-white ml-auto"
            v-b-toggle.collapse-1
          >
            <span class="d-none d-md-inline-block mr-2">Filtros</span>
            <i class="fa fa-angle-up status-icon"></i>
          </button>
        </div>

        <div class="mb-1 pr-3">
          <b-collapse id="collapse-1" class="w-100">
            <div id="filtros" class="row">
              <div class="col-12 col-lg-4 mb-3">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">de</span>
                  </div>
                  <b-form-datepicker
                    id="datepicker-dateformat1"
                    :date-format-options="optionsFormatDate"
                    locale="pt-BR"
                    :min="range.min"
                    :max="max_date"
                    nav-button-variant="white"
                    menu-class="w-100"
                    calendar-width="100%"
                    v-model="min_date"
                    :show-decade-nav="false"
                    :hide-header="true"
                    placeholder="dd/mm/aaaa"
                  ></b-form-datepicker>
                </div>
              </div>
              <div class="col-12 col-lg-4 mb-3">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">até</span>
                  </div>
                  <b-form-datepicker
                    id="datepicker-dateformat2"
                    :date-format-options="optionsFormatDate"
                    locale="pt-BR"
                    :min="min_date"
                    :max="range.max"
                    nav-button-variant="white"
                    menu-class="w-100"
                    calendar-width="100%"
                    :show-decade-nav="false"
                    :hide-header="true"
                    v-model="max_date"
                    placeholder="dd/mm/aaaa"
                  ></b-form-datepicker>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>

        <div class="row">
          <div class="col-12 col-lg-12 mb-3">
            <div class="card">
              <div class="card-body py-0">
                <div class="row text-center">
                  <div
                    class="
                      col-12 col-lg-6
                      border-bottom
                      no-border-bottom-lg
                      border-right-lg
                      p-3
                    "
                  >
                    <h5>Apólices emitidas</h5>
                    <div class="font-28">
                      <strong v-if="verifyReturn(collection.dashboard)">{{
                        collection.dashboard.policies_issued
                      }}</strong>
                      <b-spinner v-else></b-spinner>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6 p-3">
                    <h5>Prêmios emitidos</h5>
                    <div class="font-28">
                      <strong
                        v-if="verifyReturn(collection.dashboard)"
                        >{{
                          formatCurrency(
                            collection.dashboard.insurance_premiums_issued_filtered
                          )
                        }}</strong
                      >
                      <b-spinner v-else></b-spinner>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-12 mb-3">
            <div class="card">
              <div class="card-header border-bottom">
                <h5 class="mb-0 text-center uppercase">
                  Total de prêmios por mês
                </h5>
              </div>
              <div class="card-body">
                <BarChart
                  v-if="collection.dashboard"
                  :settings="collection.dashboard.monthly_insurance_premiums"
                />
                <div
                  v-else
                  class="
                    font-28
                    mt-5
                    mb-5
                    d-flex
                    align-items-center
                    justify-content-center
                  "
                >
                  <b-spinner></b-spinner>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-3">
            <div class="card">
              <div class="card-header border-bottom">
                <h5 class="mb-0 text-center uppercase">
                  Prêmios por tipo de seguro
                </h5>
              </div>
              <div class="card-body">
                <DonutChart
                  v-if="collection.dashboard"
                  :settings="collection.dashboard.insurance_premiums_by_insurer"
                />
                <div
                  v-else
                  class="
                    font-28
                    mt-5
                    mb-5
                    d-flex
                    align-items-center
                    justify-content-center
                  "
                >
                  <b-spinner></b-spinner>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-3">
            <div class="card">
              <div class="card-header border-bottom">
                <h5 class="mb-0 text-center uppercase">
                  Prêmios por seguradora
                </h5>
              </div>
              <div class="card-body">
                <DonutChart
                  v-if="collection.dashboard"
                  :settings="collection.dashboard.insurance_premiums_by_type"
                />
                <div
                  v-else
                  class="
                    font-28
                    mt-5
                    mb-5
                    d-flex
                    align-items-center
                    justify-content-center
                  "
                >
                  <b-spinner></b-spinner>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-3">
            <div class="card">
              <div class="card-header border-bottom">
                <h5 class="mb-0 text-center uppercase">
                  Importância segurada: Seguro garantia
                </h5>
              </div>
              <div class="card-body">
                <DonutChart
                  v-if="collection.dashboard"
                  :settings="collection.dashboard.insured_amount"
                />
                <div
                  v-else
                  class="
                    font-28
                    mt-5
                    mb-5
                    d-flex
                    align-items-center
                    justify-content-center
                  "
                >
                  <b-spinner></b-spinner>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-3">
            <div class="card">
              <div class="card-header border-bottom">
                <h5 class="mb-0 text-center uppercase">
                  Prêmios: Seguro garantia
                </h5>
              </div>
              <div class="card-body">
                <DonutChart
                  v-if="collection.dashboard"
                  :settings="collection.dashboard.guarantee_insurance_premiums"
                />
                <div
                  v-else
                  class="
                    font-28
                    mt-5
                    mb-5
                    d-flex
                    align-items-center
                    justify-content-center
                  "
                >
                  <b-spinner></b-spinner>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-6 mb-3">
            <div class="card">
              <div class="card-header border-bottom">
                <h5 class="mb-0 text-center uppercase">
                  Prêmios por Tipo de Recurso
                </h5>
              </div>
              <div class="card-body">
                <DonutChart
                  v-if="collection.dashboard"
                  :settings="collection.dashboard.appeal_guarantee_summary"
                />
                <div
                  v-else
                  class="
                    font-28
                    mt-5
                    mb-5
                    d-flex
                    align-items-center
                    justify-content-center
                  "
                >
                  <b-spinner></b-spinner>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="modal">
      <b-modal
        id="show-modal-renew-quotation"
        ref="showModalRenewQuotation"
        hide-footer
        centered>
        <template #modal-title>Confirmação</template>

        <div class="card">
          <p>Deseja renovar esta apólice?</p>
        </div>
        <div class="row">
          <div class="col-12 col-lg-12">
            <button
              @click="$bvModal.hide('show-modal-renew-quotation')"
              class="btn btn-outline-secondary btn-lg text-uppercase px-4 mr-1"
            >
              <i class="fa fa-angle-left mr-2"></i
              ><span class="d-none d-md-inline-block"> Cancelar</span>
            </button>
            <button
              @click="confirmCloneQuotation()"
              class="btn btn-primary btn-lg text-uppercase px-4"
            >
              Confirmar<i class="fa fa-angle-right ml-2"></i>
            </button>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { BarChart, DonutChart } from "@/components/Charts";
import assembleAnalytics from "@/mixins/assembleAnalytics";
import numericFormatter from "@/mixins/numericFormatter";
import dateFormatter from "@/mixins/dateFormatter";
import quotationService from "@/services/quotation";

export default {
  name: "Dashboard",
  components: {
    BarChart,
    DonutChart,
  },
  mixins: [assembleAnalytics, numericFormatter, dateFormatter],
  data() {
    return {
      min_date: null,
      max_date: null,
      fields: [
        {
          key: "insurance_type_name",
          label: "Tipo de seguro",
          class: "text-center column-size-insurance-type font-12",
        },
        {
          key: "coverage_value",
          label: "Valor da cobertura",
          class: "text-center column-size-coverage-value font-12",
        },
        {
          key: "insurance_premium_paid",
          label: "Prêmio Pago",
          class: "text-center column-size-insurance-premium font-12",
        },
        {
          key: "policy_holder_name",
          label: "Tomador",
          class: "text-center font-12",
        },
        {
          key: "insured_name",
          label: "Segurado",
          class: "text-center font-12",
        },
        {
          key: "insurer_selected",
          label: "Seguradora",
          class: "text-center font-12",
        },
        { key: "deadline",
          label: "Prazo p/ vencimento",
          class: "text-center font-12"
        },
        { key: "action",
          label: "Ação",
          class: "text-center column-size-actions font-12" }
      ],
      collection: {
        dashboard: null
      },
      idConfirmClone: null,
    };
  },
  created() {
    this.mountDashboard();
  },
  methods: {
    cloneQuotation(id) {
      this.idConfirmClone = id;
      this.$refs['showModalRenewQuotation'].show()
    },
    redirect(routeName, params = null) {
      this.$router.push({ name: routeName, params: params });
    },
    addHtmlClass(data) {
      switch (true) {
        case data.value < 0:
          return '<div class="badge badge-dark d-block text-uppercase p-2">vencido</div>';

        case data.value == 1:
          return '<div class="badge badge-danger d-block text-uppercase p-2">'+ data.value +' dia</div>';

        case data.value > 1 && data.value < 30:
          return '<div class="badge badge-danger d-block text-uppercase p-2">'+ data.value +' dias</div>';

        case data.value >= 30 && data.value < 60:
          return (
            '<div class="badge badge-warning d-block text-uppercase p-2">' +
            data.value +
            " dias</div>"
          );
        case data.value >= 60:
          return (
            '<div class="badge badge-success d-block text-uppercase p-2">' +
            data.value +
            " dias</div>"
          );
        case data.value == 0:
          return '<div class="badge badge-danger d-block text-uppercase p-2">Vence hoje</div>';
      }
    },
    verifyReturn(value){
      return value != null
    },
    confirmCloneQuotation() {      
      this.loading = true;
      this.msgLoading = 'Cópia de cotação em andamento.';
      this.$refs.showModalRenewQuotation.hide();
      quotationService
        .clone(this.idConfirmClone)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.redirect('garantee-data', {
                          quotationId: response.data.id,
                        })
          this.idConfirmClone = null;
        })
        .catch(() => {
          this.showError("Não foi possível clonar a cotação.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>
