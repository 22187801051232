<template>
  <div class="page-container">
    <div class="page-content">
      <div class="page-header">
        <div class="d-flex align-items-center mb-3">
          <h1 class="text-white">
            <i class="mdi mdi-folder-open"></i> Reportar Sinistro
          </h1>
          <span
            @click="back()"
            class="btn btn-outline-white ml-auto"
            ><i class="fa fa-arrow-left mr-1"></i>
            <span class="d-none d-md-inline">Voltar</span></span
          >
        </div>
      </div>

      <div v-if="alertMessage" class="col-12">
        <b-alert show :variant="alertVariant">{{ alertMessage }}</b-alert>
      </div>

      <div class="page-body">
        <div class="card">
          <div class="card-body p-3 p-lg-5">
            <validation-observer
              ref="observer"
              v-slot="{ handleSubmit, invalid }"
            >
              <b-form @submit.stop.prevent="handleSubmit(reportClaim)">
                <div id="form-cadastrar" class="row form">
                  <div class="col-12 mb-3">
                    <h6 class="form-subheader"><span>Detalhes</span></h6>
                  </div>
                  <div class="col-md-12 mb-3">
                    <validation-provider
                      name="descrição"
                      rules="required"
                      v-slot="validationContext"
                      vid="description"
                    >
                      <label class="dbo-ui" for="">Descreva detalhadamente o que ocorreu</label>
                      <b-form-textarea
                        v-model="dataForm.description"
                        :state="getValidationState(validationContext)"
                        class="py-4 col-md-6"
                      ></b-form-textarea>
                      <b-form-invalid-feedback>{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                    </validation-provider>
                  </div>
     
                  <div class="col-12 mb-3">
                    <upload-data ref="upload"
                    :files="files"
                    :url="urlUpload"
                    :forceRedirect="true"
                    :title="'Adicione os documentos relacionados ao sinistro'"
                    @upload-finished="redirect(routeRedirect)"/>
                  </div>
                  <div class="col-12 text-right">
                    <button :disabled="invalid" class="button btn btn-primary">
                      <b-spinner v-if="loading" small></b-spinner>
                      <i v-else class="fa fa-check mr-1"></i> Reportar
                    </button>
                  </div>
                </div>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { UploadData } from '@/components';
import policyService from "@/services/policy";

export default {
  name: "create-claim",
  components: {
    ValidationObserver,
    ValidationProvider,
    UploadData
  },
  props: {
    policyId: {
      type: String,
      required: true
    },
  },
  data: () => ({
    dataForm: {
      policy_id: null,
    },
    files: [],
    urlUpload: '',
    routeRedirect: 'list-policies',
    loading: false,
    validate: false,
  }),
  methods: {
    reportClaim() {
      this.resetAlert();
      this.dataForm.policy_id = this.policyId
      policyService
        .storeClaim(this.dataForm)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.urlUpload = `/api/v1/claims/${response.data.id}/files`
          this.$nextTick(() => {
            if(this.$refs.upload.$refs.dropzone.getAcceptedFiles().length > 0) {
              this.$refs.upload.$refs.dropzone.processQueue();
              return;
            }
            this.redirect(this.routeRedirect);
          });
        })
        .catch(() => {
          this.showError("Não foi possível realizar o reporte do sinistro.");
        });
    },
    redirect(routeName) {
      this.$router.push({ name: routeName });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    back() {
      this.$router.push({
        name: this.routeRedirect,
        query: { filter: true},
      });
    },
  },
};
</script>

<style>
</style>
