<template>
  <div class="page-container">
    <div class="page-content">
      <div class="page-header">
        <div class="row space-around mb-3 d-flex">
          <div class="col-3">
            <h1 class="text-white">
              <i class="mdi mdi-handshake"></i>Tomadores
            </h1>
          </div>

          <div class="col-9 d-flex justify-content-end">
            <div>
              <button
                @click="redirect('client-store')"
                class="ml-auto btn btn-outline-white mr-2"
              >
                <i class="fa fa-plus-circle"></i>
                <span class="d-none d-md-inline"> Novo Tomador</span>
              </button>
              <a
                @click="$refs.showModalFilter.show()"
                class="ml-auto btn btn-outline-white mr-2"
              >
                <i class="fa fa-filter mr-2"></i>
                <span class="d-none d-md-inline">Filtros</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div v-if="alertMessage" class="col-12">
        <div class="alert-custom alert-danger-custom">{{ alertMessage }}</div>
      </div>
      <div
        v-if="loading"
        class="overlay d-flex align-items-center justify-content-center"
      >
        <div class="d-flex justify-content-center mb-2">
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>

      <div class="page-body">
        <div class="card">
          <div class="card-body p-0">
            <div class="table-responsive shadow">
              <b-table
                class="table pretty-title-gray mb-0"
                :items="items"
                :fields="fields"
                :filter="filter"
                :per-page="perPage"
                :current-page="currentPage"
              >
                <template #cell(available_limit)="data">
                  <span>{{ formatCurrency(data.value) }}</span>
                </template>
                <template #cell(last_issue.created_at)="data">
                  <span v-if="data.value">{{
                    formatDateLocal(data.value)
                  }}</span>
                  <span v-else> - - - - - </span>
                </template>
                <template #cell(actions)="data">
                  <div class="d-flex justify-content-center align-items-center">
                    <a
                      @click="
                        redirect('show-client', {
                          economicGroupID: data.item.id,
                        })
                      "
                      class="text-secondary"
                    >
                      <i class="fas fa-eye mx-1 icon-btn"></i>
                    </a>
                    <a
                      @click="
                        redirect('update-client', {
                          economicGroupID: data.item.id,
                        })
                      "
                      class="text-secondary"
                    >
                      <i class="fas fa-pen mx-1 icon-btn"></i>
                    </a>
                    <a
                      @click="showModalDelete(data.item)"
                      class="text-secondary"
                    >
                      <i class="fas fa-times mx-1 icon-btn"></i>
                    </a>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="modal">
              <b-modal
                id="show-modal-delete"
                ref="showModalDelete"
                class="mt-4"
                size="sm"
                centered
                hide-footer
              >
                <br />
                <template #modal-title>
                  Deseja deletar esse grupo ecônomico ?
                </template>
                <div class="mb-5 pb-2">
                  <h6 class="dbo-ui">
                    Deseja deletar o grupo economico
                    <p>
                      "
                      {{
                        economic_group_data
                          ? economic_group_data.group_name
                          : ""
                      }}
                      "
                    </p>
                    detentor do cnpj
                    <p>
                      {{
                        economic_group_data
                          ? economic_group_data.cnpj
                          : ""
                      }}
                    </p>
                  </h6>
                </div>
                <div>
                  <a
                    @click="$bvModal.hide('show-modal-delete')"
                    class="btn btn-outline-secondary"
                    ><i class="fa fa-angle-left"></i> Voltar</a
                  >
                  <a
                    @click="deleteEconomicGroup"
                    class="btn btn-md btn-danger ml-2"
                    ><i class="fas fa-times"></i> Confirmar</a
                  >
                </div>
              </b-modal>
            </div>
            <nav aria-label="Paginação" class="m-3">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div class="modal">
      <b-modal
        id="show-modal-filter"
        ref="showModalFilter"
        size="md"
        class="mt-4"
        hide-footer
      >
        <br />
        <template #modal-title>
          <h5 class="modal-title">Filtrar Tomador</h5>
        </template>
        <div>
          <label class="dbo-ui" for="name">Digite o nome do grupo</label>
          <b-input v-model="filter" placeholder="Nome do grupo"></b-input>

          <button
            class="btn btn-primary my-2 col-md-12"
            @click="$refs.showModalFilter.hide()"
          >
            Buscar
          </button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import companyService from "@/services/company";
import dateFormatter from "@/mixins/dateFormatter";

export default {
  name: "list-users",
  mixins: [dateFormatter],
  data: () => ({
    perPage: 10,
    currentPage: 1,
    items: [],
    filter: null,
    economic_group_data: null,
    loading: false,
  }),
  created() {
    this.getEconomicGroups();
  },
  computed: {
    rows() {
      return this.items.length;
    },
    fields() {
      var columns = [
        {
          key: "group_name",
          label: "Nome do Grupo",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui",
        },
        {
          key: "cnpj",
          label: "CNPJ",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui",
        },
              {
          key: "company_name",
          label: "Razão Social",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui",
        },
        {
          key: "policies_quantity",
          label: "Quantidade de apolices emitidas",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui",
        },
        {
          key: "last_issue.created_at",
          label: "ultima emissão",
          tdClass: "font-14",
          thClass: "table-pretty dbo-ui",
        },
        {
          key: "actions",
          label: "AÇÃO",
          tdClass: "font-14 ",
          thClass:
            "table-pretty dbo-ui d-flex justify-content-center align-items-center rounded-top",
        },
      ];
      return columns;
    },
  },
  methods: {
    getEconomicGroups(groupName = null) {
      this.loading = true;
      companyService
        .getEconomicGroups(groupName)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.items = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar os grupos economicos.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    redirect(routeName, params = null) {
      this.$router.push({ name: routeName, params: params });
    },
    showModalDelete(economic_group_data) {
      this.economic_group_data = economic_group_data;
      this.$refs.showModalDelete.show();
    },
    deleteEconomicGroup() {
      this.loading = true;
      this.$refs.showModalDelete.hide();
      companyService
        .deleteEconomicGroup(this.economic_group_data.id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
        })
        .catch(() => {
          this.showError("Não foi possível apagar os grupos economicos.");
        })
        .finally(() => {
          this.getEconomicGroups();
        });
    },
  },
};
</script>

<style>
</style>
