export default {
  'Balanço 2020': "BALANCE-2020",
  'Balanço 2021': 'BALANCE-2021',
  'Balanço 2022': 'BALANCE-2022',
  'Balanço 2023': 'BALANCE-2023',
  'Balanço 2024': 'BALANCE-2024',
  "Documentação Societária": "CORPORATE-DOCUMENTATION",
  "Nomeação": "APPOINTMENT",
  "Outros": "OTHERS",
}
