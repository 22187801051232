<template>
  <div class="row">
    <div class="col-12 mb-3">
      <h5>
        <div class="form-subheader"><span>Documentos</span></div>
      </h5>
    </div>

    <div class="col-12 col-lg-12 mb-3">
      <table class="pretty admin mb-3 shadow">
        <thead>
          <tr>
            <th>Nome do documento</th>
            <th>Ação</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="file in endorsement.endorsement_files" :key="file.id">
            <td>{{ file.name }}</td>
            <td>
              <button @click="downloadFile(file)" type="button" class="button btn btn-primary btn-sm">
                <i class="fa fa-download"></i> Download
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import commomsHelper from "@/mixins/commomsHelper";
import policyService from "@/services/policy";

export default {
  props: {
    endorsement: {
      type: Object,
      required: true,
    },
  },
  mixins: [commomsHelper],
  name: "DocumentsData",
  data: () => ({}),
  methods: {
    downloadFile(file) {
      policyService
        .getEndorsementFile(this.endorsement.id, file.id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.openLinkTargetBlank(response.data.url);
        })
        .catch(() => {
          this.showError("Não foi possível realizar o download deste arquivo.");
        });
    },
  },
};
</script>

<style></style>
