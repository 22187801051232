<template>
<div class="page-container">
	<div class="page-content">

    <div v-if="loading" class="overlay d-flex align-items-center justify-content-center">
      <div class="d-flex justify-content-center mb-2">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </div>

      <div class="page-header d-flex justify-content-between align-items-center">
        <h4 class="text-white">Visualizar Cotação</h4>
        <div class="ml-auto pb-3">
          <b-dropdown variant="outline-white" right toggle-class="btn btn-outline-white" no-caret>
            <template #button-content>
              <i class="fas mr-1 fa-cog"></i> 
              <span class="d-none d-md-inline">Ações <span class="d-none d-lg-inline">da Cotação</span></span>
            </template>
            <b-dropdown-item 
              v-if="isMaster()" 
              @click="$bvModal.show('show-modal-status-change')"><i class="fas fa-fw mr-1 fa-edit"></i> Alterar Status/Seguradora</b-dropdown-item>
            <b-dropdown-item @click="exportQuotation()"><i class="fas fa-fw mr-1 fa-file-export"></i> Exportar Cotação</b-dropdown-item>
            <b-dropdown-item @click="$bvModal.show('show-modal-prizes-list')"><i class="fas fa-fw mr-1 fa-trophy"></i> Ver Prêmios</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

    <div v-if="alertMessage" class="col-12">
      <div class="alert-custom alert-danger-custom">{{ alertMessage }}</div>
    </div>

		<div class="page-body" v-if="quotation">
			<div class="card form">
				<div class="card-body">

          <div v-if="quotation.insurance_type_id == 1">
            <general-data :quotation="quotation" @getQuotation="getQuotation"/>
            <insurance-data :quotation="quotation"/>
            <contract-data :quotation="quotation"/>
          </div>
          <div>
            <quotation-message-table 
              @setMessageData="setMessageData" 
              ref="QuotationMessageTable"
            />
            <message-modal 
              :quotation_message_data="quotation_message_data" 
              @setMessageData="setMessageData" 
              @showTableData="showTableData"
              @showLoading="showLoading"
              ref="MessageModal"
            />
          </div>
          <div v-if="quotation.insurance_type_id == 2">
            <rental-guarantee :quotation="quotation"/>
            <partner v-if="quotation.partner.lengh" :quotation="quotation"/>
            <coverage-guarantee :quotation="quotation"/>
            <contract-term :quotation="quotation"/>
          </div>

          <documents-data :quotation="quotation" @deleteFiles="deleteFiles"/>

					<div class="row">
						<div class="col-12 col-lg-12">
							<a @click="back()" class="btn btn-outline-secondary"><i class="fa fa-angle-left"></i> Voltar</a>
						</div>
					</div>

				</div>
			</div>
		</div>

	</div>
</div>

</template>

<script>
import { GeneralData, ContractData, InsuranceData, DocumentsData, QuotationMessageTable } from '@/components/Quotation';
import { RentalGuarantee, Partner, CoverageGuarantee, ContractTerm, MessageModal } from '@/components/Quotation';
import quotationService from "@/services/quotation";
import authenticationService from "@/services/authentication";
import commomsHelper from "@/mixins/commomsHelper";
import policyService from "@/services/policy";
import fileDownload from 'js-file-download';

export default {
  name: "details-quotation",
  props: {
    quotationId: {
      type: String,
      required: true
    }
  },
  components: {
    GeneralData,
    ContractData,
    InsuranceData,
    DocumentsData,
    RentalGuarantee,
    Partner,
    CoverageGuarantee,
    ContractTerm,
    QuotationMessageTable,
    MessageModal
    
  },
  mixins: [commomsHelper],
  data: () => ({
    quotation: null,
    files: [],
    quotation_message_data: {},
    loading: false,
  }),
  mounted() {
    this.getQuotation(this.quotationId)
  },
  methods:{
    getQuotation(uid) {
      this.resetAlert();
      quotationService.getByUID(uid).then(response => {
        if (response.data.error) {
          this.showError(response.data.message);
          return
        }
        this.quotation = response.data
      }).catch(() => {
        this.showError('Não foi possível recuperar a cotação.');
      })
    },
    back() {
      this.$router.push({
        name: "list-quotation",
        query: { filter: true},
      });
    },
    downloadFile(file) {
      quotationService.downloadFile(this.quotation.id, file.id).then(response => {
        if (response.data.error) {
          this.showError(response.data.message);
          return
        }
        fileDownload(response.data, file.name);
      }).catch(() => {
        this.showError('Não foi possível realizar o download deste arquivo.');
      })
    },
    deleteFiles(file){
      if (file.insurer_quotation_id) {
        return this.deleteQuotationFile(file);
      }
      return this.deletePolicyFile(file);
    },
    deleteQuotationFile(file){
         quotationService.
          deleteQuotationFile(file.insurer_quotation_id)
          .then((response) => {
            if (response.data.error) {
              this.showError(response.data.message);
              return ;
            }
            this.$router.go(0);
          })
    },
    deletePolicyFile(file){
     policyService
        .deletePolicyFile(file.policy_id, file.id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return ;
          }
          this.$router.go(0);
        })
        .catch(() => {
          this.showError("Não foi possível apagar arquivo da cotação");
        })
    },
    setMessageData(message_data) {
      this.quotation_message_data = message_data; 
    },
    showTableData(){
      this.$refs.QuotationMessageTable.listMessages();
    },
    showLoading(loading){
      this.loading = loading;
    },
    exportQuotation(){
      this.loading = true;
      quotationService.
        generateReport(this.getIdFromUrl())
          .then((response) => {
            if (response.data.error) {
              this.showError(response.data.message);
              return ;
            }
            this.openLinkTargetBlank(response.data.pdf_url);
          })
          .catch(() => {
          this.showError("Não foi possível exportar cotação.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    isMaster() {
      return authenticationService.isMaster();
    },

  }
};
</script>

<style>
</style>
