<template>
  <div class="row">
    <div class="col-12 col-lg-7">
      <div class="row">
        <div class="col-12 mb-3">
          <h5 class="form-subheader"><span>Dados do seguro</span></h5>
        </div>
        <div class="col-12 col-lg-4 mb-3">
          <label class="dbo-ui" for="">Iníco da vigência</label>
          <p>{{formatDateLocal(policy.validity_start)}}</p>
        </div>

        <div class="col-12 col-lg-4 mb-3">
          <label class="dbo-ui" for="">Período da cobertura (em dias)</label>
          <p>{{calculateQuantityDays(policy.validity_start, policy.validity_expiration)}} </p>
        </div>

        <div class="col-12 col-lg-4 mb-3">
          <label class="dbo-ui" for="">Término da vigência</label>
          <p>{{formatDateLocal(policy.validity_expiration)}}</p>
        </div>
        <div class="col-12 col-lg-4 mb-3" v-if="this.policy.quotation">
          <label class="dbo-ui" for="">Vara</label>
          <p>{{ this.policy.quotation.guarantee.stick }}</p>
        </div>

        <div class="col-12 col-lg-4 mb-3" v-if="this.policy.quotation">
          <label class="dbo-ui" for="">Estado da vara</label>
          <p>{{ this.policy.quotation.guarantee.state }}</p>
        </div>

        <div class="col-12 col-lg-4 mb-3" v-if="this.policy.quotation">
          <label class="dbo-ui" for="">Cidade da vara</label>
          <p>{{ this.policy.quotation.guarantee.city }}</p>
        </div>
      </div>
    </div>
  <div class="col-12 col-lg-5">
    <div class="row">
      <div class="col-12 mb-3">
        <h5 class="form-subheader"><span>Dados do segurado/beneficiário</span></h5>
      </div>

      <div class="col-12 col-lg-6 mb-3">
        <label class="dbo-ui" for="">CPF/CNPJ</label>
        <p>{{policy.insured_document}}</p>
      </div>

      <div class="col-12 col-lg-6 mb-3">
        <label class="dbo-ui" for="">Nome/Razão Social</label>
        <p>{{policy.insured_name}}</p>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import dateFormatter from '@/mixins/dateFormatter';

export default {
  name: "InsuranceData",
  mixins: [
    dateFormatter,
  ],
  props: {
    policy: {
      type: Object,
      required: true
    },

  },
};
</script>

<style>
</style>
