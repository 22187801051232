<template>
  <div class="page-container">
    <div class="page-content">
      <div class="page-header">
        <div class="row space-around mb-3 d-flex">
          <div class="col-3">
            <h1 class="text-white"><i class="mdi mdi-link-variant"></i> Integrações</h1>
          </div>
        </div>
      </div>
      <div
        v-if="loading"
        class="overlay d-flex align-items-center justify-content-center"
      >
        <div class="d-flex justify-content-center mb-2">
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>
      <div class="page-body">
        <div class="card">
          <div class="card-body p-0">
            <div class="table-responsive shadow">
              <table class="table pretty-title-gray mb-0">
                <thead>
                  <tr>
                    <th
                      class="table-pretty dbo-ui col-5 col-sm-2 min-width-integations-table"
                    ></th>
                    <th
                      v-for="field in fields"
                      :key="field"
                      class="table-pretty dbo-ui width-integations-table"
                    >
                      <div class="wrapper-header text-center mx-auto">
                        {{ field }}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="insuranceCategoryType in insuranceCategoryTypes"
                    :key="insuranceCategoryType"
                  >
                    <td
                      class="font-12 cell-border my-5 width-integrations-items align-middle"
                    >
                      {{
                        getEnumKeyByEnumValue(
                          translateInsuranceCategoryTypes,
                          insuranceCategoryType
                        )
                      }}
                    </td>
                    <td
                      v-for="field in fields"
                      :key="field"
                      class="cell-integration cell-nao-integrado"
                    >
                      <div
                        v-for="insurerGuaranteeIntegration in insurerGuaranteeIntegrations"
                        :key="insurerGuaranteeIntegration.id"
                        class="integration-fields"
                      >
                        <td
                          v-if="
                            insurerGuaranteeIntegration.name == field &&
                            insurerGuaranteeIntegration.insurance_category_id ==
                              insuranceCategoryType
                          "
                          :class="{
                            'cell-integrado':
                              insurerGuaranteeIntegration.insurance_category_id ==
                              insuranceCategoryType,
                            'd-flex align-items-center justify-content-center':
                              insurerGuaranteeIntegration.limit <= 0,
                          }"
                          @click="
                            showModalIntegration(
                              insuranceCategoryType,
                              getEnumKeyByEnumValue(
                                translateInsurerIdentifierTypes,
                                field
                              )
                            )
                          "
                        >
                          <i class="fa fa fa-check"></i>
                          <p class="font-12" v-if="insurerGuaranteeIntegration.limit > 0">
                            {{ formatCurrency(insurerGuaranteeIntegration.limit) }}
                          </p>
                        </td>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal">
      <b-modal
        id="show-modal-integration"
        ref="showModalIntegration"
        size="lg"
        class="mt-4"
        hide-footer
      >
        <br />
        <template #modal-title>
          <h5 class="modal-title">Alterar integração</h5>
        </template>
        <div class="row form">
          <div class="col-12 col-lg-auto pr-lg-4 mb-3 ml-3">
            <label class="dbo-ui mb-3" for="">Seguradora</label>
            <img :src="data.icon" alt="" class="d-block mb-3 width-integrations-items" />
          </div>
          <div class="col pl-lg-4 border-left">
            <div class="row">
              <div class="col-12 mb-3">
                <label class="dbo-ui" for="">Tipo de seguro</label>
                <p class="mb-0">{{ this.data.insuranceCategoryType }}</p>
              </div>
              <div class="col-12 mb-3">
                <label class="dbo-ui" for="">Limite</label>
                <money
                  v-bind="money"
                  v-model="data.limit"
                  placeholder="Digite o valor do limite"
                  class="col-12 form-control"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            @click="saveIntegrationData()"
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
          >
            Alterar
          </button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import commomsHelper from "@/mixins/commomsHelper";
import numericFormatterMixin from "@/mixins/numericFormatter";
import commonsService from "@/services/commons";
import translateInsuranceCategoryTypes from "@/enums/translate/insuranceCategoryTypes";
import translateInsurerIdentifierTypes from "@/enums/translate/insurerIdentifierTypes";

export default {
  name: "list-integrations",
  mixins: [commomsHelper, numericFormatterMixin],
  data: () => ({
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: " ",
      precision: 2,
      masked: false,
    },
    data: {
      limit: "",
      icon: "",
      type: 0,
      insurer: "",
    },
    loading: false,
    items: [],
    fields: [],
    insurerGuaranteeIntegrations: [],
    insuranceCategoryTypes: [],
    translateInsuranceCategoryTypes: translateInsuranceCategoryTypes,
    translateInsurerIdentifierTypes: translateInsurerIdentifierTypes,
    insuranceCategoryType: "",
    field: "",
  }),
  mounted() {
    this.getAllInsurers();
  },
  methods: {
    getAllInsurers() {
      this.loading = true;
      commonsService
        .getAllInsurers()
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          response.data.insurerData.forEach((item) => {
            this.fields.push(item.name);
          });
          this.items = response.data;
          this.insuranceCategoryTypes = response.data.insuranceCategories;
          this.insurerGuaranteeIntegrations = response.data.insurerGuaranteeIntegration;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar as seguradoras.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showModalIntegration(insuranceCategoryType, field) {
      this.insuranceCategoryType = insuranceCategoryType;
      this.field = field;
      this.findInsurer();
    },
    findInsurer() {
      this.loading = true;
      commonsService
        .findIntegration(this.insuranceCategoryType, this.field)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.data.limit = response.data.limit;
          this.data.icon = response.data.icon;
          this.data.insuranceCategoryType = this.getEnumKeyByEnumValue(
            translateInsuranceCategoryTypes,
            this.insuranceCategoryType
          );
          this.data.insurer = this.field;
          this.$refs.showModalIntegration.show();
        })
        .catch(() => {
          this.showError("Não foi possível recuperar as seguradoras.");
        })
        .finally(() => {
          this.loading = false;
        });
    },

    saveIntegrationData() {
      commonsService
        .storeIntegrationData(this.insuranceCategoryType, this.data)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
        })
        .catch(() => {
          this.showError("Não foi possível recuperar as seguradoras.");
        })
        .finally(() => {
          this.$refs.showModalIntegration.hide();
          this.fields = [];
          this.getAllInsurers();
        });
    },
  },
};
</script>

<style></style>
