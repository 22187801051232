<template>
  <div class="row">
    <div class="col-12 mb-3">
      <h5><div class="form-subheader"><span>Dados do contrato</span></div></h5>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Valor de cobertura</label>
      <p>{{formatCurrency(policy.coverage_value)}}</p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Prêmio</label>
      <p>{{formatCurrency(policy.insurance_premium)}}</p>
    </div>
  </div>
</template>

<script>
import numericFormatterMixin from '@/mixins/numericFormatter';

export default {
  name: "ContractData",
  props: {
    policy: {
      type: Object,
      required: true
    }
  },
  mixins: [
    numericFormatterMixin,
  ],
};
</script>

<style>
</style>
