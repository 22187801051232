export default {
    'aig': 'AIG',
    'akad': 'Akad',
    'allianz': 'Allianz',
    'american-life': 'American life',
    'anxo-capital': 'Anxo Capital',
    'argo': 'Argo',
    'austral': 'Austral',
    'avla': 'Avla',
    'axa': 'AXA',
    'axa-xl': 'AXA-XL',
    'bail': 'Bail',
    'berkley': 'Berkley',
    'bmg': 'BMG',
    'bradesco-seguros': 'Bradesco Seguros',
    'cesce': 'Cesce',
    'chubb': 'Chubb',
    'columbia': 'Columbia',
    'essor': 'Essor',
    'euler-hermes': 'Euler-hermes',
    'excelsior': 'Excelsior',
    'ezze': 'EZZE',
    'fairfax': 'Fairfax',
    'fator': 'Fator',
    'generali': 'Generali',
    'hdi': 'HDI',
    'infinite-bank': 'InfiniteBank',
    'investprev': 'Investprev',
    'itau': 'Itaú',
    'jns': 'JNS',
    'junto-seguros': 'Junto Seguros',
    'kovr': 'KOVR',
    'liberty': 'Liberty',
    'mapfre': 'Mapfre',
    'metlife': 'Metlife',
    'mitsui': 'Mitsui',
    'mongeral': 'Mongeral',
    'newe': 'Newe',
    'omint': 'Omint',
    'porto-seguro': 'Porto Seguro',
    'pottencial': 'Pottencial',
    'prudential': 'Prudential',
    'sombrero': 'Sombrero',
    'sompo': 'Sompo',
    'suhai': 'Suhai',
    'sulamerica': 'Sulamérica',
    'sura': 'Sura',
    'swiss-re': 'Swiss re',
    'tokio-marine': 'Tokio Marine',
    'too': 'Too Seguros',
    'unimed': 'Unimed',
    'zurich-santander': 'Zurich',
}