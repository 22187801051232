<template>
  <div class="modal">
    <b-modal
      id="show-modal-message"
      ref="showModalMessage"
      @shown="initializeUserAndDate()"
      @hidden="resetModalData()"
      size="lg"
      class="mt-4"
      hide-footer
    >
      <template #modal-title>
        <h5 class="modal-title">Adicionar uma mensagem ao Endosso</h5>
      </template>
      <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
        <b-form @submit.stop.prevent="handleSubmit()" class="form" autocomplete="off">
          <validation-provider
            name="texto da observação"
            rules="required"
            v-slot="validationContext"
            vid="text"
          >
            <b-form-textarea
              rows="7"
              cols="70"
              v-model="message_data.text"
            ></b-form-textarea>
            <b-form-invalid-feedback>{{
              validationContext.errors[0]
            }}</b-form-invalid-feedback>
          </validation-provider>
          <upload-data
            :files="files"
            :url="url"
            @deleteFile="deleteFile"
            @filesUploaded="filesUploaded()"
            ref="upload"
          />
          <button
            v-if="!is_edit"
            @click="saveObservation()"
            :disabled="invalid"
            class="btn btn-primary ml-6 my-2 float-right"
          >
            Salvar
          </button>
          <button
            v-else
            @click="updateObservation()"
            :disabled="invalid"
            class="btn btn-primary ml-6 my-2 float-right"
          >
            Atualizar
          </button>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import UploadData from "./UploadData.vue";
import commomsHelperMixin from "@/mixins/commomsHelper";
import policyService from "@/services/policy";

export default {
  name: "ObservationsModal",
  mixins: [commomsHelperMixin],
  emits: ["setMessageData", "showTableData", "showLoading"],
  components: {
    UploadData,
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    url: "",
    files: [],
    loading: false,
    is_edit: false,
    uploaded: true,
    message_data: {
      text: "",
      author: "",
      date: "",
    },
  }),
  props: {
    observation_data: {},
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    initializeUserAndDate() {
      if (this.observation_data.item && this.observation_data.item.action_id != "") {
        this.is_edit = true;
        this.files = this.observation_data.item.files;
        this.showMessageData();
      }
    },
    showMessageData() {
      this.$emit("showLoading", true);
      policyService
        .showEndorsementObservation(this.observation_data.item.action_id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.message_data.text = response.data.text;
        })
        .catch(() => {
          this.showError("Não foi possível atualizar a mensagem da cotação");
        })
        .finally(() => {
          this.$emit("showLoading", false);
        });
    },
    saveObservation() {
      this.$emit("showLoading", true);
      policyService
        .createEndorsementObservations(this.getIdFromUrl(), this.message_data)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.uploadMessageFile(response.data.id);
        })
        .catch(() => {
          this.showError("Não foi possível recuperar mensagens do endosso.");
        })
        .finally(() => {
          this.finallyAction();
        });
    },
    updateObservation() {
      this.$emit("showLoading", true);
      policyService
        .updateEndorsementObservations(
          this.observation_data.item.action_id,
          this.message_data
        )
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.uploadMessageFile(this.observation_data.item.action_id);
        })
        .catch(() => {
          this.showError("Não foi possível atualizar mensagens do endosso.");
        })
        .finally(() => {
          this.finallyAction();
        });
    },
    uploadMessageFile(endorsement_message_id) {
      this.url = `/api/v1/endorsements/${this.getIdFromUrl()}/upload-message-file/${endorsement_message_id}`;
      this.$nextTick(() => {
        const uploads = Array.isArray(this.$refs.upload)
          ? this.$refs.upload
          : [this.$refs.upload];
        uploads.forEach((element) => {
          if (element.$refs.dropzone.getAcceptedFiles().length > 0) {
            element.$refs.dropzone.processQueue();
            return;
          }
        });
      });
    },
    deleteFile(fileId) {
      if (fileId) {
        this.$emit("showLoading", true);
        policyService
          .deleteMessageFile(fileId)
          .then((response) => {
            if (response.data.error) {
              this.showError(response.data.message);
              return;
            }
          })
          .catch(() => {
            this.showError("Não foi possível deletar o arquivo.");
          })
          .finally(() => {
            this.$emit("showLoading", false);
            this.$emit("showTableData");
          });
      }
    },
    filesUploaded() {
        this.$emit("showLoading", false);
        this.$emit("showTableData");
        this.$bvModal.hide("show-modal-message");
    },
    finallyAction() {
      if(this.$refs.upload.$refs.dropzone.getAcceptedFiles().length <= 0){
        this.$emit("showLoading", false);
        this.$emit("showTableData");
        this.$bvModal.hide("show-modal-message");
      }
    },
    resetModalData() {
      this.is_edit = false;
      this.$emit("setMessageData", {});
      this.message_data.text = "";
      this.files = [];
    },
  },
};
</script>

<style></style>
