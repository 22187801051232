<template>
  <div class="page-container">
    <div class="page-content">
      <div class="page-header">
        <div class="d-flex align-items-center mb-3">
          <h1 class="text-white">
            <i class="mdi mdi-folder-open"></i> Criar Endosso
          </h1>
          <span
            @click="back()"
            class="btn btn-outline-white ml-auto"
            ><i class="fa fa-arrow-left mr-1"></i>
            <span class="d-none d-md-inline">Voltar</span></span
          >
        </div>
      </div>

      <div v-if="alertMessage" class="col-12">
        <b-alert show :variant="alertVariant">{{ alertMessage }}</b-alert>
      </div>

      <div class="page-body">
        <div class="card">
          <div class="card-body p-3 p-lg-5">
            <validation-observer
              ref="observer"
              v-slot="{ handleSubmit, invalid }"
            >
              <general-data v-if="policy" :policy="policy" @getPolicy="getPolicy"/>
              <b-form @submit.stop.prevent="handleSubmit(createEndorsement)">
                <div id="form-cadastrar" class="row form">
                  <div class="col-12 mb-3">
                    <h6 class="form-subheader"><span>Detalhes</span></h6>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label class="dbo-ui" for="">Tipo de alteração</label>
                    <validation-provider name="tipo de seguro" rules="required">
                      <div slot-scope="ProviderProps">
                        <select
                          :class="{ 'is-invalid': ProviderProps.errors[0] }"
                          v-model="dataForm.type"
                          class="form-control"
                        >
                          <option disabled="disabled" :value="null">
                            Selecione...
                          </option>
                          <option
                            v-for="objType in endosementTypes"
                            :key="objType.id"
                            :value="objType.id"
                          >
                            {{ objType.name }}
                          </option>
                        </select>
                        <p class="invalid-feedback-custom">
                          {{ ProviderProps.errors[0] }}
                        </p>
                      </div>
                    </validation-provider>
                  </div>
                  <template v-if="dataForm.type === endorsementEnumType.INCREASE_VALUE ||
                    dataForm.type === endorsementEnumType.INCREASE_VALUE_AND_EXPIRATION ||
                    dataForm.type === endorsementEnumType.DECREASE_VALUE">
                  <div class="col-md-3 mb-3" v-if="policy">
                    <label class="dbo-ui" for="">Importância segurada atual</label>
                    <money
                      v-bind="money"
                      v-model="policy.coverage_value"
                      class="form-control"
                      disabled="disabled"
                    />
                  </div>
                  <div class="col-md-3 mb-3">
                    <label class="dbo-ui" for="">Importância segurada {{dataForm.type === endorsementEnumType.DECREASE_VALUE?'- redução':'+ aumento'}}</label>
                    <money
                      v-bind="money"
                      v-model="dataForm.new_coverage_value"
                      class="form-control"
                    />
                  </div>
                  <div class="col-md-3 mb-3" v-if="dataForm.type === endorsementEnumType.INCREASE_VALUE || dataForm.type === endorsementEnumType.INCREASE_VALUE_AND_EXPIRATION">
                    <label class="dbo-ui" for="">Aumento na importância segurada</label>
                    <money
                      v-bind="money"
                      v-model="coverageSumValue"
                      class="form-control"
                    />
                  </div>
                  <div class="col-md-3 mb-3" v-if="dataForm.type === endorsementEnumType.DECREASE_VALUE">
                    <label class="dbo-ui" for="">Redução na importância segurada</label>
                    <money
                      v-bind="money"
                      v-model="coverageReduceValue"
                      class="form-control"
                    />
                  </div>
                  <div class="col-md-3 mb-3">
                    <label class="dbo-ui" for="">Inicio da nova IS</label>
                    <validation-provider
                      name="data final"
                      rules="required"
                      v-slot="validationContext"
                      vid="validity_expiration"
                    >
                      <b-form-input
                        v-model="dataForm.new_validity_start"
                        type="date"
                        :state="getValidationState(validationContext)"
                        placeholder=""
                      ></b-form-input>
                      <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                    </validation-provider>
                  </div>
                  </template>
                  
                  <template v-if="dataForm.type === endorsementEnumType.INCREASE_EXPIRATION ||
                    dataForm.type === endorsementEnumType.INCREASE_VALUE_AND_EXPIRATION ||
                    dataForm.type === endorsementEnumType.DECREASE_EXPIRATION">
                    <div class="col-md-4 mb-3">
                        <label class="dbo-ui">Data de vencimento</label>
                        <b-form-input
                          v-model="policy.validity_expiration"
                          type="date"
                          disabled="disabled"
                          placeholder=""
                        ></b-form-input>
                    </div>
                    <div class="col-md-4 mb-3">
                        <label class="dbo-ui">Nova data de vencimento</label>
                        <b-form-input
                          v-model="dataForm.new_expiration_date"
                          type="date"
                          placeholder=""
                        ></b-form-input>
                    </div>
                    <div class="col-md-4 mb-3">
                      <label class="dbo-ui" for="">Dias {{dataForm.type === endorsementEnumType.DECREASE_EXPIRATION?'a reduzir':'adicionais'}}</label>
                      <b-form-input v-model="newValidityExpirationDays" type="number" placeholder=""></b-form-input>
                    </div>
                  </template>
                  <div class="col-md-12 mb-3">
                    <validation-provider
                      name="descrição"
                      rules="required"
                      v-slot="validationContext"
                      vid="description"
                    >
                      <label class="dbo-ui" for="">Descrição</label>
                      <b-form-textarea
                        v-model="dataForm.description"
                        :state="getValidationState(validationContext)"
                        class="py-4 col-md-12"
                      ></b-form-textarea>
                      <b-form-invalid-feedback>{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                    </validation-provider>
                  </div>

                  <div class="col-12 mb-3">
                    <upload-data ref="upload"
                    :files="files"
                    :url="urlUpload"
                    :forceRedirect="true"
                    @upload-finished="redirect(routeRedirect)"/>
                  </div>
                  <div class="col-12 text-right">
                    <button :disabled="invalid || submitting" class="button btn btn-primary">
                      Criar Endosso
                      <b-spinner v-if="loading" small></b-spinner>
                    </button>
                  </div>
                </div>
                <div class="resource resource-warning resource-text mt-3" v-if="dataForm.type>0" >
                  <strong>Docucumentos necessários:&nbsp;</strong>
                  <small>
                    {{endosementTypes.find((item)=>item.id===dataForm.type).documents}}
                  </small>


                </div>
              </b-form>

            </validation-observer>
            <div class="col-12 mt-5">

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import endorsementEnumType from "@/enums/endorsementType";
import { UploadData } from '@/components';
import policyService from "@/services/policy";
import {GeneralData} from "@/components/Policy";
import dateFormatter from "@/mixins/dateFormatter";
import moment from "moment";

export default {
  name: "create-endorsement",
  components: {
    GeneralData,
    ValidationObserver,
    ValidationProvider,
    UploadData
  },
  mixins: [dateFormatter],
  props: {
    policyId: {
      type: String,
      required: true
    },
  },
  data: () => ({
    dataForm: {
      policy_id: null,
      type: 0,
      new_coverage_value: 0,
      new_expiration_date: null
    },
    files: [],
    newValidityExpirationDays: 0,
    urlUpload: '',
    routeRedirect: 'list-policies',
    loading: false,
    validate: false,
    policy: null,
    coverageSumValue: 0,
    coverageReduceValue: 0,
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: " ",
      precision: 2,
      masked: false,
    },
    endosementTypes: [
      {id: 1, name: 'Aumento de valor', documents: 'Judicial (Ofício do Juiz), \n' +
          'Outras modalidades (Termo aditivo e contrato inicial)'},
      {id: 2, name: 'Aumento no prazo', documents: 'Judicial (Ofício do Juiz), \n' +
          'Outras modalidades (Termo aditivo e contrato inicial)'},
      {id: 3, name: 'Aumento no prazo e valor', documents: 'Judicial (Ofício do Juiz), \n' +
          'Outras modalidades (Termo aditivo e contrato inicial)'},
      {id: 4, name: 'Redução de valor', documents: 'Endosso de redução de Importância Segurada \n' +
          'Judicial (Ofício do Juiz), \n' +
          'Outras modalidades (Termo aditivo e contrato inicial)'},
      {id: 5, name: 'Redução no prazo'},
      {id: 6, name: 'Correção de informações',documents: 'Judicial (Ofício do Juiz), \n' +
          'Outras modalidades (Termo aditivo e contrato inicial)' },
      {id: 7, name: 'Cancelamento', documents: 'Endosso de cancelamento\n' +
          'Judicial/ Recursal (Ofício do Juiz ou Certidão do Trânsito em julgado),\n' +
          'Outras modalidades (Anuência do segurado) '}
    ],
    submitting: false,
    endorsementEnumType: endorsementEnumType,
  }),
  created(){
    this.getPolicy(this.policyId);
    if (this.$route.query.type) {
      this.dataForm.type = parseInt( this.$route.query.type );
    }
  },
  methods: {
    createEndorsement() {
      this.resetAlert();
      if (!this.submitting) {
        this.submitting = true;
        this.loading = true;
        this.dataForm.policy_id = this.policy.id
        policyService
          .storeEndorsement(this.dataForm)
          .then((response) => {
            this.submitting = false;
            if (response.data.error) {
              this.showError(response.data.message);
              return;
            }
            
            this.urlUpload = `/api/v1/endorsements/${response.data.id}/files`
            this.$nextTick(() => {
              if(this.$refs.upload.$refs.dropzone.getAcceptedFiles().length > 0) {
                this.$refs.upload.$refs.dropzone.processQueue();
                return;
              }
              this.redirect(this.routeRedirect);
            });
          })
          .catch(() => {
            this.showError("Não foi possível criar o endosso.");
          });
        }
    },
    redirect(routeName) {
      this.$router.push({ name: routeName });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    back() {
      this.$router.push({
        name: this.routeRedirect,
        query: { filter: true},
      });
    },
    getPolicy(uid){
      this.loading = true;
      this.resetAlert();
      policyService.getByUID(uid).then(response => {
        if (response.data.error) {
          this.showError(response.data.message);
          return
        }
        this.policy = response.data
      }).catch(() => {
        this.showError('Não foi possível recuperar a apólice.');
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  watch: {
    policy: function (value) {
      if (value?.coverage_value) {
        this.dataForm.new_coverage_value = value.coverage_value
        this.coverageSumValue = 0
        this.coverageReduceValue = 0
        this.dataForm.new_expiration_date = value.validity_expiration
        this.newValidityExpirationDays = 0
      }
    },
    endosementType: function () {
      if (this.policy?.coverage_value) {
        this.dataForm.new_coverage_value = this.policy.coverage_value
        this.coverageSumValue = 0
        this.coverageReduceValue = 0
        this.dataForm.new_expiration_date = this.policy.validity_expiration
        this.newValidityExpirationDays = 0
      }
    },
    "dataForm.new_coverage_value": function (value) {
      if (this.dataForm.type === endorsementEnumType.INCREASE_VALUE || this.dataForm.type === endorsementEnumType.INCREASE_VALUE_AND_EXPIRATION) {
        this.coverageReduceValue = 0
        this.coverageSumValue = value - this.policy.coverage_value
      }
      if (this.dataForm.type === endorsementEnumType.DECREASE_VALUE) {
        this.coverageSumValue = 0
        this.coverageReduceValue = this.policy.coverage_value - value
      }
    },
    coverageSumValue: function (value) {
      if (this.dataForm.type === endorsementEnumType.INCREASE_VALUE || this.dataForm.type === endorsementEnumType.INCREASE_VALUE_AND_EXPIRATION) {
        this.coverageReduceValue = 0
        this.dataForm.new_coverage_value = this.policy.coverage_value + value
      }
    },
    coverageReduceValue: function (value) {
      if (this.dataForm.type === endorsementEnumType.DECREASE_VALUE) {
        this.coverageSumValue = 0
        this.dataForm.new_coverage_value = this.policy.coverage_value - value
      }
    },
    newValidityExpirationDays: function (value) {
      if (this.dataForm.type === endorsementEnumType.INCREASE_EXPIRATION || this.dataForm.type === endorsementEnumType.INCREASE_VALUE_AND_EXPIRATION) {
        const d = moment(this.policy.validity_expiration).add(value, 'days').format('YYYY-MM-DD')
        this.dataForm.new_expiration_date = d

      }
      if(this.dataForm.type === endorsementEnumType.DECREASE_EXPIRATION) {
        const d = moment(this.policy.validity_expiration).subtract(value, 'days').format('YYYY-MM-DD')
        this.dataForm.new_expiration_date = d
      }
    },
    'dataForm.new_expiration_date': function (value) {
      if (this.dataForm.type === endorsementEnumType.INCREASE_EXPIRATION || this.dataForm.type === endorsementEnumType.INCREASE_VALUE_AND_EXPIRATION) {
        this.newValidityExpirationDays = moment(value).diff(moment(this.policy.validity_expiration), 'days')
      }
      if(this.dataForm.type === endorsementEnumType.DECREASE_EXPIRATION) {
        this.newValidityExpirationDays = moment(this.policy.validity_expiration).diff(moment(value), 'days')
      }
    },
  },

};
</script>

<style>
</style>

