<template>
  <div>
    <h5>
      <div class="form-subheader"><span>Mensagens do Endosso</span></div>
    </h5>

    <div class="row justify-content-end">
      <div class="col-md-6">
        <a
          @click="createObservation()"
          class="button btn btn-outline-secondary btn-sm mb-3 float-right"
        >
          <i class="fa fa-plus mr-1"></i>
          Adicionar Mensagem
        </a>
      </div>
      <b-table :fields="fields" :items="items">
        <template #cell(author)="data">
          <span class="centered-cell">{{ data.value }}</span>
        </template>
        <template #cell(text)="data">
          <span class="centered-cell break-line">{{ data.value }}</span>
        </template>
        <template #cell(date)="data">
          <span class="centered-cell">{{
            data.value != "" ? formatDateTimeCustom(data.value) : "- - -"
          }}</span>
        </template>
        <template #cell(files)="data">
          <div>
            <span class="row mx-2 my-1" v-for="(file, index) in data.value" :key="index">
              {{ file.name }}
              <i class="fas fa-download icon-btn ml-2" @click="downloadFile(file)"></i>
            </span>
          </div>
        </template>
        <template #cell(actions)="row">
          <div class="centered-header">
            <a
              class="text-secondary"
              v-if="getUserId == row.item.user_id || isMaster()"
              @click="deleteObservation(row)"
            >
              <i class="fas fa-trash mx-1 icon-btn"></i>
            </a>
            <a
              class="text-secondary"
              v-if="getUserId == row.item.user_id || isMaster()"
              @click="showObservation(row)"
            >
              <i class="fas fa-pen mx-1 icon-btn"></i>
            </a>
          </div>
        </template>
      </b-table>
      <div class="modal">
        <b-modal
          id="show-modal-delete-observations"
          ref="showModalDeleteObservations"
          size="sm"
          class="mt-4"
          hide-footer
          centered
        >
          <br />
          <template #modal-title>
            <h5 class="modal-title">Deseja apagar essa observação ?</h5>
          </template>
          <div class="text-center font-14 dbo-ui">
            Deseja excluir a observação criada por {{ observation.author }} na data
            {{ formatDateTimeCustom(observation.date) }}
          </div>
          <button
            @click="$bvModal.hide('show-modal-delete-observations')"
            class="btn btn-outline-secondary btn-lg text-uppercase my-4 px-4 mr-1"
          >
            <i class="fa fa-angle-left mr-2"></i
            ><span class="d-none d-md-inline-block"> Voltar</span>
          </button>
          <button
            @click="destroyObservation(observation)"
            class="btn btn-primary w-50 my-4 float-right"
          >
            Excluir
            <i class="fa fa-angle-right ml-2"></i>
          </button>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import dateFormatter from "@/mixins/dateFormatter";
import commomsHelper from "@/mixins/commomsHelper";
import policyService from "@/services/policy";
import authenticationService from "@/services/authentication";
export default {
  name: "ObservationsTable",
  mixins: [dateFormatter, commomsHelper],
  data: () => ({
    loading: false,
    items: [],
    observation: {},
  }),
  computed: {
    fields() {
      var columns = [
        {
          key: "author",
          label: "usuário",
          tdClass: "font-6",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "text",
          label: "observação",
          tdClass: "font-6",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "date",
          label: "data",
          tdClass: "font-6",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "files",
          label: "anexos",
          tdClass: "font-6",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "actions",
          label: "",
          tdClass: "font-14 ",
          thClass: "table-pretty dbo-ui centered-header",
        },
      ];
      return columns;
    },
    getUserId() {
      return this.$store.getters.user.id;
    },
  },
  created() {
    this.getAllObservations();
  },
  methods: {
    getAllObservations() {
      this.$emit("showLoading", true);
      policyService
        .getEndorsementObservations(this.getIdFromUrl())
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.items = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar mensagens do endosso.");
        })
        .finally(() => {
          this.$emit("showLoading", false);
        });
    },
    deleteObservation(data) {
      this.observation = data.item;
      this.$bvModal.show("show-modal-delete-observations");
    },
    destroyObservation(data) {
      this.$emit("showLoading", true);
      policyService
        .destroyEndorsementObservations(data.action_id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
        })
        .catch(() => {
          this.showError("Não foi possível recuperar mensagens do endosso.");
        })
        .finally(() => {
          this.$bvModal.hide("show-modal-delete-observations");
          this.$emit("showLoading", false);
          this.getAllObservations();
        });
    },
    downloadFile(file) {
      this.$emit("showLoading", true);
      policyService
        .downloadMessageFile(file.id)
        .then((response) => {
          if (response.data.error) {
            return;
          }
          this.openLinkTargetBlank(response.data);
        })
        .catch(() => {
          this.showError("Não foi possível baixar o arquivo.");
        })
        .finally(() => {
          this.$emit("showLoading", false);
        });
    },
    createObservation() {
      this.$emit("setObservationData", this.observation);
      this.$bvModal.show("show-modal-message");
    },
    showObservation(data = null) {
      this.$bvModal.show("show-modal-message");
      this.$emit("setObservationData", data);
    },
    isMaster() {
      return authenticationService.isMaster();
    },
  },
};
</script>

<style></style>
