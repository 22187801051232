<template>
  <div class="row mt-3" v-if="policy.renovations.length">
    <div class="col-12 mb-3">
      <h5><div class="form-subheader"><span>Renovações</span></div></h5>
    </div>

    <div class="col-12 mb-3">

      <table class="pretty admin mb-3 shadow">
          <thead>
            <tr>
              <th>Cotação</th>
              <th>Status</th>
              <th>Data/Hora</th>
              <th>Usuário</th>
            </tr>
          </thead>
          <tbody>
            <template>
              <tr v-for="policyRenovation in policy.renovations" :key="policyRenovation.id">
                <td>
                  <a class="btn" @click="redirectQuotation(policyRenovation.quotation.id)">
                    #{{ policyRenovation.quotation.number }} - 
                    {{ policyRenovation.quotation.document }} -
                    {{ policyRenovation.quotation.name }}
                  </a>
                </td>
                <td>{{ policyRenovation.status.translate }}</td>
                <td>{{ formatDateTimeFromISO(policyRenovation.created_at) }}</td>
                <td>{{ policyRenovation.user ? policyRenovation.user.name : '' }}</td>
              </tr>
            </template>
          </tbody>
        </table>

    </div>
    
  </div>
</template>

<script>
import dateFormatterMixin from "@/mixins/dateFormatter";

export default {
  name: "RenovationsData",
  props: {
    policy: {
      type: Object,
      required: true
    }
  },
  mixins: [
    dateFormatterMixin,
  ],
  methods: {
    redirectQuotation(id) {
      this.redirect('details-quotation', {
        quotationId: id,
      });
    },
    redirect(routeName, params = null) {
      this.$router.push({ name: routeName, params: params });
    },
  }
};
</script>

<style>
</style>
