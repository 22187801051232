<template>
  <div class="page-container">
    <div class="page-content">
      <div class="page-header">
        <div class="d-flex align-items-center">
          <h1 class="text-white">Apoio Jurídico</h1>
          <button
            v-if="$can('process-import')"
            @click="openImportProcessesModal()"
            class="btn btn-outline-white ml-auto"
          >
            <i class="far fa-file-excel"></i>
            <span class="d-none d-md-inline"> Importar Documento</span>
          </button>
        </div>
        <div class="d-block text-white text-right mb-3" v-if="last_import">
          <small v-html="MsgLastImport"></small>
        </div>
      </div>

      <div v-if="alertMessage" class="col-12">
        <div class="alert-custom alert-danger-custom">{{ alertMessage }}</div>
      </div>

      <div class="page-body">
        <div class="card">
          <div class="card-body p-0">
            <div class="table-responsive shadow">
              <b-table
                class="table pretty-title-gray mb-0"
                :items="items"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-desc.sync="sortDesc"
                :sort-compare="sortCompareCustom"
              >
                <template #cell(id)="data">
                  <span>#{{ data.value }}</span>
                </template>
                <template #cell(value_deposit)="data">
                  <span>{{ formatCurrency(data.value) }}</span>
                </template>
                <template #cell(liquidation_value)="data">
                  <span>{{ formatCurrency(data.value) }}</span>
                </template>
                <template #cell(action)="data">
                  <span class="mt-1">
                    <i
                      v-if="$can('process-show')"
                      @click="redirect('details-process', { processId: data.item.id })"
                      v-b-tooltip.hover
                      title="Visualizar"
                      class="fas fa-eye icon-btn mx-1 small align-self-center"
                    ></i>
                    <i
                      v-if="$can('process-delete')"
                      class="fas fa-trash icon-btn text-secondary mx-1 small align-self-center"
                      @click="showModalDestroy(data)"
                      data-toggle="tooltip"
                      v-b-tooltip.hover
                      title="Apagar"
                    >
                    </i>
                  </span>
                </template>
              </b-table>
            </div>
            <nav aria-label="Paginação" class="m-3">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <ImportProcessesModal
      v-model="showImportProcessModal"
      @processesImported="getProcesses()"
    />
    <div class="modal">
      <b-modal
        id="show-modal"
        ref="showModal"
        size="sm"
        class="mt-4"
        hide-footer
        centered
      >
        <br />
        <template #modal-title> Excluir processo </template>

        <div class="card card-termo-uso mt-1 mb-5">
          <p class="my-4">{{ deletion_data }}</p>
        </div>
        <div class="row">
          <div class="col-12 col-lg-12">
            <button
              @click="$bvModal.hide('show-modal')"
              class="btn btn-outline-secondary btn-lg text-uppercase px-4 mr-1"
            >
              <i class="fa fa-angle-left mr-2"></i
              ><span class="d-none d-md-inline-block"> Voltar</span>
            </button>
            <button
              v-if="$can('process-delete')"
              @click="destroyProcess()"
              class="btn btn-primary btn-lg text-uppercase px-4"
              data-toggle="modal"
              data-target="#modal-cnpj"
            >
              Excluir<i class="fa fa-angle-right ml-2"></i>
            </button>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import jurimetryService from "@/services/jurimetry";
import numericFormatterMixin from "@/mixins/numericFormatter";
import uiHelperMixin from '@/mixins/uiHelper';
import dateFormatter from "@/mixins/dateFormatter";
import ImportProcessesModal from "@/components/Jurimetry/ImportProcesses/Index.vue";

export default {
  name: "list-jurimetries",
  components: {
    ImportProcessesModal,
  },
  mixins: [numericFormatterMixin, dateFormatter, uiHelperMixin],
  data: () => ({
    perPage: 10,
    currentPage: 1,
    sortDesc: false,
    fields: [
        { key: "number", label: "Número do Processo", tdClass: "font-12", thClass: "table-pretty dbo-ui rounded-top", },
      { key: "active_part", label: "Partes polo Ativo", tdClass: "font-12", thClass: "table-pretty dbo-ui", },
      {
        key: "substitution_order",
        label: "Possui pedido substituição",
        tdClass: "font-12",
        thClass: "table-pretty dbo-ui",
      },
      { key: "resource_types", label: "Tipo Recurso", tdClass: "font-12", thClass: "table-pretty dbo-ui", },
      {key: "value_deposit", label: "Valor Depósito", tdClass: "font-12", sortable: true, thClass: "table-pretty dbo-ui",},
      { key: "action", label: "Ação", class: "nowrap", thClass: "table-pretty dbo-ui rounded-top", },
    ],
    items: [],
    deletion_data: "",
    current_deletion: null,
    showImportProcessModal: false,
    holder_document: "",
    last_import: null
  }),
  created() {
    this.getProcesses();
  },
  computed: {
    rows() {
      return this.items.length;
    },
    MsgLastImport() {
      return this.last_import ? 'Último processo importado em <strong class="font-14">' + this.last_import.toLocaleDateString("pt-BR") +'</strong>' : '-';
    }
  },
  methods: {
    getProcesses() {
      this.resetAlert();
      jurimetryService
        .getProcesses()
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.items = response.data;
          this.last_import = this.getLastCreatedDate( this.items );
        })
        .catch(() => {
          this.showError("Não foi possível recuperar as processos.");
        })
        .finally(() => {
          this.holder_document = "";
        });
    },
    redirect(routeName, params = null) {
      this.$router.push({ name: routeName, params: params });
    },
    openImportProcessesModal() {
      this.showImportProcessModal = true;
    },
    showModalDestroy(val) {
      this.current_deletion = val.item.id;
      this.deletion_data =
        "Deseja realmente excluir os dados do processo " +
        val.item.number +
        " ?";
      this.$refs.showModal.show();
    },
    destroyProcess() {
      this.loading = true;
      this.$refs.showModal.hide();
      jurimetryService
        .destroyProcess(this.current_deletion)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.getProcesses();
        })
        .catch(() => {
          this.showError("Não foi possível remover este processo.");
        })
        .finally(() => {
          this.current_deletion = null;
          this.loading = false;
        });
    },
    filterProcesses() {
      this.getProcesses();
      this.$refs.modalFilter.hide();
    },
    cleanFilter() {
      this.holder_document = "";
      this.getProcesses();
    },
  },
};
</script>

<style></style>
