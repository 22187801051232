<template>
  <div>
    <SideBar/>
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import SideBar from "@/components/SideBar";
export default {
  name: "Panel",
  components: {
    SideBar,
  }
};
</script>

<style>
</style>
