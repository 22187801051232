<template>
  <div class="modal">
    <b-modal
      id="show-modal-cancel-policy"
      ref="showModalCancelPolicy"
      class="mt-4"
      size="sm"
      centered
      hide-footer
    >
      <template #modal-title> Deseja cancelar esta apólice ? </template>

      <div>
        <a
          @click="$bvModal.hide('show-modal-cancel-policy')"
          class="btn btn-outline-secondary"
          ><i class="fa fa-angle-left"></i> Voltar</a
        >
        <a @click="cancelPolicy" class="btn btn-md btn-danger ml-2"
          ><i class="fa fa-solid fa-ban"></i> Confirmar</a
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import endorsementTypes from "@/enums/endorsementTypes";

export default {
  name: "CancelPolicy",
  emits: ["getPolicy"],
  props: {
    policy: {
      type: Object,
    },
  },
  data: () => ({
    endorsementTypes: endorsementTypes
  }),
  methods: {
    cancelPolicy() {
      this.$router.push({ name: 'create-endorsement', query: { type: this.endorsementTypes.CANCEL_ENDORSEMENT } });
    },
  },
};
</script>

<style>
</style>