<template>
  <div>
    <label class="dbo-ui" for="">{{ title }}</label>
    <vue2Dropzone
      class="dropzone"
      ref="dropzone"
      v-model="dataForm.archives"
      id="dropzone"
      @vdropzone-removed-file="deleteFile"
      @vdropzone-success-multiple="filesUploaded"
      :options="dropzoneOptions"
      :use-custom-slot="true"
    >
      <div class="dz-message text-muted" data-dz-message>
        <span class="font-28"><i class="fa fa-upload"></i></span>
        <p>
          Clique ou arraste os documentos para esta área<br />
          <small>pdf, doc, docx, xls, xslx, jpg, png, bmp</small>
        </p>
      </div>
    </vue2Dropzone>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  name: "UploadData",
  props: {
    files: {
      type: Array,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "Adicione os documentos de sua empresa (Preenchimento Opcional)",
    },
  },
  components: {
    vue2Dropzone,
  },
  data() {
    let dataUpload = this.dataUpload();
    return {
      dataForm: {},
      is_destroying: false,
      dropzoneOptions: {
        url: dataUpload.urlUpload,
        thumbnailWidth: 150,
        parallelUploads: 10,
        uploadMultiple: true,
        addRemoveLinks: true,
        autoProcessQueue: false,
        maxFilesize: 10,
        headers: { Authorization: dataUpload.token.access_token },
      },
    };
  },
  beforeDestroy() {
    this.is_destroying = true;
  },
  destroyed() {
    this.is_destroying = false;
  },
  methods: {
    filesUploaded() {
      this.$emit("filesUploaded");
    },
    fillFiles(files) {
      files.forEach((item) => {
        this.$refs.dropzone.manuallyAddFile({ name: item.name, id: item.id }, "");
      });
    },
    deleteFile(file) {
      if (this.is_destroying) {
        return;
      }
      this.$emit("deleteFile", file.id);
    },
    dataUpload(url = null) {
      this.url = url ? url : this.url;
      let urlUpload = process.env.VUE_APP_API_URL + this.url;
      let token = JSON.parse(localStorage.getItem("token"));
      return {
        token,
        urlUpload,
      };
    },
  },
  watch: {
    url(data) {
      var dataUpload = this.dataUpload(data);
      this.$set(this.$refs.dropzone.dropzone.options, "url", dataUpload.urlUpload);
    },
    files(data) {
      this.fillFiles(data);
    },
  },
};
</script>

<style></style>
