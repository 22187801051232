import http from "@/services/http";

const uploadImportSpreadsheet = (file, document_holder, only_values) => {
  var formData = new FormData();
  formData.append("document_holder", document_holder);
  formData.append("only_values", only_values);
  formData.append("file", file);

  return http.post('import-jurimetries', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
const getImportSpreadsheetPreview = (importId) => http.get(`import-jurimetries/${importId}`);
const discardImportSpreadsheet = (importId) => http.delete(`import-jurimetries/${importId}`);
const confirmImportProcesses = (importId) => http.post(`import-jurimetries/${importId}/confirmation`);
const getProcessByUID = (uid) => http.get(`processes/${uid}`)
const getProcesses = () => http.get('processes');
const destroyProcess = (uid) => http.delete(`processes/${uid}`)

export default {
  uploadImportSpreadsheet,
  getImportSpreadsheetPreview,
  discardImportSpreadsheet,
  confirmImportProcesses,
  getProcessByUID,
  getProcesses,
  destroyProcess
}
