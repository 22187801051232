<template>
  <div class="page-container">
    <div class="page-content">
      <div class="page-header">
        <div class="row space-around mb-3">
          <div class="col-10">
            <h1 class="text-white">Endossos</h1>
          </div>
        </div>
      </div>

      <div v-if="alertMessage" class="col-12">
        <div class="alert-custom alert-danger-custom">{{ alertMessage }}</div>
      </div>

      <div class="page-body">
        <div class="card-body p-0">

          
          <div class="row row-cols-1 row-cols-lg-5">
            <div
              class="col"
              v-for="(column, index) in columnKanban()"
              :key="index">
              <div class="card shadow mb-3">
                <div v-bind:class="cardColors[index]" class="card-header border-bottom px-3 text-uppercase text-center font-weight-bold">
                  {{ getEnumKeyByEnumValue(translateStatusQuotationTypes, column)}}
                </div>
                <div class="card-body p-3 fade-in">
                  <div
                    v-for="(endorsement, index) in items[getEnumKeyByEnumValue(statusQuotationTypes, column)]"
                    :key="index">

                    <div class="kanban-card" v-if="index < limitResults[getEnumKeyByEnumValue(statusQuotationTypes, column)]">
                      <div class="d-flex align-items-start" v-if="endorsement">
                        <div class="d-block">
                          <strong class="pointer" @click="$can('endorsement-show') ? redirect('details-endorsement', {endorsementId: endorsement.id}) : null ">
                            {{ endorsement.policy_holder_name }}
                          </strong><br />
                          <span class="pointer" @click="$can('policy-show') ? redirect('details-policy', {policyId: endorsement.policy_id}) : null ">
                            <i class="far fa-file-alt mr-2"></i>Apólice nº {{ endorsement.policy_number }}
                          </span><br />
                          <span><i class="far fa-user mr-2"></i>{{ endorsement.insured_name }}</span><br />
                          {{ endorsement.endorsement_type ? endorsement.endorsement_type.translate : "---" }}<br />
                        </div>
                        
                        <span
                          v-show="
                            endorsement.status_id != statusQuotationTypes.RESOLVED && 
                            $can.any(['endorsement-edit', 'endorsement-update'])
                          "
                          @click="showModal(endorsement)"
                          class="ml-auto"
                          data-toggle="tooltip"
                          title="Alterar status"
                        >
                          <button
                            type="button"
                            data-toggle="modal"
                            data-target="#modal-cotacao-status"
                            class="button btn btn-sm btn-link text-secondary"
                          >
                            <i class="fa fa-ellipsis-v"></i>
                          </button>
                        </span>                        
                      </div>
                    </div>
                  </div>
                  <div
                    class="d-flex justify-content-center"
                    v-if="ifExistsPaginate( getEnumKeyByEnumValue(statusQuotationTypes, column) )">
                    <button
                      class="btn btn-primary my-2"
                      @click="addMoreResults( getEnumKeyByEnumValue(statusQuotationTypes, column) )">
                      Mostrar Mais
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- Modal de status de endosso -->
    <b-modal
      v-model="showModalStatus"
      id="modal-endosso-status"
      size="lg">
      <template #modal-title>
        <div>
          <div>
            <h5 class="modal-title" id="modal-cotacao-status-label">
              Alterar Endosso
            </h5>
            <p class="text-muted mb-0">Revise e altere o status do endosso</p>
          </div>
        </div>
      </template>
      <div v-if="endorsementSelected" class="modal-lg">
        <div class="p-3 pb-0">
          <div>
            <div class="row form">
              <div class="col-12">
                <label class="dbo-ui">Tomador</label>
                <p>{{ formatCnpj(endorsementSelected.policy_holder_document) }} - <strong>{{ endorsementSelected.policy_holder_name }}</strong></p>
              </div>
              <div class="col-sm-6">
                <label class="dbo-ui">Tipo de alteração</label>
                <p>{{ endorsementSelected.endorsement_type ? endorsementSelected.endorsement_type.translate : '---' }}</p>
              </div>
              <div class="col-sm-6">
                <label class="dbo-ui">Usuário</label>
                <p>{{ endorsementSelected.user ? endorsementSelected.user.name : '---' }}</p>
              </div>
              <div class="col-12">
                <label class="dbo-ui">Descrição</label>
                <p>{{ endorsementSelected.description }}</p>
              </div>

              <div class="col-12 col-lg-4" v-if="showValueField">
                <label class="dbo-ui">Novo Valor</label>
                <p>{{ formatCurrency(endorsementSelected.new_coverage_value) }}</p>
              </div>
              <div class="col-12 col-lg-4" v-if="endorsementSelected.new_validity_start">
                <label class="dbo-ui">Inicio da nova IS</label>
                <p>{{ formatDateLocal(endorsementSelected.new_validity_start) }}</p>
              </div>
              <div class="col-12 col-lg-4" v-if="showDateField">
                <label class="dbo-ui">Nova data de vencimento</label>
                <p>{{ formatDateLocal(endorsementSelected.new_expiration_date) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-12 pb-4" v-if="isMaster()">
        <label class="dbo-ui" for="">Status</label>
        <select class="custom-select" v-model="dataForm.status">
          <option value="null" selected>Selecione...</option>
          <option
            v-for="(status, index) in options"
            :key="index"
            :value="status.value">
            {{ status.text }}
          </option>
        </select>
      </div>
      <template #modal-footer>
        <button
          @click="showModalDestroy()"
          type="button"
          class="btn btn-danger mr-auto"
        >
          <b-spinner v-if="loading_delete" small></b-spinner> Excluir Endosso
        </button>

        <button
          :disabled="disabledButtonChange"
          @click="changeStatusEndorsement()"
          type="button"
          class="btn btn-primary"
        >
          <b-spinner v-if="loading" small></b-spinner> Alterar
        </button>
      </template>
    </b-modal>

    <!-- Modal de exclusão de endosso -->
    <div class="modal">
      <b-modal
        id="show-modal"
        ref="showModal"
        size="md"
        class="mt-4"
        hide-footer
        centered>
        <template #modal-title>Excluir Endosso</template>

        <div class="card card-termo-uso mt-1 mb-2">
          <p v-html="deletion_msg"></p>
        </div>
        <div class="row">
          <div class="col-12 col-lg-12">
            <button
              @click="$bvModal.hide('show-modal')"
              class="btn btn-outline-secondary btn-lg text-uppercase px-4 mr-1"
            >
              <i class="fa fa-angle-left mr-2"></i
              ><span class="d-none d-md-inline-block"> Voltar</span>
            </button>
            <button
              @click="destroyEndorsement()"
              class="btn btn-primary btn-lg text-uppercase px-4"
              data-toggle="modal"
              data-target="#show-modal"
            >
              Excluir<i class="fa fa-angle-right ml-2"></i>
            </button>
          </div>
        </div>
      </b-modal>
    </div>

  </div>
</template>

<script>
import policyService from "@/services/policy";
import dateFormatter from "@/mixins/dateFormatter";
import commomsHelper from "@/mixins/commomsHelper";
import numericFormatter from "@/mixins/numericFormatter";
import authenticationService from "@/services/authentication";
import statusQuotationTypes from "@/enums/statusQuotationTypes";
import endorsementTypes from "@/enums/endorsementTypes";
import translateInsuranceTypes from "@/enums/translate/insuranceTypes";
import translateStatusQuotationTypes from "@/enums/translate/statusQuotationTypes";

export default {
  name: "list-endorsement",
  mixins: [dateFormatter, commomsHelper, numericFormatter],
  data: () => ({
    perPage: 10,
    currentPage: 1,
    fields: [
      { key: "policy.number", label: "Apólice", tdClass: "column-size-share", thClass: "table-pretty dbo-ui rounded-top", },
      { key: "status_id", label: "Status", tdClass: "font-14", thClass: "table-pretty dbo-ui", },
      { key: "description", label: "Descrição", tdClass: "font-14", thClass: "table-pretty dbo-ui", },
      { key: "policy.user.name", label: "Usuário", tdClass: "font-14", thClass: "table-pretty dbo-ui", },
      { key: "created", label: "Data de criação", tdClass: "font-14", thClass: "table-pretty dbo-ui", },
      { key: "action", label: "Ação", tdClass: "font-14", thClass: "table-pretty dbo-ui rounded-top", },
    ],
    items: [],
    filter: null,
    loading: false,
    loading_delete: false,
    deletion_msg: '',
    translateStatusQuotationTypes: translateStatusQuotationTypes,
    translateInsuranceTypes: translateInsuranceTypes,
    statusQuotationTypes: statusQuotationTypes,
    endorsementTypes: endorsementTypes,
    cardColors: {
      REQUESTED: "card-kanban-analysis",
      PROGRESS: "card-kanban-analysis",
      PENDING: "card-kanban-declined",
      RESOLVED: "card-kanban-issue",
    },
    limit: 10,
    limitResults: {
      REQUESTED: 10,
      PROGRESS: 10,
      PENDING: 10,
      RESOLVED: 10
    },
    showModalStatus: false,
    endorsementSelected: {},
    dataForm: { status: null },
    options: [],
  }),
  created() {
    this.getStatusKanban();
    this.getEndorsements();
  },
  computed: {
    rows() {
      return this.items.length;
    },
    disabledButtonChange() {
      return this.dataForm.status ? false : true;
    },
    showValueField() {
      return (
        this.endorsementSelected.type == this.endorsementTypes.INCREASE_VALUE ||
        this.endorsementSelected.type == this.endorsementTypes.INCREASE_VALUE_AND_EXPIRATION  ||
        this.endorsementSelected.type == this.endorsementTypes.DECREASE_VALUE
      );
    },
    showDateField() {
      return (
        this.endorsementSelected.type == this.endorsementTypes.INCREASE_EXPIRATION ||
        this.endorsementSelected.type == this.endorsementTypes.INCREASE_VALUE_AND_EXPIRATION  ||
        this.endorsementSelected.type == this.endorsementTypes.DECREASE_EXPIRATION
      );
    },
  },
  methods: {
    getEndorsements() {
      this.resetAlert();
      policyService
        .getEndorsements()
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.formatListQuotation(response.data);
        })
        .catch(() => {
          this.showError("Não foi possível recuperar os endossos.");
        });
    },
    formatListQuotation(data) {
      Object.keys(statusQuotationTypes).forEach((element) => {
        this.$set(
          this.items,
          element,
          data.filter((elementData) => {
            return elementData.status_id == statusQuotationTypes[element];
          })
        );
      });
    },
    redirect(routeName, params = null) {
      this.$router.push({ name: routeName, params: params });
    },
    showModalDestroy() {
      if (this.endorsementSelected) {
        this.showModalStatus = false;
        this.deletion_msg =
          'Deseja realmente excluir o endosso de ' +
          (this.endorsementSelected.endorsement_type ? '<strong>'+this.endorsementSelected.endorsement_type.translate + '</strong> de ' : '') +
          '<strong>'+this.endorsementSelected.policy_holder_name + '</strong>?';
        this.$refs.showModal.show();
      }
    },
    showModal(element) {
      this.endorsementSelected = element;
      this.showModalStatus = true;
    },
    destroyEndorsement() {
      this.loading_delete = true;
      this.$refs.showModal.hide();
      policyService
        .destroyEndorsement(this.endorsementSelected.id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.getEndorsements();
        })
        .catch(() => {
          this.showError('Não foi possível remover a cotação.');
        })
        .finally(() => {
          this.endorsementSelected = {};
          this.loading_delete = false;
        });
    },
    isMaster() {
      return authenticationService.isMaster();
    },
    columnKanban() {
      return {
        REQUESTED: 11,
        PROGRESS: 12,
        PENDING: 13,
        RESOLVED: 14
      };
    },
    addMoreResults(type) {
      this.$set(
        this.limitResults,
        type,
        (this.limitResults[type] += this.limit)
      );
    },
    ifExistsPaginate(type) {
      if (Array.isArray(this.items[type]) && this.items[type].length > 0) {
        return this.items[type].length > this.limitResults[type];
      }
      return false;
    },
    getStatusKanban() {
      this.listStatusKanbanEndorsement()
        .forEach((element) => {
          this.options.push({value: element, text: this.getEnumKeyByEnumValue(this.translateStatusQuotationTypes, element)})
        })
    },
    changeStatusEndorsement() {
      this.loading = true;
      this.resetAlert();
      policyService
        .updateStatusEndorsement(this.endorsementSelected.id, this.dataForm)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.getEndorsements();
        })
        .catch(() => {
          this.showError("Não foi possível mudar o status do endosso.");
        })
        .finally(() => {
          this.showModalStatus = false;
          this.dataForm.status = null;
          this.loading = false;
        });
    },
    showModalDelete(element){
      this.endorsementSelected = element;
      this.showModalStatus = true;
      this.showModalDestroy();
    },
  },
};
</script>

<style></style>
